import {PocCampaignsSummary} from '@Models';
import api from '../../api';

export default async function getCampaignsAndPointsService(
  pocCode: string
): Promise<PocCampaignsSummary[] | any> {
  const status = 'BLOCKED';

  return await api.get({
    url: `/v1/admin/loyalty-program/${pocCode}/coupons/summary?status=${status}`,
  });
}

import {useCampaignDetails} from '@Hooks';
import {
  CreateCampaignStepFour,
  EditCampaignFooter,
  LoadingBee,
} from '@Components';
import {useBreadcrumb, useToast} from '@Providers';
import {useIntl} from 'react-intl';
import {useEffect, useState} from 'react';
import {CreateCampaignProps} from '@Models';
import {updateCampaignUrlMediaUseCase} from '@UseCase';
import {useNavigate} from 'react-router-dom';

const CampaignUrlMedia: React.FC<{defaultValid?: boolean}> = ({
  defaultValid,
}) => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {toast} = useToast();

  const [campaign, isLoading] = useCampaignDetails({
    includeBlob: true,
    path: 'UrlAndMedia',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [draft, setDraft] = useState<CreateCampaignProps>();
  const [isValid, setIsValid] = useState(false);
  const [campaignWithoutPromotions, setCampaignWithoutPromotions] = useState<
    CreateCampaignProps | undefined
  >();

  useEffect(() => {
    if (campaign) {
      const {promotions, ...campaignRest} = campaign;

      setCampaignWithoutPromotions(campaignRest);
    }
  }, [campaign]);

  const onSave = () => {
    setIsSaving(true);
    updateCampaignUrlMediaUseCase(draft as CreateCampaignProps)
      .then(() => {
        setIsSaving(false);
        toast({
          message: formatMessage(
            {id: 'COUPON_CAMPAIGNS.CREATE.UPDATED_MESSAGE'},
            {
              campaignName: campaign?.campaignName,
            }
          ),
          type: 'success',
        });
      })
      .finally(() => navigate(-1));
  };

  useBreadcrumb(
    [
      {
        name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
        href: '/campaigns',
      },
      {
        name: `${formatMessage({
          id: 'COUPON_CAMPAIGNS.EDIT.EDIT_BUTTON',
        })} ${campaign?.campaignName ? campaign.campaignName : ''}`,
        href: `/campaigns/${campaign?.campaignId}`,
      },
      {
        name: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.URL_AND_MEDIA.TITLE',
        }),
        active: true,
      },
    ],
    [campaign]
  );

  return isLoading ? (
    <LoadingBee />
  ) : (
    <div style={{paddingBottom: '8rem'}}>
      <CreateCampaignStepFour
        initialValues={campaignWithoutPromotions}
        onChange={(value, isValid) => {
          setDraft({...campaignWithoutPromotions, ...value});
          setIsValid(isValid);
        }}
      />

      <EditCampaignFooter
        disabled={!isValid && !defaultValid}
        loading={isSaving}
        onSave={onSave}
      />
    </div>
  );
};

export default CampaignUrlMedia;

import {getPocsValidationStatusService} from '@Services';

async function getPocsValidationStatusUseCase(id: string): Promise<{
  processedPocs: number;
  inconsistentPocs: number;
}> {
  const response = await getPocsValidationStatusService(id);

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default getPocsValidationStatusUseCase;

import React, {DragEvent, useState, useEffect, useRef} from 'react';
import './CustomDragAndDrop.css';

interface CustomDragAndDropProps {
  onItemsChange: (items: any[]) => void;
}

const CustomDragAndDrop: React.FC<CustomDragAndDropProps> = ({
  children,
  onItemsChange,
}) => {
  const [items, setItems] = useState<any[]>([]);
  const [dragging, setDragging] = useState<number | null>(null);
  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
  const scrollIntervalRef = useRef<number | null>(null);

  useEffect(() => {
    setItems(React.Children.toArray(children));
  }, [children]);

  useEffect(() => {
    const handleWindowDragOver = (event: Event) => {
      handleAutoScroll(event as unknown as DragEvent);
    };

    window.addEventListener('dragover', handleWindowDragOver);

    return () => {
      window.removeEventListener('dragover', handleWindowDragOver);
      if (scrollIntervalRef.current !== null) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, []);

  const handleDragStart = (event: DragEvent<HTMLDivElement>, index: number) => {
    const dragIcon = event.currentTarget.querySelector('[data-drag-icon]');
    const isOpen = items[index]?.props?.promotion?.isOpen;

    if (dragIcon?.contains(event.target as Node) && !isOpen) {
      setDragging(index);
      event.dataTransfer.effectAllowed = 'move';
      event.currentTarget.classList.add('dragging');
      const dragImage = event.currentTarget.cloneNode(true) as HTMLElement;
      dragImage.style.position = 'absolute';
      dragImage.style.top = '-9999px';
      dragImage.style.left = '-9999px';
      dragImage.style.width = `${event.currentTarget.offsetWidth}px`;
      dragImage.style.height = `${event.currentTarget.offsetHeight}px`;
      dragImage.style.background = 'rgba(255,255,255, 0.85)';
      dragImage.style.boxShadow = 'none';
      document.body.appendChild(dragImage);
      event.dataTransfer.setDragImage(dragImage, 0, 0);
    } else {
      event.preventDefault();
    }
  };

  const handleDragEnd = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove('dragging');
    setDragging(null);
    setDragOverIndex(null);
    const dragImage = document.querySelector('.dragging');
    if (dragImage) {
      document.body.removeChild(dragImage);
    }
    if (scrollIntervalRef.current !== null) {
      clearInterval(scrollIntervalRef.current);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    setDragOverIndex(index);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (dragOverIndex !== null && dragging !== null) {
      const newItems = [...items];
      [newItems[dragging], newItems[dragOverIndex]] = [
        newItems[dragOverIndex],
        newItems[dragging],
      ];
      const updatedItems = newItems.map((item, index) => ({
        ...item.props.promotion,
        priority: index,
      }));
      setItems(newItems);
      onItemsChange(updatedItems);
    }

    const draggingElement = document.querySelector('.dragging');
    if (draggingElement) {
      draggingElement.classList.remove('dragging');
    }
    setDragging(null);
    setDragOverIndex(null);
    if (scrollIntervalRef.current !== null) {
      clearInterval(scrollIntervalRef.current);
    }
  };

  const handleAutoScroll = (event: DragEvent) => {
    const {clientY} = event;
    const scrollThreshold = 100;
    const scrollSpeed = 7;

    if (scrollIntervalRef.current !== null) {
      clearInterval(scrollIntervalRef.current);
    }

    if (clientY < scrollThreshold) {
      scrollIntervalRef.current = window.setInterval(() => {
        window.scrollBy(0, -scrollSpeed);
      }, 10);
    } else if (clientY > window.innerHeight - scrollThreshold) {
      scrollIntervalRef.current = window.setInterval(() => {
        window.scrollBy(0, scrollSpeed);
      }, 10);
    }
  };

  return (
    <div>
      {items.map((item, index) => (
        <div
          key={item.key}
          draggable={!item.props.promotion?.isOpen}
          onDragStart={event => handleDragStart(event, index)}
          onDragEnd={handleDragEnd}
          onDragOver={event => handleDragOver(event, index)}
          onDrop={handleDrop}
          style={{
            backgroundColor: 'rgb(255,255,255)',
            boxShadow: '0rem 0.25rem 1rem 0rem rgba(0,0,0,.16)',
            borderRadius: '1rem',
          }}
        >
          {React.cloneElement(item as React.ReactElement, {index})}
        </div>
      ))}
    </div>
  );
};
export default CustomDragAndDrop;

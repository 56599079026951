import React from 'react';
import {useField} from 'formik';
import {TextArea} from '@hexa-ui/components';

const CustomTextArea: React.FC<any> = props => {
  const [field, meta] = useField(props);

  return (
    <TextArea
      width="100%"
      hasError={meta.touched && meta.error}
      errorText={meta.error}
      placeholder={props.placeholder ?? ''}
      {...field}
      {...props}
    />
  );
};

export default CustomTextArea;

import {blockUnblockPocService} from '@Services';

async function blockUnblockPocUseCase(
  pocCode: string,
  campaignId: number,
  block: boolean,
  unblockForFraud: boolean | undefined
): Promise<{
  numberOfUnblockedCampaigns: number;
}> {
  const response = await blockUnblockPocService(
    pocCode,
    campaignId,
    block,
    unblockForFraud
  );

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default blockUnblockPocUseCase;

import {globalCss} from '@stitches/react';
import quillStyles from './Quill.styles';

const useGlobalStyles = globalCss({
  /* Snow Quill Styles */
  ...quillStyles,

  /* Custom Quill Styles */
  '.quill': {
    width: '100%',
    marginBottom: '1.5rem',
  },

  '.ql-editor': {
    fontFamily: 'var(--fonts-paragraph)',
    padding: '1rem',
    minHeight: 130,
    maxHeight: 415,
    overflowY: 'scroll',
  },

  '.ql-toolbar': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderColor: 'rgba(0, 0, 0, 0.15)',
  },

  '.ql-container': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    borderColor: 'rgba(0, 0, 0, 0.15)',
  },
});

export default useGlobalStyles;

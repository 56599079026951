import React, {useState} from 'react';
import {Button, Dialog, Heading, Paragraph, Radio} from '@hexa-ui/components';
import {useIntl} from 'react-intl';

interface BlockPocModalProps {
  pocName: string;
  isOpen: boolean;
  onClose: () => void;
  onBlock: () => void;
  onBlockPocManuallyFraudPrevention: () => void;
  loading?: boolean;
}

enum BlockType {
  MANUALLY_FRAUD_PREVENTION = 'manually-fraud-prevention',
  DISABLED_PARTICIPATION = 'disabled-participation',
}

const BlockPocModal: React.FC<BlockPocModalProps> = ({
  pocName,
  isOpen,
  onClose,
  onBlock,
  onBlockPocManuallyFraudPrevention,
  loading,
}) => {
  const {formatMessage} = useIntl();
  const [blockType, setBlockType] = useState('');

  const handleBlock = () => {
    if (blockType === BlockType.MANUALLY_FRAUD_PREVENTION) {
      onBlockPocManuallyFraudPrevention();
    } else if (blockType === BlockType.DISABLED_PARTICIPATION) {
      onBlock();
    }
  };

  return (
    <Dialog.Root
      open={isOpen}
      onClose={onClose}
      actions={
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            data-testid="action-block-poc-button"
            size="large"
            variant="destructive"
            style={{marginRight: '0.9375rem'}}
            onClick={handleBlock}
            children={formatMessage({id: 'BLOCK_POC.ACTION'})}
            isLoading={loading}
          />
          <Dialog.Close>
            <Button
              data-testid="cancel-block-poc-button"
              size="large"
              variant="secondary"
              onClick={onClose}
              children={formatMessage({id: 'COMMON.BUTTONS.NO_GO_BACK'})}
            />
          </Dialog.Close>
        </div>
      }
      title={
        <Heading size="H2">
          {formatMessage(
            {id: 'BLOCK_POC.TITLE'},
            {
              pocName: pocName,
            }
          )}
        </Heading>
      }
    >
      <Radio.Root
        onValueChange={value => setBlockType(value)}
        style={{
          display: 'block',
          gap: '1rem',
          maxWidth: '507px',
        }}
      >
        <React.Fragment key=".0">
          <Radio.Item
            data-testid="block-poc-manually-fraud-prevention"
            id="blockPocManuallyFraudPrevention"
            label={formatMessage({
              id: 'BLOCK_POC.MANUALLY_FRAUD_PREVENTION',
            })}
            value={BlockType.MANUALLY_FRAUD_PREVENTION}
            checked={blockType === BlockType.MANUALLY_FRAUD_PREVENTION}
          />

          <Paragraph
            data-testid="upload-description-two"
            size="xsmall"
            colortype="secondary"
          >
            {formatMessage(
              {
                id: 'BLOCK_POC.MANUALLY_FRAUD_PREVENTION_DESCRIPTION',
              },
              {
                totalBeesPoints: 100000000,
              }
            )}
          </Paragraph>

          <Radio.Item
            data-testid="block-disabled-participation"
            id="disabled-participation"
            label={formatMessage({
              id: 'BLOCK_POC.DISABLED_PARTICIPATION',
            })}
            value={BlockType.DISABLED_PARTICIPATION}
            checked={blockType === BlockType.DISABLED_PARTICIPATION}
          />

          <Paragraph
            data-testid="upload-description-two"
            size="xsmall"
            colortype="secondary"
          >
            {formatMessage({
              id: 'BLOCK_POC.DISABLED_PARTICIPATION_DESCRIPTION',
            })}
          </Paragraph>
        </React.Fragment>
      </Radio.Root>
    </Dialog.Root>
  );
};

export default BlockPocModal;

import {saveCurrentLocale} from '@Hooks';
import {usePreferredLanguageV2} from 'admin-portal-shared-services';
import {ReactNode} from 'react';
import {IntlProvider as NativeProvider} from 'react-intl';
import MessageMap from './i18n.d';
import resources from './languages';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: ReactNode;
};

export const messages: {[language: string]: MessageMap} = {
  ...resources,
};

export function IntlProvider({children}: IIntlProvider): JSX.Element {
  const preferredLanguage = usePreferredLanguageV2();
  const locale =
    preferredLanguage.preferredLanguage || preferredLanguage.defaultLanguage;
  const mergedMessages = mergeMessages(messages, locale);

  saveCurrentLocale(locale.split('-')[0]);
  return (
    <NativeProvider
      locale={locale}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={mergedMessages}
    >
      {children}
    </NativeProvider>
  );
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const flattenObject = (ob: any): any => {
  const toReturn: {[key: string]: any} = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

export const mergeMessages = (
  messagesInput: MessageMap,
  selectedLocale: string
): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return {...defaultMessages, ...localeMessages};
};

export default IntlProvider;

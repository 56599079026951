import {
  Alert,
  Grid,
  Heading,
  InputWrapper,
  Paragraph,
  Select,
} from '@hexa-ui/components';
import React, {useMemo} from 'react';
import {CustomDatePicker, CustomNumberInput} from '@Components';
import {Clock} from '@hexa-ui/icons';
import {useIntl} from 'react-intl';

interface DatesAndLimitsProps {
  promotionIndex: number;
  hasLimitPerConsumer?: boolean;
}

const DatesAndLimits: React.FC<DatesAndLimitsProps> = ({
  promotionIndex,
  hasLimitPerConsumer,
}) => {
  const {formatMessage} = useIntl();

  return (
    <Grid.Container
      type="fluid"
      style={{
        border: '1px solid #DBDADA',
        borderRadius: '16px',
        margin: 0,
        padding: '1rem',
      }}
    >
      <Grid.Item
        xs={12}
        style={{marginBottom: '1rem', flexDirection: 'column'}}
      >
        <Heading size="H4">
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.TITLE',
          })}
        </Heading>
        <Paragraph size="xsmall" colortype="secondary">
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.DESCRIPTION',
          })}
        </Paragraph>
      </Grid.Item>

      <Grid.Item xs={12}>
        <InputWrapper
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_GENERATION.TITLE',
          })}
        >
          <div
            style={{
              display: 'flex',
              gap: '1.5rem',
              margin: '1rem 0',
              alignItems: 'flex-end',
            }}
          >
            <CustomDatePicker
              data-testid="gen-start-date"
              name={`promotions.${promotionIndex}.couponGenStart`}
              label={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_GENERATION.START_DATE',
              })}
              width="200px"
            />
            <CustomDatePicker
              data-testid="gen-end-date"
              name={`promotions.${promotionIndex}.couponGenEnd`}
              label={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_GENERATION.END_DATE',
              })}
              width="200px"
            />
          </div>
          <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
            <CustomNumberInput
              data-testid="exp-time"
              name={`promotions.${promotionIndex}.expirationTime`}
              label={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_GENERATION.EXP_TIME',
              })}
              width="150px"
              sufix={
                (<Clock style={{marginTop: '6px', color: '#000'}} />) as any
              }
            />
            <Paragraph css={{marginTop: '1rem'}}>
              {formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_GENERATION.HOURS',
              })}
            </Paragraph>
          </div>
        </InputWrapper>
      </Grid.Item>
      {hasLimitPerConsumer && (
        <Grid.Item style={{marginTop: '1.5rem'}} xs={12}>
          <Alert
            type="info"
            message={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_GENERATION.EXP_TIME_ALERT',
            })}
            style={{width: '100%'}}
          />
        </Grid.Item>
      )}

      <Grid.Item xs={12} style={{marginTop: '1rem'}}>
        <InputWrapper
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_REDEMPTION.TITLE',
          })}
        >
          <div
            style={{
              display: 'flex',
              gap: '1.5rem',
              margin: '1rem 0',
              alignItems: 'flex-end',
            }}
          >
            <CustomDatePicker
              data-testid="redemption-start-date"
              name={`promotions.${promotionIndex}.couponRedeemStart`}
              label={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_REDEMPTION.START_DATE',
              })}
              width="200px"
            />
            <CustomDatePicker
              data-testid="redemption-end-date"
              name={`promotions.${promotionIndex}.couponRedeemEnd`}
              label={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_REDEMPTION.END_DATE',
              })}
              width="200px"
            />
          </div>
        </InputWrapper>
      </Grid.Item>
    </Grid.Container>
  );
};

export default DatesAndLimits;

import {Tooltip} from '@hexa-ui/components';
import React from 'react';
import {Dot, StatusWrapper} from './StatusDot.styles';
import {Info} from '@hexa-ui/icons';

type StatusProps = 'error' | 'success' | 'warning' | 'info';

interface StatusDotProps {
  text: string;
  status: StatusProps;
  tooltip?: string;
  infoTooltip?: string;
}

const StatusDot: React.FC<StatusDotProps> = ({
  text,
  status,
  tooltip,
  infoTooltip,
}) => {
  if (tooltip) {
    return (
      <Tooltip data-testid="tooltip" text={tooltip} placement="right">
        <StatusWrapper>
          <Dot status={status} /> {text}
        </StatusWrapper>
      </Tooltip>
    );
  }

  return (
    <StatusWrapper>
      <Dot status={status} />
      <span style={{marginRight: 5}}>{text}</span>
      {!!infoTooltip && (
        <Tooltip text={infoTooltip} placement="bottom">
          <Info size="medium" style={{marginTop: 5, color: '#939191'}} />
        </Tooltip>
      )}
    </StatusWrapper>
  );
};

export default StatusDot;

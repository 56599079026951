import {ListRequestProps} from '@Models';
import {getPocsByPromotionService} from '@Services';

async function getPocsByPromotionUseCase(
  campaignId: number,
  pageNumber: number,
  promotionId: string,
  props: ListRequestProps = {}
): Promise<any> {
  const response = await getPocsByPromotionService(
    campaignId,
    pageNumber,
    promotionId,
    props
  );

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default getPocsByPromotionUseCase;

import {Alert, Button} from '@hexa-ui/components';
import {Plus} from '@hexa-ui/icons';
import {CreatePromotionStepProps, Promotion} from '@Models';
import {v4 as uuidv4} from 'uuid';
import {FieldArray, Form, Formik} from 'formik';
import {ValuesWatcher, CustomDragAndDrop} from '@Components';
import {useIntl} from 'react-intl';
import CreatePromotionSchema from './Promotion.schema';
import PromotionCard from './PromotionCard/PromotionCard';

interface StepTwoProps extends CreatePromotionStepProps {
  isEditing?: boolean;
}

const StepTwo: React.FC<StepTwoProps> = ({
  onChange,
  initialValues,
  isEditing,
}) => {
  const {formatMessage} = useIntl();
  const promotionSchema = CreatePromotionSchema(
    initialValues?.startDateCampaign ?? new Date(),
    initialValues?.endDateCampaign ?? new Date()
  );

  const hasLimitPerConsumer = initialValues?.hasLimitPerConsumer ?? false;

  return (
    <div data-testid="step-two">
      <Formik
        initialValues={{
          promotions:
            initialValues?.promotions?.map((promotion, index) => ({
              ...promotion,
              priority: promotion.priority ?? index,
            })) ??
            ([
              {
                id: uuidv4(),
                promotionName: '',
                promotionImageAltText: '',
                couponType: 'discount',
                isOpen: true,
                position: 0,
                priority: 0,
                promotionStatus: true,
                campaignId: initialValues?.campaignId,
                newPromotion: true,
              },
            ] as Promotion[]),
        }}
        validationSchema={promotionSchema}
        validateOnMount
        onSubmit={() => {}}
      >
        {({values, setFieldValue}) => (
          <Form>
            <FieldArray
              name="promotions"
              render={helper => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                  }}
                >
                  <Alert
                    message={formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.ALERT',
                    })}
                    type="warning"
                    style={{width: '100%'}}
                  />
                  <CustomDragAndDrop
                    onItemsChange={newItems => {
                      setFieldValue('promotions', newItems);
                    }}
                  >
                    {values.promotions.map((promotion, index) => (
                      <PromotionCard
                        key={promotion.id}
                        promotion={promotion}
                        helper={helper}
                        index={index}
                        isEditing={false}
                        hasLimitPerConsumer={hasLimitPerConsumer}
                      />
                    ))}
                  </CustomDragAndDrop>
                  <Button
                    onClick={() =>
                      helper.push({
                        id: uuidv4(),
                        promotionName: '',
                        promotionImageAltText: '',
                        couponType: 'discount',
                        isOpen: true,
                        position: values.promotions.length,
                        priority: values.promotions.length,
                        editing: isEditing,
                        promotionStatus: true,
                        campaignId: initialValues?.campaignId,
                        newPromotion: true,
                        uploadNewPromotionInEligiblePocs: false,
                      })
                    }
                    leading
                    icon={Plus}
                    variant="secondary"
                    type="button"
                  >
                    {formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.ADD_BUTTON',
                    })}
                  </Button>
                </div>
              )}
            />
            <ValuesWatcher onChange={onChange} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepTwo;

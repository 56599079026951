import {Grid, Heading} from '@hexa-ui/components';
import {useIntl} from 'react-intl';
import {useBreadcrumb, useToast} from '@Providers';
import {
  addPocsUseCase,
  getInconsistenciesUseCase,
  getPocsValidationStatusUseCase,
  uploadPocsUseCase,
} from '@UseCase';
import {StyledFileUploader} from '@martech/components';
import {useEffect, useMemo, useRef, useState} from 'react';
import {openBlob} from '@martech/utils';
import {ButtonsBackSubmit, CustomCardFileUploader} from '@Components';
import Axios from 'axios';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {countCsvLines, formatNumber, getStorageURL} from '@Utils';
import {useAuthenticationService} from 'admin-portal-shared-services';

type ProcessStatus = 'waiting' | 'loading' | 'processing' | 'done' | 'error';

export const MAX_POCS_UPLOAD = 50000;

export default function AddEligiblePocs() {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {toast} = useToast();
  const location = useLocation();
  const validationRef = useRef() as any;
  const authService = useAuthenticationService();

  const {campaignId} = useParams();
  const [file, setFile] = useState<File>();
  const [status, setStatus] = useState<ProcessStatus>('done');
  const [processedLines, setProcessedLines] = useState(0);
  const [inconsistentLines, setInconsistentLines] = useState(0);
  const [csvLines, setCsvLines] = useState(0);
  const [uploadId, setUploadId] = useState<string>();
  const [validating, setValidating] = useState(false);
  const [adding, setAdding] = useState(false);
  const [onCancel, setOnCancel] = useState<any>();
  const [fileError, setFileError] = useState('');

  const campaignData = useMemo(() => {
    return location.state;
  }, [location.state]);

  useBreadcrumb([
    {
      name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
      href: '/campaigns',
    },
    {
      name: campaignData.campaignName,
      href: `/campaigns/${campaignId}/eligible-pocs`,
    },
    {
      name: formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.ELIGIBLE_POCS'}),
      href: `/campaigns/${campaignId}/eligible-pocs`,
    },
    {
      name: formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.BREADCRUMB'}),
      active: true,
    },
  ]);

  useEffect(() => {
    return () => {
      clearInterval(validationRef.current);
    };
  }, []);

  useEffect(() => {
    if (uploadId) {
      validationRef.current = setInterval(() => {
        getValidationStatus(uploadId);
      }, 5000);
    }
  }, [uploadId]);

  const handlePocValidation = (file: File) => {
    const handleFailure = () => {
      setStatus('error');
      setValidating(false);
    };

    setFileError('');
    setProcessedLines(0);
    setFile(file);
    setStatus('loading');
    setValidating(true);

    countCsvLines(file)
      .then(lines => {
        const actualLines = lines - 4;
        if (actualLines > MAX_POCS_UPLOAD || actualLines < 1) {
          setFileError('INVALID');
          handleFailure();
        } else {
          uploadPocsUseCase(+campaignId!, file)
            .then(response => {
              setStatus('processing');
              setCsvLines(response.numberOfPocs);
              setUploadId(response.pocUploadId);
            })
            .catch(error => {
              if (error.id === 'invalid') {
                setFileError('INVALID');
              }
              handleFailure();
            });
        }
      })
      .catch(() => {
        handleFailure();
        setFileError('TYPE');
      });
  };

  const getValidationStatus = (uploadedFileId: string) => {
    getPocsValidationStatusUseCase(uploadedFileId)
      .then(response => {
        setProcessedLines(response.processedPocs);
        setInconsistentLines(response.inconsistentPocs);

        if (response.processedPocs === csvLines) {
          setStatus('done');
          setValidating(false);
          clearInterval(validationRef.current);
        }
      })
      .catch(() => {
        setStatus('done');
        setValidating(false);
        clearInterval(validationRef.current);
      });
  };

  const handleExportInconsistentPocs = () => {
    const request = Axios.CancelToken.source();

    setOnCancel(request);
    getInconsistenciesUseCase(uploadId!, request)
      .then(response => {
        const datetime = new Date().toISOString().split('T');
        const date = datetime[0].replaceAll('-', '_');
        const time = datetime[1].replaceAll(':', '_').slice(0, 8);

        openBlob(
          response,
          `pocs_upload_inconsistencies_report_${date}_${time}.csv`
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  const addPocs = () => {
    const request = Axios.CancelToken.source();

    setOnCancel(request);
    setAdding(true);
    addPocsUseCase(uploadId!, request)
      .then(() => {
        toast({
          message: formatMessage(
            {id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.SUCCESS_MESSAGE'},
            {
              value: formatNumber(csvLines - inconsistentLines),
              campaignName: campaignData.campaignName,
            }
          ),
          type: 'success',
        });
        navigateToEligiblePocsList();
      })
      .catch(() => {
        toast({
          message: formatMessage({
            id: 'COMMON.ERRORS.DEFAULT',
          }),
          type: 'error',
        });
      })
      .finally(() => {
        setAdding(false);
      });
  };

  const handleCancel = () => {
    onCancel?.cancel();
    navigateToEligiblePocsList();
  };

  const handleTemplateDownload = () => {
    const storageURL = getStorageURL();
    const country = authService.getCountryB2C().toLowerCase();

    const templateUrl = `${storageURL}/templates/${country}/POC_Upload_template.csv`;
    window.open(templateUrl, '_blank');
  };

  const navigateToEligiblePocsList = () => {
    navigate(`/campaigns/${campaignId}/eligible-pocs`);
  };

  return (
    <Grid.Container
      sidebar
      data-testid="add-eligible-pocs-page"
      type="fluid"
      style={{margin: 0, width: '100%', justifyContent: 'center'}}
    >
      <Grid.Item xs={12}>
        <Heading data-testid="campaignTitle" size="H2">
          {formatMessage(
            {id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.TITLE'},
            {title: campaignData.campaignName}
          )}
        </Heading>
      </Grid.Item>

      <CustomCardFileUploader
        titleName={campaignData.campaignName}
        handleTemplateDownload={handleTemplateDownload}
        isDisabledDownloadTemplate={adding}
        hasEligiblePocs={campaignData.eligible > 0}
        textIdDescriptionOne="CAMPAIGN_ELIGIBLE_POCS.ADD.FILE_DESCRIPTION_ONE"
        textIdDescriptionTwo="CAMPAIGN_ELIGIBLE_POCS.ADD.FILE_DESCRIPTION_TWO"
        hasFeedbackCard
        file={file}
        status={status}
        dataTestIdProcessingFeedbackCard="processing-container"
        dataTestIdProcessingFeedbackCardStatus="process-status"
        dataTestIdDoneFeedbackCard="done-container"
        dataTestIdDoneFeedbackCardInconsistencies="pocs-to-add"
        dataTestIdDoneFeedbackCardExportError="export-error-pocs"
        isDisabledExportError={adding}
        processedLines={processedLines}
        inconsistentLines={inconsistentLines}
        csvLines={csvLines}
        handleExportInconsistentPocs={handleExportInconsistentPocs}
      >
        <StyledFileUploader
          onChange={handlePocValidation}
          isLoading={status === 'loading'}
          handleRemove={() => {
            setFile(undefined);
            setFileError('');
          }}
          acceptedType=".csv"
          placeholder={formatMessage({
            id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.DROP_PLACEHOLDER',
          })}
          buttonText={formatMessage({
            id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.BROWSE_FILE',
          })}
          isDisabled={adding || validating}
          errorMessage={
            fileError
              ? formatMessage({
                  id: `CAMPAIGN_ELIGIBLE_POCS.ADD.${fileError}_ERROR`,
                })
              : undefined
          }
          iconType="doc"
          value={file}
        />
      </CustomCardFileUploader>

      <Grid.Item
        xs={9}
        style={{justifyContent: 'flex-end', margin: '2rem auto'}}
      >
        <ButtonsBackSubmit
          dataTestIdBtnOne="cancel"
          dataTestIdBtnTwo="add-pocs"
          variantBtnOne="secondary"
          styleBtnOne={{marginRight: '1rem'}}
          onClickBtnOne={handleCancel}
          onClickBtnTwo={addPocs}
          textIdBtnOne="CAMPAIGN_ELIGIBLE_POCS.ADD.CANCEL"
          textIdBtnTwo="CAMPAIGN_ELIGIBLE_POCS.ADD.ADD_POCS"
          disabledBtnTwo={
            status !== 'done' ||
            !uploadId ||
            !file ||
            inconsistentLines === csvLines
          }
          isLoadingBtnTwo={adding}
        />
      </Grid.Item>
    </Grid.Container>
  );
}

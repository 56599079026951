import {useCurrentLocale} from '@Hooks';
import {numberLocale} from '@martech/utils';

const formatNumber = (number: any, fixed = 0) => {
  const locale = useCurrentLocale();

  return numberLocale(number, locale, fixed);
};

export default formatNumber;

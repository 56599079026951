import {Grid, Heading} from '@hexa-ui/components';
import React from 'react';
import {CustomNumberInput, CustomInput} from '@Components';
import {CurrencyInput} from '@martech/components';
import {useFormikContext} from 'formik';
import {useIntl} from 'react-intl';

interface ProductSettingsProps {
  promotionIndex: number;
}

const ProductSettings: React.FC<ProductSettingsProps> = ({promotionIndex}) => {
  const {formatMessage} = useIntl();
  const {values, setFieldValue, setFieldTouched, touched, errors} =
    useFormikContext<any>();

  return (
    <Grid.Container
      type="fluid"
      style={{
        border: '1px solid #DBDADA',
        borderRadius: '16px',
        margin: 0,
        padding: '1rem',
        alignItems: 'self-end',
      }}
    >
      <Grid.Item
        xs={12}
        style={{marginBottom: '1rem', flexDirection: 'column'}}
      >
        <Heading size="H4">
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.TITLE',
          })}
        </Heading>
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1.5rem'}}>
        <CustomInput
          data-testid="product-type"
          name={`promotions.${promotionIndex}.productType`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_TYPE.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_TYPE.HINT',
          })}
          maxLength={30}
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1.5rem'}}>
        <CustomInput
          data-testid="product-brand"
          name={`promotions.${promotionIndex}.cdpBrandName`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_BRAND.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_BRAND.HINT',
          })}
          maxLength={30}
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1.5rem'}}>
        <CustomInput
          data-testid="product-name"
          name={`promotions.${promotionIndex}.productName`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_NAME.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_NAME.HINT',
          })}
          maxLength={30}
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1rem'}}>
        <CustomNumberInput
          data-testid="total-units"
          name={`promotions.${promotionIndex}.totalUnits`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.TOTAL_UNITS.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.TOTAL_UNITS.HINT',
          })}
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1rem'}}>
        <CurrencyInput
          testId="price-per-unit"
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRICE_PER_UNIT.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRICE_PER_UNIT.HINT',
          })}
          currency={{
            locale: 'en-US',
            currency: 'USD',
            prefix: {
              actual: '$',
              preferred: '$',
            },
          }}
          value={values.promotions[promotionIndex].pricePerUnit}
          onChange={value => {
            setFieldTouched(`promotions.${promotionIndex}.pricePerUnit`, true);
            setFieldValue(`promotions.${promotionIndex}.pricePerUnit`, value);
          }}
          hasError={
            !!(touched.promotions as any)?.[promotionIndex]?.pricePerUnit &&
            !!(errors.promotions as any)?.[promotionIndex]?.pricePerUnit
          }
          errorText={
            (errors.promotions as any)?.[promotionIndex]?.pricePerUnit as string
          }
        />
      </Grid.Item>

      <Grid.Item xs={4} style={{marginBottom: '1rem'}}>
        <CustomNumberInput
          data-testid="volume-per-unit"
          name={`promotions.${promotionIndex}.mlVolumePerUnit`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.VOLUME_PER_UNIT.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.VOLUME_PER_UNIT.HINT',
          })}
          decimal
        />
      </Grid.Item>
    </Grid.Container>
  );
};

export default ProductSettings;

import React from 'react';
import {Button, Dialog, Heading, Paragraph} from '@hexa-ui/components';
import {useIntl} from 'react-intl';

interface EnablePocModalProps {
  pocName: string;
  isOpen: boolean;
  onClose: () => void;
  onEnable: () => void;
  loading?: boolean;
}

const EnablePocModal: React.FC<EnablePocModalProps> = ({
  pocName,
  isOpen,
  onClose,
  onEnable,
  loading,
}) => {
  const {formatMessage} = useIntl();

  return (
    <Dialog.Root
      data-testid="enable-poc-modal"
      open={isOpen}
      onClose={onClose}
      actions={
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            data-testid="action-enable-poc-button"
            size="large"
            variant="destructive"
            style={{marginRight: '0.9375rem'}}
            onClick={onEnable}
            children={formatMessage({id: 'ENABLE_POC.ACTION'})}
            isLoading={loading}
          />
          <Dialog.Close>
            <Button
              data-testid="cancel-enable-poc-button"
              size="large"
              variant="secondary"
              onClick={onClose}
              children={formatMessage({id: 'COMMON.BUTTONS.NO_GO_BACK'})}
            />
          </Dialog.Close>
        </div>
      }
      title={
        <Heading size="H2">
          {formatMessage(
            {id: 'ENABLE_POC.TITLE'},
            {
              pocName: pocName,
            }
          )}
        </Heading>
      }
    >
      <Paragraph
        style={{
          margin: 0,
          paddingTop: '15px',
          paddingBottom: '15px',
          textAlign: 'justify',
          maxWidth: '507px',
        }}
      >
        {formatMessage({
          id: 'ENABLE_POC.ENABLE_PARTICIPATION_DESCRIPTION',
        })}
      </Paragraph>
    </Dialog.Root>
  );
};

export default EnablePocModal;

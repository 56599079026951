import {Button, Grid, Heading} from '@hexa-ui/components';
import {useNavigate} from 'react-router-dom';
import {useBreadcrumb} from '@Providers';
import {useIntl} from 'react-intl';
import CampaignSummary from '../../components/Campaign/Common/CampaignSummary/CampaignSummary';
import PromotionsSummary from '../../components/Campaign/Common/PromotionsSummary/PromotionsSummary';
import LegalInfoSummary from '../../components/Campaign/Common/LegalInfoSummary/LegalInfoSummary';
import UrlAndMediaSummary from '../../components/Campaign/Common/UrlAndMediaSummary/UrlAndMediaSummary';
import {useCampaignDetails} from '@Hooks';
import {Footer, LoadingBee} from '@Components';

const EditCampaign: React.FC = () => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();

  const [campaign, isLoading] = useCampaignDetails();

  useBreadcrumb(
    [
      {
        name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
        href: '/campaigns',
      },
      {
        name: `${formatMessage({
          id: 'COUPON_CAMPAIGNS.EDIT.EDIT_BUTTON',
        })} ${campaign?.campaignName ?? ''}`,
        active: true,
      },
    ],
    [campaign]
  );

  const handleEdit = (card: string, paramsEdit?: boolean) => {
    if (paramsEdit) {
      navigate(card, {state: {edit: paramsEdit}});
      return;
    }
    navigate(card);
  };

  const handleGoBack = () => {
    navigate('/campaigns');
  };

  return isLoading ? (
    <LoadingBee />
  ) : (
    <Grid.Container
      data-testid="campaign-details"
      sidebar
      type="fluid"
      style={{
        margin: 0,
        paddingBottom: '150px',
      }}
    >
      <Grid.Item
        xs={12}
        style={{
          marginBottom: '40px',
        }}
      >
        <Heading size="H2">
          {formatMessage({id: 'COUPON_CAMPAIGNS.EDIT.EDIT_BUTTON'})}{' '}
          {campaign?.campaignName ?? ''}
        </Heading>
      </Grid.Item>
      <Grid.Item xs={12} style={{flexDirection: 'column', gap: '2rem'}}>
        <CampaignSummary
          values={campaign}
          onEdit={() => handleEdit('campaign', true)}
        />
        <PromotionsSummary
          values={campaign}
          onEdit={() => handleEdit('promotions')}
        />
        <LegalInfoSummary
          values={campaign}
          onEdit={() => handleEdit('legal-info')}
        />
        <UrlAndMediaSummary
          values={campaign}
          onEdit={() => handleEdit('url-and-media')}
        />
      </Grid.Item>

      <Footer>
        <Grid.Container
          sidebar
          type="fluid"
          style={{
            border: '1px solid #EEE',
            background: '#FFF',
            borderRadius: '1000px',
            padding: '24px',
            marginLeft: '6rem',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <Grid.Item xs={12} style={{justifyContent: 'flex-end', gap: '1rem'}}>
            <Button
              variant="secondary"
              data-testid="back-button"
              onClick={handleGoBack}
            >
              {formatMessage({id: 'COUPON_CAMPAIGNS.EDIT.GO_BACK_BUTTON'})}
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Footer>
    </Grid.Container>
  );
};

export default EditCampaign;

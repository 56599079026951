import MessageMap from '../../i18n';

const promotionDetails: MessageMap = {
  DETAILS_TITLE: 'Detalles de {title}',
  DETAILS_BREADCRUMB: 'Detalles de la promoción',
  DETAILS_ADD_BUTTON: 'Agregar PDV elegibles',
  DETAILS_SEARCH: 'Buscar por código de PDV o nombre',
  STATUSES: {
    POC_PARTICIPATING: 'Participando',
    POC_NOT_PARTICIPATING: 'Sin participar',
    POC_BLOCKED_MANUAL: 'Bloqueado - Manualmente',
    POC_BLOCKED_MANUAL_DISABLED:
      'Bloqueado manualmente - Desactivado en la campaña',
    POC_BLOCKED_MANUALLY_FRAUD_PREVENTION:
      'Bloqueado manualmente - Prevención del fraude',
    POC_BLOCKED_AUTOMATIC: 'Bloqueado - Prevención del fraude',
    POC_DISABLED_ON_PROMOTION: 'Desactivado manualmente en promoción',
  },
  STATUSES_TOOLTIP_INFO: {
    POC_BLOCKED_MANUAL: 'Participación desactivada.',
    POC_BLOCKED_MANUALLY_FRAUD_PREVENTION:
      'Bloqueado por prevención del fraude.',
  },
  TABLE: {
    DETAILS_POC_ID: 'Código de PDV',
    DETAILS_POC_NAME: 'Nombre del PDV',
    DETAILS_STATUS: 'Estado',
    DETAILS_ADDED_ON: 'Agregado el',
    DETAILS_BLOCKED_ON: 'Bloqueado el',
    DETAILS_ACTION: 'Acción',
    DETAILS_DISABLE: 'Deshabilitar',
    DETAILS_ENABLE: 'Habilitar',
  },
  NO_DATA: {
    DETAILS_TITLE: 'Esta promoción aún no tiene ningún PDV elegible',
    DETAILS_DESCRIPTION: 'Agrégalos haciendo clic en “Agregar PDV elegibles”.',
  },
};

export default promotionDetails;

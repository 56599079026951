import {Card, Grid, Heading, InputWrapper, Radio} from '@hexa-ui/components';
import {useFormikContext} from 'formik';
import React, {useEffect} from 'react';
import {CustomInput} from '@Components';
import {useIntl} from 'react-intl';
import {validateLandingPageUrlUseCase} from '@UseCase';

const DomainAndURL: React.FC = () => {
  const {formatMessage} = useIntl();
  const {values, setFieldValue, setFieldTouched, setFieldError} =
    useFormikContext<any>();

  const validatePathName = () => {
    if (values.landingPageUrl) {
      validateLandingPageUrlUseCase(values.landingPageUrl).then(response => {
        if (response.content.length > 0) {
          setFieldError(
            'landingPageUrl',
            formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.PATH_NAME.ERROR',
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (values.adSettings?.domainType === 'beesdc') {
      setFieldValue('adSettings.gtmContainerId', undefined);
      setFieldValue('adSettings.customCookiesUrl', undefined);
      setFieldValue('adSettings.googleAnalytics360UA', undefined);
      setFieldValue('adSettings.googleAnalytics360PropertyViewId', undefined);
    }
  }, [values.adSettings?.domainType]);

  return (
    <Card
      data-testid="domain-and-url"
      border="medium"
      elevated="small"
      style={{width: '100%', padding: '2rem'}}
    >
      <Grid.Container type="fluid" style={{margin: 0}}>
        <Grid.Item xs={12}>
          <Heading size="H3">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.TITLE',
            })}
          </Heading>
        </Grid.Item>

        <Grid.Item
          xs={12}
          style={{flexDirection: 'column', gap: '8px', marginTop: '1rem'}}
        >
          <InputWrapper
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.DOMAIN_TYPE.TITLE',
            })}
            width="100%"
          >
            <Radio.Root
              onValueChange={value => {
                setFieldValue('adSettings.domainType', value);
                const defaultDomainValue =
                  value === 'beesdc' ? 'beescoupons.com/' : '';
                setFieldValue('adSettings.customDomain', defaultDomainValue);
              }}
              style={{
                display: 'flex',
                gap: '1rem',
              }}
            >
              <React.Fragment key=".0">
                <Radio.Item
                  id="default"
                  label={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.DOMAIN_TYPE.BEESDC',
                  })}
                  value="beesdc"
                  checked={values.adSettings.domainType === 'beesdc'}
                />
                <Radio.Item
                  id="custom"
                  label={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.DOMAIN_TYPE.CUSTOM',
                  })}
                  value="custom"
                  checked={values.adSettings.domainType === 'custom'}
                />
              </React.Fragment>
            </Radio.Root>
          </InputWrapper>
        </Grid.Item>

        <Grid.Item xs={4} style={{alignItems: 'end'}}>
          <CustomInput
            data-testid="domain"
            name="adSettings.customDomain"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.DEFAULT_DOMAIN.TITLE',
            })}
            hint={formatMessage({
              id: `COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.DEFAULT_DOMAIN.HINT_${values.adSettings.domainType?.toUpperCase()}`,
            })}
            disabled={values.adSettings.domainType === 'beesdc'}
          />
        </Grid.Item>
        <Grid.Item xs={8} style={{alignItems: 'end'}}>
          <CustomInput
            data-testid="path-name"
            name="landingPageUrl"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.PATH_NAME.TITLE',
            })}
            hint={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.PATH_NAME.HINT',
            })}
            onBlur={() => {
              setFieldTouched('landingPageUrl', true);
              validatePathName();
            }}
          />
        </Grid.Item>

        {values.adSettings.domainType === 'custom' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '1.5rem',
              marginTop: '1.5rem',
            }}
          >
            <Grid.Item xs={12}>
              <CustomInput
                data-testid="gtm-container-id"
                name="adSettings.gtmContainerId"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.GTM_CONTAINER_ID',
                })}
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <CustomInput
                data-testid="custom-url-cookies"
                name="adSettings.customCookiesUrl"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.CUSTOM_URL_COOKIES',
                })}
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <CustomInput
                data-testid="google-analytics-ua"
                name="adSettings.googleAnalytics360UA"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.GOOGLE_ANALYTICS_UA',
                })}
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <CustomInput
                data-testid="google-analytics-view-id"
                name="adSettings.googleAnalytics360PropertyViewId"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.GOOGLE_ANALYTICS_VIEW_ID',
                })}
              />
            </Grid.Item>
          </div>
        )}
      </Grid.Container>
    </Card>
  );
};

export default DomainAndURL;

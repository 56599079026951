const getCountryIsoCode3 = (countryIsoCode2: string) => {
  const countriesIsoCode: {[key: string]: string} = {
    AR: 'ARG',
    BR: 'BRA',
    CO: 'COL',
    DO: 'DOM',
    EC: 'ECU',
    MX: 'MEX',
    PA: 'PAN',
    PE: 'PER',
    PY: 'PRY',
    SV: 'SLV',
    ZA: 'ZAF',
  };

  return countriesIsoCode[countryIsoCode2] || '';
};

export default getCountryIsoCode3;

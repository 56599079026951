import {styled} from '@stitches/react';

const Footer = styled('div', {
  position: 'fixed',
  left: '0',
  bottom: '0',
  width: '100%',
  background: 'rgb(250, 250, 250)',
});

export default Footer;

import {useCampaignDetails} from '@Hooks';
import {
  CreateCampaignStepTwo,
  EditCampaignFooter,
  LoadingBee,
} from '@Components';
import {useBreadcrumb, useToast} from '@Providers';
import {useIntl} from 'react-intl';
import {useCallback, useEffect, useState} from 'react';
import {CreatePromotionProps, Promotion} from '@Models';
import {updateCampaignPromotionsUseCase} from '@UseCase';
import {useNavigate} from 'react-router-dom';

const CampaignPromotions: React.FC<{defaultValid?: boolean}> = ({
  defaultValid,
}) => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {toast} = useToast();

  const [campaign, isLoading] = useCampaignDetails({
    includeBlob: true,
    path: 'Promotions',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [draft, setDraft] = useState<CreatePromotionProps>();
  const [isValid, setIsValid] = useState(false);
  const [promotionsWithCampaignId, setPromotionsWithCampaignId] = useState<
    CreatePromotionProps | undefined
  >();

  useEffect(() => {
    if (campaign) {
      const {
        promotions,
        campaignId,
        startDateCampaign,
        endDateCampaign,
        hasLimitPerConsumer,
      } = campaign;
      const promosWithCampaignId = promotions
        ? promotions.map(promotion => ({
            ...promotion,
            campaignId,
          }))
        : [];

      setPromotionsWithCampaignId({
        campaignId,
        startDateCampaign,
        endDateCampaign,
        hasLimitPerConsumer,
        promotions: promosWithCampaignId,
      });
    }
  }, [campaign]);

  const onChange = useCallback(
    (value, isValid) => {
      const isFieldPromoEdited = (
        promo: {[x: string]: any},
        initialPromo: Promotion | undefined
      ) => {
        if (!initialPromo) return false;

        return Object.keys(promo).some(key => {
          const promoValue = promo[key];
          const initialPromoValue = initialPromo[key];
          if (key === 'isOpen') return false;

          if (promoValue instanceof Date && initialPromoValue) {
            return (
              promoValue.toISOString().split('T')[0] !==
              initialPromoValue.split('T')[0]
            );
          }

          if (
            typeof promoValue === 'number' ||
            typeof initialPromoValue === 'number'
          ) {
            return Number(promoValue) !== Number(initialPromoValue);
          }

          if (key === 'promotionImageFile') {
            if (
              promoValue instanceof File &&
              initialPromoValue instanceof File
            ) {
              return (
                promoValue.name !== initialPromoValue.name ||
                promoValue.size !== initialPromoValue.size ||
                promoValue.lastModified !== initialPromoValue.lastModified
              );
            }
            return (
              promoValue instanceof File !== initialPromoValue instanceof File
            );
          }

          return promoValue !== initialPromoValue;
        });
      };

      const promotionsEdited = value.promotions.map(
        (promo: {[x: string]: any}, index: number) => {
          const initialPromo = promotionsWithCampaignId?.promotions?.[index];
          const isEdited = isFieldPromoEdited(promo, initialPromo);
          return isEdited ? {...promo, editedPromotion: true} : promo;
        }
      );

      setDraft(prevDraft => ({
        ...prevDraft,
        promotions: promotionsEdited,
        startDateCampaign: prevDraft?.startDateCampaign || new Date(),
        endDateCampaign: prevDraft?.endDateCampaign || new Date(),
      }));
      setIsValid(isValid);
    },
    [promotionsWithCampaignId]
  );

  const onSave = () => {
    setIsSaving(true);
    updateCampaignPromotionsUseCase(draft as CreatePromotionProps)
      .then(() => {
        setIsSaving(false);
        toast({
          message: formatMessage(
            {id: 'COUPON_CAMPAIGNS.CREATE.UPDATED_MESSAGE'},
            {
              campaignName: campaign?.campaignName,
            }
          ),
          type: 'success',
        });
      })
      .finally(() => navigate(-1));
  };

  useBreadcrumb(
    [
      {
        name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
        href: '/campaigns',
      },
      {
        name: `${formatMessage({
          id: 'COUPON_CAMPAIGNS.EDIT.EDIT_BUTTON',
        })} ${campaign?.campaignName ? campaign.campaignName : ''}`,
        href: `/campaigns/${campaign?.campaignId}`,
      },
      {
        name: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.STEPS.PROMOTIONS.TITLE',
        }),
        active: true,
      },
    ],
    [campaign]
  );

  return isLoading ? (
    <LoadingBee />
  ) : (
    <div data-testid="campaign-promotions" style={{paddingBottom: '8rem'}}>
      <CreateCampaignStepTwo
        data-testid="create-campaign-step-two"
        initialValues={promotionsWithCampaignId}
        isEditing
        onChange={onChange}
      />

      <EditCampaignFooter
        disabled={!isValid && !defaultValid}
        loading={isSaving}
        onSave={onSave}
      />
    </div>
  );
};

export default CampaignPromotions;

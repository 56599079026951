import {Formik, Form} from 'formik';
import LegalInfo from '../../Common/LegalInfo/LegalInfo';
import CreateStepThreeSchema from './StepThree.schema';
import {CreateCampaignStepProps} from '@Models';
import {ValuesWatcher} from '@Components';

interface StepThreeProps extends CreateCampaignStepProps {}

const StepThree: React.FC<StepThreeProps> = ({onChange, initialValues, isEditing}) => {

  return (
    <div data-testid="step-three">
      <Formik
        initialValues={{
          legalInfoForParticipantPocs:
            initialValues?.legalInfoForParticipantPocs ?? [
              {document: '', title: '', position: 0},
            ],
          termsUrl: initialValues?.termsUrl,
          privacyUrl: initialValues?.privacyUrl,
          campaignRules: initialValues?.campaignRules,
        }}
        validationSchema={CreateStepThreeSchema()}
        validateOnMount
        onSubmit={() => {}}
      >
        <Form>
          <LegalInfo isEditing={isEditing} />
          <ValuesWatcher onChange={onChange} />
        </Form>
      </Formik>
    </div>
  );
};

export default StepThree;

import MessageMap from '../../i18n';

const unblockPoc: MessageMap = {
  TITLE: '¿Desbloquear {pocName}?',
  TEXT_WARNING_AUTOMATIC:
    'Este PDV también ha sido bloqueado de las campañas a continuación por prevención de fraude y se han retenido los siguientes puntos BEES:',
  TEXT_CONFIRMATION_AUTOMATIC:
    'Una vez desbloqueado, el PDV podrá usar estos puntos BEES y validar cupones nuevamente.',
  CAMPAIGNS_POINTS: '- {campaignName}: {amountPoints} puntos',

  TEXT_WARNING_DISABLED_PARTICIPATION:
    'La participación en la campaña para este PDV ha sido deshabilitada.',
  TEXT_CONFIRMATION_DISABLED_PARTICIPATION:
    'Si se desbloquea, el PDV podrá participar en la campaña y validar los cupones nuevamente.',

  TEXT_WARNING_MANUALLY_FRAUD_PREVENTION: `Este PDV ha sido bloqueado de la campaña para prevenir el fraude. El PDV tenía un total de {amountPoints, plural,
      =1 {1 punto}
      other {# puntos}} BEES, que también estaban bloqueados.`,

  TEXT_CONFIRMATION_MANUALLY_FRAUD_PREVENTION:
    'Si se desbloquea, el PDV podrá usar estos puntos BEES y validar los cupones nuevamente.',

  ACTION: 'Sí, desbloquear PDV',
  SUCCESS_MESSAGE_MANUAL:
    '{pocName} fue desbloqueado con éxito de la campaña del {campaignName}.',
  SUCCESS_MESSAGE_AUTOMATIC:
    'El PDV {pocName} se desbloqueó correctamente en {countCampaigns} campañas.',
};

export default unblockPoc;

import {Grid, Heading, Paragraph} from '@hexa-ui/components';
import React, {useMemo} from 'react';
import {Demand, Introduce, Sustain} from '@Assets';
import {useFormikContext} from 'formik';
import {useIntl} from 'react-intl';

interface PromotionGoalProps {
  promotionIndex: number;
}

const PromotionGoal: React.FC<PromotionGoalProps> = ({promotionIndex}) => {
  const {formatMessage} = useIntl();
  const {values, setFieldValue} = useFormikContext<any>();

  const goals = useMemo(() => {
    return [
      {
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.INTRODUCE.TITLE',
        }),
        description: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.INTRODUCE.DESCRIPTION',
        }),
        value: 'introduce',
        icon: Introduce,
      },
      {
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.SUSTAIN.TITLE',
        }),
        description: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.SUSTAIN.DESCRIPTION',
        }),
        value: 'sustain',
        icon: Sustain,
      },
      {
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.GENERATE.TITLE',
        }),
        description: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.GENERATE.DESCRIPTION',
        }),
        value: 'generate_demand',
        icon: Demand,
      },
    ];
  }, []);

  return (
    <Grid.Container
      type="fluid"
      style={{
        width: '100%',
        border: '1px solid #DBDADA',
        borderRadius: '16px',
        margin: 0,
        padding: '1.5rem',
        background: '#F2F2F2',
      }}
    >
      <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
        <Heading size="H4">
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.TITLE',
          })}
        </Heading>
      </Grid.Item>

      {goals.map((goal, index) => (
        <Grid.Item
          data-testid={`goal-${goal.value}`}
          key={index}
          xs={4}
          onClick={() =>
            setFieldValue(
              `promotions.${promotionIndex}.promotionGoal`,
              goal.value
            )
          }
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              gap: '8px',
              padding: '8px 16px',
              background: '#FFF',
              border:
                values.promotions[promotionIndex].promotionGoal === goal.value
                  ? '2px solid #047AF1'
                  : '1px solid #DBDADA',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          >
            <img src={goal.icon} alt={goal.label} />
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Heading size="H5">{goal.label}</Heading>
              <Paragraph size="xsmall">{goal.description}</Paragraph>
            </div>
          </div>
        </Grid.Item>
      ))}
    </Grid.Container>
  );
};

export default PromotionGoal;

import React from 'react';
import {useField} from 'formik';
import {Input, InputProps} from '@hexa-ui/components';
import {onlyNumbers} from '@martech/utils';

const CustomNumberInput: React.FC<InputProps | any> = ({
  decimal = false,
  ...props
}) => {
  const [field, meta, helper] = useField(props as any);

  const {width = '100%'} = props;

  const fullValue = (string: string) => {
    const sanitized = onlyNumbers(string);
    const sanitizedNumber = Number(sanitized);

    return decimal ? (sanitizedNumber / 100).toFixed(2) : sanitizedNumber;
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    const full = fullValue(value);

    helper.setTouched(true);
    helper.setValue(full);
  };

  return (
    <Input
      {...field}
      {...(props as any)}
      width={width}
      hasError={Boolean(meta.touched && meta.error)}
      errorText={meta.error}
      placeholder={props.placeholder ?? ''}
      onChange={handleChange}
    />
  );
};

export default CustomNumberInput;

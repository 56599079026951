import React, {useEffect, useMemo} from 'react';
import {Formik, Form} from 'formik';
import CustomerSettings from '../../Common/CustomerSettings/CustomerSettings';
import CreateStepOneSchema from './StepOne.schema';
import PlatformSettings from '../../Common/PlatformSettings/PlatformSettings';
import {useAuthenticationService} from 'admin-portal-shared-services';
import {useCurrentLocale} from '@Hooks';
import ConsumerSettings from '../../Common/ConsumerSettings/ConsumerSettings';
import {CreateCampaignStepProps} from '@Models';
import {ValuesWatcher} from '@Components';
import {getCountryIsoCode3} from '@Utils';

const StepOne: React.FC<CreateCampaignStepProps> = ({
  onChange,
  initialValues,
}) => {
  const auth = useAuthenticationService();
  const locale = useCurrentLocale();

  const [schema] = CreateStepOneSchema();

  const currentMonth = useMemo(() => {
    const currentDate = new Date();
    const formatter = new Intl.DateTimeFormat(locale, {month: 'long'});
    const month = formatter.format(currentDate);

    return month.charAt(0).toUpperCase() + month.slice(1);
  }, []);

  const countryIsoCode3 = getCountryIsoCode3(auth.getCountryB2C());

  return (
    <div data-testid="step-one">
      <Formik
        initialValues={{
          campaignName: initialValues?.campaignName,
          imageFile: initialValues?.imageFile,
          imageUrl: initialValues?.imageUrl,
          campaignDescription: initialValues?.campaignDescription,
          campaignImgAlt: initialValues?.campaignImgAlt,
          landingPageUrl: initialValues?.landingPageUrl,
          template: {
            ageGate: {
              enabled: initialValues?.template?.ageGate?.enabled,
              type: initialValues?.template?.ageGate?.type,
              mobileBackground:
                initialValues?.template?.ageGate?.mobileBackground,
              mobileBackgroundFile:
                initialValues?.template?.ageGate?.mobileBackgroundFile,
              desktopBackground:
                initialValues?.template?.ageGate?.desktopBackground,
              desktopBackgroundFile:
                initialValues?.template?.ageGate?.desktopBackgroundFile,
            },
            logoFile: initialValues?.template.logoFile,
            logo: initialValues?.template.logo,
            headerColor: initialValues?.template.headerColor,
            contacts: initialValues?.template.contacts ?? [
              {type: '', value: ''},
            ],
            registrationBanner: initialValues?.template.registrationBanner,
            registrationBannerFile:
              initialValues?.template.registrationBannerFile,
            govIdentifier: initialValues?.template.govIdentifier ?? false,
            pocPinType: initialValues?.template.pocPinType ?? 'DEFAULT',
            customPin: initialValues?.template.customPin,
            customPinFile: initialValues?.template.customPinFile,
            ageGateColor: initialValues?.template.ageGateColor,
            linksColor: initialValues?.template.linksColor,
          },
          campaignHost: initialValues?.campaignHost,
          country: auth.getCountryB2C(),
          initiative: initialValues?.initiative ?? 'B2O',
          startDateCampaign: initialValues?.startDateCampaign
            ? new Date(initialValues.startDateCampaign)
            : undefined,
          endDateCampaign: initialValues?.endDateCampaign
            ? new Date(initialValues.endDateCampaign)
            : undefined,
          year: initialValues?.year ?? new Date().getFullYear().toString(),
          uniqueName: initialValues?.uniqueName ?? '',
          launch: initialValues?.launch ?? currentMonth,
          dollarQuote: initialValues?.dollarQuote,
          couponOnSms: initialValues?.couponOnSms ?? false,
          globalB2OTag: initialValues?.globalB2OTag,
          globalB2OCountry: countryIsoCode3,
          promotions: initialValues?.promotions ?? undefined,
          maximumCouponsValidationAttemptsByPeriod:
            initialValues?.maximumCouponsValidationAttemptsByPeriod,
          periodForCouponValidation:
            initialValues?.periodForCouponValidation ?? '0',
          consumerRedemptionCouponLimit:
            initialValues?.consumerRedemptionCouponLimit,
          hasLimitPerConsumer: initialValues?.hasLimitPerConsumer ?? false,
        }}
        validationSchema={schema}
        validateOnMount
        onSubmit={() => {}}
      >
        <Form>
          <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
            <CustomerSettings />
            <ConsumerSettings />
            <PlatformSettings />
          </div>
          <ValuesWatcher onChange={onChange} />
        </Form>
      </Formik>
    </div>
  );
};

export default StepOne;

import {Sidebar} from '@Components';
import {Grid} from '@hexa-ui/components';
import {
  AddEligiblePocs,
  CouponCampaigns,
  CreateCampaign,
  CampaignDetails,
  EligiblePocs,
  CampaignPromotions,
  CampaignInCampaign,
  CampaignLegalInfo,
  CampaignUrlMedia,
  PromotionList,
  PromotionDetails,
  BulkManagePocs,
  AddEligiblePocsByPromotion,
} from '@Pages';
import {BreadcrumbProvider} from '@Providers';
import {useMemo} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

interface AppRoute {
  path: string;
  element: JSX.Element;
}

const Router = (): JSX.Element => {
  const appRoutes = useMemo<AppRoute[]>(() => {
    return [
      {
        path: '/',
        element: <Navigate to="/campaigns" replace={true} />,
      },
      {
        path: '/campaigns',
        element: <CouponCampaigns />,
      },
      {
        path: '/campaigns/create',
        element: <CreateCampaign />,
      },
      {
        path: '/campaigns/:campaignId/promotions',
        element: <CampaignPromotions />,
      },
      {
        path: '/campaigns/:campaignId/campaign',
        element: <CampaignInCampaign />,
      },
      {
        path: '/campaigns/:campaignId/legal-info',
        element: <CampaignLegalInfo />,
      },
      {
        path: '/campaigns/:campaignId/url-and-media',
        element: <CampaignUrlMedia />,
      },
      {
        path: '/campaigns/:campaignId',
        element: <CampaignDetails />,
      },
      {
        path: '/campaigns/:campaignId/eligible-pocs',
        element: <EligiblePocs />,
      },
      {
        path: '/campaigns/:campaignId/eligible-pocs/upload',
        element: <AddEligiblePocs />,
      },
      {
        path: '/campaigns/:campaignId/promotion-list',
        element: <PromotionList />,
      },
      {
        path: '/campaigns/:campaignId/promotion-list/:promotionId',
        element: <PromotionDetails />,
      },
      {
        path: '/campaigns/:campaignId/eligible-pocs/bulk-manage',
        element: <BulkManagePocs />,
      },
      {
        path: '/campaigns/:campaignId/promotion-list/:promotionId/upload',
        element: <AddEligiblePocsByPromotion />,
      },
    ];
  }, []);

  return (
    <Grid.Container>
      <BrowserRouter basename="/digital-coupons">
        <BreadcrumbProvider>
          <Sidebar />
          <Routes>
            {appRoutes.map(route => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Routes>
        </BreadcrumbProvider>
      </BrowserRouter>
    </Grid.Container>
  );
};
export default Router;

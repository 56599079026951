import MessageMap from '../../i18n';

const disablePoc: MessageMap = {
  TITLE: 'Desabilitar {pocName}?',
  ACTION: 'Sim, desabilitar PDV',
  DISABLED_PARTICIPATION_DESCRIPTION:
    'Se desabilitado, o PDV não poderá participar da promoção e validar cupons novamente.',
    SUCCESS_MESSAGE:
      '{pocName} foi desabilitado com sucesso da promoção {promotionName}.',
};

export default disablePoc;

import React from 'react';
import {useField} from 'formik';
import {Input, InputProps} from '@hexa-ui/components';
import './CustomInput.styles.css';

const CustomInput: React.FC<InputProps> = props => {
  const [field, meta] = useField(props as any);

  const {width = '100%'} = props;

  return (
    <Input
      width={width}
      hasError={Boolean(meta.touched && meta.error)}
      errorText={meta.error}
      placeholder={props.placeholder ?? ''}
      onWheel={e => e.currentTarget.blur()}
      className="custom-input"
      {...field}
      {...(props as any)}
    />
  );
};

export default CustomInput;

import {TableCardProps} from '@Models';
import {Card, Grid} from '@hexa-ui/components';
import {DropdownFilter, SearchInput} from '@martech/components';
import {doesFilterStructureHaveValue} from '@martech/utils';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import CustomTable from '../CustomTable/CustomTable';

const TableCard: React.FC<TableCardProps> = ({
  tableData,
  filter,
  schema,
  searchPlaceholder,
  handleFilterChange,
  loadData,
  onSearchTerm,
}) => {
  const {formatMessage} = useIntl();

  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [emptySearch, setEmptySearch] = useState(false);

  const customTableProps = useMemo(() => {
    const onSort = (prop?: string, direction?: string) => {
      loadData({page: 0, props: {sort: {prop, direction}, filter, searchTerm}});
    };

    return {
      ...tableData,
      loadingSearch,
      emptySearch,
      onSort,
      filtered: doesFilterStructureHaveValue(filter),
    };
  }, [tableData, loadingSearch, emptySearch]);

  const onSearch = (value: string) => {
    onSearchTerm(value);

    if (value.length === 0) {
      setSearchTerm(undefined);
      loadData({page: 0, props: {filter}});
      setEmptySearch(false);
      return;
    }
    setLoadingSearch(true);
    setSearchTerm(value);

    loadData({
      page: 0,
      props: {
        searchTerm: value,
        filter,
      },
    }).then((response: any) => {
      if (!response || (response && response.content.length === 0)) {
        setEmptySearch(true);
      } else {
        setEmptySearch(false);
      }

      setLoadingSearch(false);
    });
  };

  const handleApplyFilter = () => {
    setLoadingSearch(true);
    loadData({page: 0, props: {filter, searchTerm}}).then((response: any) => {
      if (response && response.content.length === 0) {
        setEmptySearch(true);
      } else {
        setEmptySearch(false);
      }

      setLoadingSearch(false);
    });
  };

  return (
    <Card style={{width: '100%'}} border="medium" elevated="medium">
      <Grid.Container
        type="fluid"
        style={{
          padding: '24px',
          alignItems: 'center',
          margin: 0,
        }}
      >
        <Grid.Item xs={4} style={{padding: 0}}>
          <SearchInput placeholder={searchPlaceholder} onSearch={onSearch} />
        </Grid.Item>
        <Grid.Item xs={4} style={{padding: 0, marginLeft: '8px'}}>
          <DropdownFilter
            filterButtonText={formatMessage({id: 'COMMON.FILTER.DROPDOWN'})}
            applyText={formatMessage({id: 'COMMON.FILTER.APPLY'})}
            cancelText={formatMessage({id: 'COMMON.FILTER.CANCEL'})}
            clearAllText={formatMessage({id: 'COMMON.FILTER.CLEAR_ALL'})}
            value={filter}
            schema={schema}
            onChange={handleFilterChange}
            onApply={handleApplyFilter}
          />
        </Grid.Item>
      </Grid.Container>

      <Grid.Container
        type="fluid"
        style={{margin: 0, justifyContent: 'center'}}
      >
        <CustomTable {...customTableProps} />
      </Grid.Container>
    </Card>
  );
};

export default TableCard;

import MessageMap from '../../i18n';

const common: MessageMap = {
  SIDEBAR: {
    COUPON_CAMPAIGN: 'Campanhas de cupom',
  },
  FILTER: {
    DROPDOWN: 'Filtros',
    APPLY: 'Aplicar',
    CANCEL: 'Cancelar',
    CLEAR_ALL: 'Limpar tudo',
  },
  TABLE: {
    OF: 'de',
    LOADING: 'Carregando',
    LOADING_SEARCH: 'Carregando os resultados da busca',
    EMPTY_RESULTS: 'Nenhum resultado encontrado',
    EMPTY_SEARCH: 'Tente uma nova busca utilizando termos diferentes.',
    EMPTY_FILTER: 'Tente usar filtros diferentes',
  },
  BUTTONS: {
    GO_BACK: 'Voltar',
    CANCEL: 'Cancelar',
    YES_CANCEL: 'Sim, cancelar',
    CONFIRM: 'Confirmar',
    CONTINUE: 'Continuar',
    SAVE: 'Salvar alterações',
    SELECT_FILE: 'Selecionar arquivo',
    DOWNLOAD_TEMPLATE: 'Baixar modelo',
    NO_GO_BACK: 'Não, voltar',
    SELECT: 'Selecionar',
    DELETE: 'Descartar',
    ENABLED: 'Ativado',
    DISABLED: 'Desabilitado',
    DISABLE: 'Desabilitar',
    OK: 'Ok',
  },
  FORM: {
    OPTIONAL: '(Opcional)',
    REQUIRED: 'Campo obrigatório.',
  },
  ERRORS: {
    DEFAULT: 'Algo deu errado. Tente novamente mais tarde.',
    UPLOAD: 'Formato inválido.',
    MAX_SIZE: 'O arquivo excedeu {size}MB.',
    TYPE_ERROR: 'Tipo de arquivo não suportado.',
    INVALID_URL: 'Insira uma URL válida.',
    FIELD_MIN: 'O campo deve ter pelo menos {min} caracteres.',
    SPECIAL_INVALID: 'Espaços e caracteres especiais não são suportados.',
    NUMBER_MIN: 'Valor deve ser maior que {min}.',
    NUMBER_MAX: 'Valor deve ser menor ou igual a {max}.',
    INVALID_FIELD: 'Campo inválido',
    MIN_DATE: 'Escolha uma data a partir de {date}.',
    MAX_DATE: 'Escolha uma data até {date}.',
    INVALID_DATE: 'Escolha uma data válida.',
  },
  COMPLEMENTS: {
    POINTS: 'pontos',
  },
};

export default common;

import React from 'react';
import {useIntl} from 'react-intl';
import {Button} from '@hexa-ui/components';

interface ButtonsBackSubmitProps {
  dataTestIdBtnOne: string;
  dataTestIdBtnTwo: string;
  variantBtnOne?: 'primary' | 'secondary' | 'destructive';
  styleBtnOne?: object;
  onClickBtnOne: () => void;
  onClickBtnTwo: () => void;
  textIdBtnOne: string;
  textIdBtnTwo: string;
  disabledBtnTwo: boolean;
  isLoadingBtnTwo: boolean;
}

const ButtonsBackSubmit: React.FC<ButtonsBackSubmitProps> = ({
  dataTestIdBtnOne,
  dataTestIdBtnTwo,
  variantBtnOne,
  styleBtnOne,
  onClickBtnOne,
  onClickBtnTwo,
  textIdBtnOne,
  textIdBtnTwo,
  disabledBtnTwo,
  isLoadingBtnTwo,
}) => {
  const {formatMessage} = useIntl();

  return (
    <>
      <Button
        data-testid={dataTestIdBtnOne}
        variant={variantBtnOne}
        style={styleBtnOne}
        onClick={onClickBtnOne}
      >
        {formatMessage({
          id: textIdBtnOne,
        })}
      </Button>
      <Button
        data-testid={dataTestIdBtnTwo}
        disabled={disabledBtnTwo}
        onClick={onClickBtnTwo}
        isLoading={isLoadingBtnTwo}
      >
        {formatMessage({
          id: textIdBtnTwo,
        })}
      </Button>
    </>
  );
};

export default ButtonsBackSubmit;

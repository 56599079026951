import {FilterStructure} from '@martech/utils';
import {formatDate, getDateMask} from '@Utils';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import * as yup from 'yup';

export default function useBaseCouponCampaignsFilter(): [FilterStructure, any] {
  const {formatMessage} = useIntl();

  const schema = useMemo(() => {
    return yup.object({
      campaignDateFrom: yup
        .date()
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
      campaignDateTo: yup
        .date()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .when(['campaignDateFrom'], {
          is: (campaignDateFrom: Date | undefined) => Boolean(campaignDateFrom),
          then: yup
            .date()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .min(yup.ref('campaignDateFrom'), ({min}) =>
              formatMessage(
                {
                  id: 'COUPON_CAMPAIGNS.FILTERS.INVALID_DATE',
                },
                {
                  startDate: formatDate(min),
                }
              )
            ),
        }),
    });
  }, []);

  const structure = useMemo<FilterStructure>(
    () => ({
      status: {
        label: formatMessage({id: 'COUPON_CAMPAIGNS.FILTERS.STATUS'}),
        id: 'status',
        array: true,
        options: [
          {
            id: 'active',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.STATUSES.ACTIVE',
            }),
            type: 'checkbox',
          },
          {
            id: 'scheduled',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.STATUSES.SCHEDULED',
            }),
            type: 'checkbox',
          },
          {
            id: 'finished',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.STATUSES.FINISHED',
            }),
            type: 'checkbox',
          },
        ],
      },
      campaignDate: {
        label: formatMessage({id: 'COUPON_CAMPAIGNS.FILTERS.DATES.TITLE'}),
        options: [
          {
            id: 'campaignDateFrom',
            label: formatMessage({id: 'COUPON_CAMPAIGNS.FILTERS.DATES.START'}),
            placeholder: getDateMask(),
            type: 'date',
          },
          {
            id: 'campaignDateTo',
            label: formatMessage({id: 'COUPON_CAMPAIGNS.FILTERS.DATES.END'}),
            placeholder: getDateMask(),
            type: 'date',
            endDate: true,
          },
        ],
      },
    }),
    []
  );

  return [structure, schema];
}

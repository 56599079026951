import MessageMap from '../../i18n';

const enablePoc: MessageMap = {
  TITLE: 'Enable {pocName}?',
  ENABLE_PARTICIPATION_DESCRIPTION:
    'If enabled, the POC will be able to participate in the promotion and validate coupons again.',
  ACTION: 'Yes, enable POC',
  SUCCESS_MESSAGE:
    '{pocName} was successfully enabled for the {promotionName} promotion.',
};

export default enablePoc;

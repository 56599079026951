import api from '../../api';

export default async function editCampaignService(
  campaignId: number,
  body: FormData
): Promise<any> {
  return await api.patch({
    url: `/v1/admin/campaigns/${campaignId}`,
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });
}

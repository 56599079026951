import {Breadcrumb, Grid} from '@hexa-ui/components';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

export interface BreadcrumbItem {
  name: string;
  active?: boolean;
  href?: string;
}
export type BreadcrumbProps = {
  items: BreadcrumbItem[];
  setItems: (items: BreadcrumbItem[]) => void;
};

const defaultInitialValue: BreadcrumbProps = {
  items: [],
  setItems: () => {},
};

export const BreadcrumbContext = createContext(defaultInitialValue);
export const useBreadcrumbContext = () => useContext(BreadcrumbContext);

export function useBreadcrumb(
  items: BreadcrumbItem[],
  dependencies: any[] = []
) {
  const context = useBreadcrumbContext();

  useEffect(() => {
    context.setItems(items);
  }, dependencies);
}

export default function BreadcrumbProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

  return (
    <BreadcrumbContext.Provider
      value={{items: breadcrumbItems, setItems: setBreadcrumbItems}}
    >
      <Grid.Container
        sidebar
        type="fluid"
        style={{
          margin: '1rem 8px',
          width: '100%',
        }}
        data-testid="breadcrumb-container"
      >
        <Breadcrumb.Root>
          <Breadcrumb.HomeItem asChild>
            <Link to="/" />
          </Breadcrumb.HomeItem>
          {breadcrumbItems.map(item => (
            <Breadcrumb.Item
              asChild
              key={item.name}
              isCurrentPage={item.active}
            >
              {item.href ? (
                <Link to={item.href}>{item.name}</Link>
              ) : (
                <>{item.name}</>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb.Root>
      </Grid.Container>
      {children}
    </BreadcrumbContext.Provider>
  );
}

import {
  Card,
  Checkbox,
  FileUploader,
  Grid,
  Heading,
  IconButton,
  InputWrapper,
  Radio,
  Select,
  TextButton,
} from '@hexa-ui/components';
import React, {memo, useEffect, useMemo, useState} from 'react';
import {AgeGate, Buttons, Footer, Header, Map, RegisterForm} from '@Assets';
import {
  CustomCheckbox,
  CustomInput,
  CustomSelectInput,
  CustomStyledUploader,
} from '@Components';
import SectionCard from '../SectionCard/SectionCard';
import {Download, Plus, Trash2} from '@hexa-ui/icons';
import {FieldArray, useFormikContext} from 'formik';
import {useIntl} from 'react-intl';
import {getStorageURL} from '@Utils';
import CreateStepOneSchema from '../../Create/StepOne/StepOne.schema';

interface ContactData {
  type: string;
  value: string;
}

const ConsumerSettings: React.FC = () => {
  const {formatMessage} = useIntl();
  const {values, setFieldValue} = useFormikContext<any>();

  const [, droppedFilesValidation] = CreateStepOneSchema();

  const [logoPreview, setLogoPreview] = useState<string | undefined>(undefined);
  const [pinPreview, setPinPreview] = useState<string | undefined>(undefined);
  const [fieldWithError, setFieldsWithError] = useState<string[]>([]);

  const contactTypeOptions = useMemo(() => {
    const options = ['phone', 'email', 'whatsapp', 'website'];

    return options.map(option => ({
      label: formatMessage({
        id: `COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.FOOTER.TYPES.${option.toUpperCase()}`,
      }),
      value: option,
    }));
  }, []);

  const handleLogoValidation = (
    file: File,
    callback: (uploadState: boolean) => void
  ) => {
    changeValues('template.logoFile', file);
    callback(true);
  };

  const handleRemoveLogo = () => {
    setLogoPreview(undefined);
    changeValues('template.logoFile', undefined);
  };

  const handlePinValidation = (
    file: File,
    callback: (uploadState: boolean) => void
  ) => {
    changeValues('template.customPinFile', file);
    callback(true);
  };

  const handleRemovePin = () => {
    setPinPreview(undefined);
    changeValues('template.customPin', undefined);
    changeValues('template.customPinFile', undefined);
  };

  const handleTemplateDownload = (file: string) => {
    const storageURL = getStorageURL();
    const templateUrl = `${storageURL}/templates/campaign/${file}`;
    window.open(templateUrl, '_blank');
  };

  const addInvalidField = (field: string) => {
    const fieldsError = [...fieldWithError];
    fieldsError.push(field);
    setFieldsWithError(fieldsError);
  };

  const removeInvalidField = (field: string) => {
    const fieldsError = fieldWithError.filter(item => item !== field);
    setFieldsWithError(fieldsError);
  };

  const handleFilesDropped = (files: any, field: string) => {
    const fields = field.split('.');
    const fieldName = fields[fields.length - 1];
    droppedFilesValidation(files, fieldName).then(
      (response: {valid: boolean; files: any}) => {
        if (response.valid) {
          changeValues(field, files, () => removeInvalidField(field));
        } else {
          changeValues(field, response.files, () => addInvalidField(field));
        }
      }
    );
  };

  const changeValues = (field: string, value: any, callback?: () => void) => {
    setFieldValue(field, value)
      .then(() => {
        callback && callback();
      })
      .catch(() => {
        //do nothing
      });
  };

  useEffect(() => {
    if (values.template?.customPinFile) {
      const objectUrl = URL.createObjectURL(values.template?.customPinFile);
      setPinPreview(objectUrl);
    } else if (values.template?.customPin) {
      changeValues('template.pocPinType', values.template?.pocPinType);
      setPinPreview(values.template?.customPin);
    }
  }, [values.template?.customPinFile, values.template?.customPin]);

  useEffect(() => {
    if (values.template?.pocPinType === 'DEFAULT') {
      handleRemovePin();
    }
  }, [values.template?.pocPinType]);

  useEffect(() => {
    if (values.template?.logoFile) {
      const objectUrl = URL.createObjectURL(values.template?.logoFile);
      setLogoPreview(objectUrl);
    } else if (values.template?.logo) {
      setLogoPreview(values.template?.logo);
    }
  }, [values.template?.logoFile, values.template?.logo]);

  useEffect(() => {
    if (values.template?.ageGate?.enabled === false) {
      changeValues('template.ageGate.mobileBackgroundFile', undefined);
      changeValues('template.ageGate.desktopBackgroundFile', undefined);
      changeValues('template.ageGate.type', undefined);
    }
  }, [values.template?.ageGate?.enabled]);

  useEffect(() => {
    if (values.template?.ageGate?.type) {
      changeValues('template.ageGate.enabled', true);
      changeValues('template.ageGate.type', values.template?.ageGate?.type);
    }
  }, [values.template?.ageGate?.type]);

  const addPrefix = (index: number, type: string): void => {
    const contacts = [...(values.template?.contacts || [])];
    contacts[index].type = type;
    contacts[index].value = type === 'phone' || type === 'whatsapp' ? '+' : '';

    changeValues('template.contacts', contacts);
  };

  return (
    <Card
      data-testid="consumer-settings-card"
      border="medium"
      elevated="small"
      style={{width: '100%', padding: '2rem'}}
    >
      <Grid.Container type="fluid" style={{margin: 0, gap: '1.5rem'}}>
        <Grid.Item xs={12}>
          <Heading size="H3">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.TITLE',
            })}
          </Heading>
        </Grid.Item>

        {/* AGE GATE */}
        <Grid.Item xs={12}>
          <SectionCard
            icon={AgeGate}
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.TITLE',
            })}
          >
            <Grid.Container type="fluid" style={{margin: 0}}>
              <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
                <CustomCheckbox
                  id="template.ageGate.enabled"
                  name="template.ageGate.enabled"
                  checked={values.template?.ageGate?.enabled}
                  label={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.CHECKBOX',
                  })}
                />
              </Grid.Item>

              {values.template?.ageGate?.enabled && (
                <>
                  <Grid.Item
                    xs={12}
                    style={{flexDirection: 'column', gap: '8px'}}
                  >
                    <InputWrapper
                      label={formatMessage({
                        id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.TYPE.TITLE',
                      })}
                      width="100%"
                    >
                      <Radio.Root
                        onValueChange={value =>
                          changeValues('template.ageGate.type', value)
                        }
                        style={{
                          display: 'flex',
                          gap: '1rem',
                        }}
                      >
                        <React.Fragment key=".0">
                          <Radio.Item
                            id="yesOrNo"
                            label={formatMessage({
                              id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.TYPE.FIRST',
                            })}
                            value="yes-or-no"
                            checked={
                              values.template?.ageGate?.type === 'yes-or-no'
                            }
                          />
                          <Radio.Item
                            id="date"
                            label={formatMessage({
                              id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.TYPE.SECOND',
                            })}
                            value="birth-date"
                            checked={
                              values.template?.ageGate?.type === 'birth-date'
                            }
                          />
                        </React.Fragment>
                      </Radio.Root>
                    </InputWrapper>
                  </Grid.Item>

                  <Grid.Item
                    xs={6}
                    style={{
                      flexDirection: 'column',
                      gap: '8px',
                      marginTop: '0.5rem',
                    }}
                  >
                    <InputWrapper
                      label={formatMessage({
                        id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.MOBILE_BG.TITLE',
                      })}
                      width="100%"
                    >
                      <FileUploader
                        accept=".png,.jpg"
                        maxFiles={1}
                        imageFit="contain"
                        message={formatMessage({
                          id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.MOBILE_BG.DESCRIPTION',
                        })}
                        onDrop={filesDropped => {
                          handleFilesDropped(
                            filesDropped,
                            'template.ageGate.mobileBackgroundFile'
                          );
                        }}
                        onClean={() => {
                          changeValues(
                            'template.ageGate.mobileBackgroundFile',
                            ''
                          );
                        }}
                        buttonIntl={formatMessage({
                          id: 'COMMON.BUTTONS.SELECT_FILE',
                        })}
                        value={values.template?.ageGate?.mobileBackgroundFile}
                        error={
                          fieldWithError.includes(
                            'template.ageGate.mobileBackgroundFile'
                          )
                            ? {
                                message:
                                  values.template?.ageGate
                                    ?.mobileBackgroundFile[0].errors[0],
                              }
                            : undefined
                        }
                      />
                    </InputWrapper>
                    <TextButton
                      data-testid="mobile-background-template"
                      icon={Download}
                      iconPosition="leading"
                      size="small"
                      onClick={() =>
                        handleTemplateDownload(
                          'mobile_bg_template_1180_1920.png'
                        )
                      }
                      type="button"
                    >
                      {formatMessage({
                        id: 'COMMON.BUTTONS.DOWNLOAD_TEMPLATE',
                      })}
                    </TextButton>
                  </Grid.Item>

                  <Grid.Item
                    xs={6}
                    style={{
                      flexDirection: 'column',
                      gap: '8px',
                      marginTop: '0.5rem',
                    }}
                  >
                    <InputWrapper
                      label={formatMessage({
                        id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.DESKTOP_BG.TITLE',
                      })}
                      width="100%"
                    >
                      <FileUploader
                        accept=".png,.jpg"
                        maxFiles={1}
                        imageFit="contain"
                        message={formatMessage({
                          id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.DESKTOP_BG.DESCRIPTION',
                        })}
                        onDrop={filesDropped => {
                          handleFilesDropped(
                            filesDropped,
                            'template.ageGate.desktopBackgroundFile'
                          );
                        }}
                        onClean={() => {
                          changeValues(
                            'template.ageGate.desktopBackgroundFile',
                            ''
                          );
                        }}
                        buttonIntl={formatMessage({
                          id: 'COMMON.BUTTONS.SELECT_FILE',
                        })}
                        value={values.template?.ageGate?.desktopBackgroundFile}
                        error={
                          fieldWithError.includes(
                            'template.ageGate.desktopBackgroundFile'
                          )
                            ? {
                                message:
                                  values.template?.ageGate
                                    ?.desktopBackgroundFile[0].errors[0],
                              }
                            : undefined
                        }
                      />
                    </InputWrapper>
                    <TextButton
                      data-testid="desktop-background-template"
                      icon={Download}
                      iconPosition="leading"
                      size="small"
                      onClick={() =>
                        handleTemplateDownload(
                          'desktop_bg_template_1920_930.png'
                        )
                      }
                      type="button"
                    >
                      {formatMessage({
                        id: 'COMMON.BUTTONS.DOWNLOAD_TEMPLATE',
                      })}
                    </TextButton>
                  </Grid.Item>
                </>
              )}
            </Grid.Container>
          </SectionCard>
        </Grid.Item>

        {/* HEADER */}
        <Grid.Item xs={12}>
          <SectionCard
            icon={Header}
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.HEADER.TITLE',
            })}
            withPreview
            preview={logoPreview}
            color={values.template?.headerColor}
          >
            <div
              style={{
                marginBottom: '1rem',
                width: '100%',
              }}
            >
              <CustomStyledUploader
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.HEADER.LOGO.TITLE',
                })}
                acceptedTypes="image/png,image/jpg,image/jpeg"
                type="img"
                maxSizeInMB={1}
                onChange={handleLogoValidation}
                onRemove={handleRemoveLogo}
                placeholder={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.HEADER.LOGO.DESCRIPTION',
                })}
                value={values.template?.logoFile}
              />
            </div>
            <CustomInput
              data-testid="header-color"
              name="template.headerColor"
              label={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.HEADER.COLOR.TITLE',
              })}
              hint={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.HEADER.COLOR.HINT',
              })}
              width="50%"
            />
          </SectionCard>
        </Grid.Item>

        {/* FOOTER */}
        <Grid.Item xs={12}>
          <SectionCard
            icon={Footer}
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.FOOTER.TITLE',
            })}
          >
            <Grid.Container
              type="fluid"
              style={{
                margin: 0,
                width: '100%',
              }}
            >
              <FieldArray name="template.contacts">
                {({push, remove}) => (
                  <>
                    {values.template?.contacts?.map(
                      (_: ContactData, index: number) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'end',
                            width: '100%',
                            marginTop: index !== 0 ? '1rem' : 0,
                          }}
                        >
                          <Grid.Item xs={4}>
                            <CustomSelectInput
                              data-testid={`contact-type-${index}`}
                              label={formatMessage(
                                {
                                  id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.FOOTER.CONTACT.TYPE',
                                },
                                {index: index + 1}
                              )}
                              name={`template.contacts[${index}].type`}
                              onChange={type => addPrefix(index, type)}
                            >
                              {contactTypeOptions.map(item => (
                                <Select.Option
                                  key={item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </Select.Option>
                              ))}
                            </CustomSelectInput>
                          </Grid.Item>
                          <Grid.Item xs={7}>
                            <CustomInput
                              data-testid={`contact-${index}`}
                              name={`template.contacts[${index}].value`}
                              label={formatMessage(
                                {
                                  id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.FOOTER.CONTACT.CONTACT',
                                },
                                {index: index + 1}
                              )}
                            />
                          </Grid.Item>
                          {index !== 0 && (
                            <Grid.Item
                              xs={1}
                              style={{alignSelf: 'center', marginTop: '1rem'}}
                            >
                              <IconButton
                                icon={Trash2}
                                variant="inherit"
                                type="button"
                                onClick={() => remove(index)}
                              />
                            </Grid.Item>
                          )}
                        </div>
                      )
                    )}
                    <Grid.Item xs={12} style={{marginTop: '1.5rem'}}>
                      <TextButton
                        data-testid="add-contact"
                        icon={Plus}
                        iconPosition="leading"
                        size="small"
                        onClick={() => push({type: '', value: ''})}
                        type="button"
                      >
                        {formatMessage({
                          id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.FOOTER.ADD',
                        })}
                      </TextButton>
                    </Grid.Item>
                  </>
                )}
              </FieldArray>
            </Grid.Container>
          </SectionCard>
        </Grid.Item>

        {/* REGISTER FORM */}
        <Grid.Item xs={12}>
          <SectionCard
            icon={RegisterForm}
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.REGISTER_FORM.TITLE',
            })}
          >
            <Grid.Container type="fluid" style={{margin: 0}}>
              <Grid.Item
                xs={6}
                style={{
                  flexDirection: 'column',
                  gap: '8px',
                  marginTop: '0.5rem',
                }}
              >
                <InputWrapper
                  label={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.REGISTER_FORM.BANNER.TITLE',
                  })}
                  width="100%"
                >
                  <FileUploader
                    accept=".png,.jpg"
                    maxFiles={1}
                    imageFit="contain"
                    message={formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.REGISTER_FORM.BANNER.DESCRIPTION',
                    })}
                    onDrop={filesDropped => {
                      handleFilesDropped(
                        filesDropped,
                        'template.registrationBannerFile'
                      );
                    }}
                    onClean={() => {
                      changeValues(
                        'template.registrationBannerFile',
                        undefined
                      );
                    }}
                    value={values.template?.registrationBannerFile}
                    error={
                      fieldWithError.includes('template.registrationBannerFile')
                        ? {
                            message:
                              values.template?.registrationBannerFile[0]
                                .errors[0],
                          }
                        : undefined
                    }
                    buttonIntl={formatMessage({
                      id: 'COMMON.BUTTONS.SELECT_FILE',
                    })}
                  />
                </InputWrapper>
                <TextButton
                  data-testid="registration-banner-template"
                  icon={Download}
                  iconPosition="leading"
                  size="small"
                  onClick={() =>
                    handleTemplateDownload(
                      'register_banner_template_1920_1280.png'
                    )
                  }
                  type="button"
                >
                  {formatMessage({
                    id: 'COMMON.BUTTONS.DOWNLOAD_TEMPLATE',
                  })}
                </TextButton>
              </Grid.Item>
              <Grid.Item xs={12} style={{marginTop: '2rem'}}>
                <Checkbox
                  id="template.govIdentifier"
                  name="template.govIdentifier"
                  label={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.REGISTER_FORM.UNIQUE_ID',
                  })}
                  checked={values.template?.govIdentifier}
                  onCheckedChange={(checked: boolean) => {
                    changeValues('template.govIdentifier', checked);
                  }}
                />
              </Grid.Item>
            </Grid.Container>
          </SectionCard>
        </Grid.Item>

        {/* MAP */}
        <Grid.Item xs={12}>
          <SectionCard
            icon={Map}
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.TITLE',
            })}
            withPreview={
              values.template?.pocPinType === 'CUSTOM' ? true : false
            }
            previewType="map"
            preview={pinPreview}
          >
            <Grid.Container
              type="fluid"
              style={{
                margin: '0 0 1rem 0',
              }}
            >
              <Grid.Item xs={12} style={{flexDirection: 'column'}}>
                <InputWrapper
                  label={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.PIN_TYPE.TITLE',
                  })}
                  width="100%"
                >
                  <Radio.Root
                    onValueChange={value =>
                      changeValues('template.pocPinType', value)
                    }
                    style={{
                      display: 'flex',
                      gap: '1rem',
                    }}
                  >
                    <React.Fragment key=".0">
                      <Radio.Item
                        id="default"
                        label={formatMessage({
                          id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.PIN_TYPE.FIRST',
                        })}
                        value="DEFAULT"
                        checked={values.template?.pocPinType === 'DEFAULT'}
                      />
                      <Radio.Item
                        id="custom"
                        label={formatMessage({
                          id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.PIN_TYPE.SECOND',
                        })}
                        value="CUSTOM"
                        checked={values.template?.pocPinType === 'CUSTOM'}
                      />
                    </React.Fragment>
                  </Radio.Root>
                </InputWrapper>
              </Grid.Item>

              {values.template?.pocPinType === 'CUSTOM' && (
                <Grid.Item xs={12}>
                  <CustomStyledUploader
                    label={formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.CUSTOM.TITLE',
                    })}
                    acceptedTypes="image/png,image/jpg,image/jpeg"
                    type="img"
                    maxSizeInMB={1}
                    onChange={handlePinValidation}
                    onRemove={handleRemovePin}
                    placeholder={formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.CUSTOM.DESCRIPTION',
                    })}
                    value={values.template?.customPinFile}
                  />
                </Grid.Item>
              )}
            </Grid.Container>
          </SectionCard>
        </Grid.Item>

        {/* BUTTON AND TEXT */}
        <Grid.Item xs={12}>
          <SectionCard
            icon={Buttons}
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.BUTTONS.TITLE',
            })}
            withPreview
            previewType="button"
            color={values.template?.ageGateColor}
            secondaryColor={values.template?.linksColor}
          >
            <Grid.Container
              type="fluid"
              style={{
                margin: '0 0 1rem 0',
                gap: '1rem',
              }}
            >
              <Grid.Item xs={12}>
                <CustomInput
                  data-testid="button-and-age-gate"
                  name="template.ageGateColor"
                  label={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.BUTTONS.FIRST.TITLE',
                  })}
                  hint={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.BUTTONS.FIRST.HINT',
                  })}
                  width="60%"
                />
              </Grid.Item>
              <Grid.Item xs={12}>
                <CustomInput
                  data-testid="button-and-link"
                  name="template.linksColor"
                  label={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.BUTTONS.SECOND.TITLE',
                  })}
                  hint={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.BUTTONS.SECOND.HINT',
                  })}
                  width="60%"
                />
              </Grid.Item>
            </Grid.Container>
          </SectionCard>
        </Grid.Item>
      </Grid.Container>
    </Card>
  );
};

export default memo(ConsumerSettings);

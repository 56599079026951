import {enableDisablePocService} from '@Services';

async function enableDisablePocUseCase(
  pocCode: string,
  campaignId: number,
  blocked: boolean,
  promotionId: number
): Promise<any> {
  const response = await enableDisablePocService(
    pocCode,
    campaignId,
    blocked,
    promotionId
  );

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default enableDisablePocUseCase;

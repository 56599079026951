import {CreateCampaignProps} from '@Models';
import api from '../../api';

export default async function getCampaignDetailsService(
  id: number
): Promise<CreateCampaignProps | any> {
  return await api.get({
    url: `/v1/admin/campaigns/${id}?isPromotionActive=true`,
  });
}

import {Grid} from '@hexa-ui/components';
import {CreateCampaignProps} from '@Models';
import {BorderContainer, TextItem} from '@martech/components';
import {useIntl} from 'react-intl';
import {getFileName, getLinkForRedirect} from '@Utils';
import CardForEdit from '../CardForEdit/CardForEdit';

export default function LegalInfoSummary({
  values,
  onEdit,
}: {
  values: CreateCampaignProps | undefined;
  onEdit?: () => void;
}) {
  const {formatMessage} = useIntl();

  return (
    <CardForEdit
      id="legal-info"
      title={formatMessage({id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.TITLE'})}
      onEdit={onEdit}
    >
      <Grid.Item xs={6}>
        <BorderContainer
          heading={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CONSUMER_SECTION.TITLE',
          })}
        >
          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CONSUMER_SECTION.TERMS_OF_USE',
            })}
            xs={12}
            value={values?.termsUrl}
            isLink
            linkTo={
              values?.termsUrl ? getLinkForRedirect(values?.termsUrl) : ''
            }
          />

          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CONSUMER_SECTION.PRIVACY_POLICY',
            })}
            xs={12}
            value={values?.privacyUrl}
            isLink
            linkTo={
              values?.privacyUrl ? getLinkForRedirect(values?.privacyUrl) : ''
            }
          />

          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CONSUMER_SECTION.CAMPAIGN_RULES',
            })}
            value={
              values?.campaignRules
                ? values.campaignRules.name
                : getFileName(values?.campaignRulesUrl)
            }
            xs={12}
            isLink
            linkTo={
              values?.campaignRules
                ? URL.createObjectURL(values.campaignRules)
                : values?.campaignRulesUrl
            }
          />
        </BorderContainer>
      </Grid.Item>
      <Grid.Item xs={6}>
        <BorderContainer
          heading={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CUSTOMER_SECTION.TITLE',
          })}
        >
          {values?.legalInfoForParticipantPocs &&
            values.legalInfoForParticipantPocs.map((document, index) => (
              <TextItem
                key={index}
                title={formatMessage(
                  {
                    id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CUSTOMER_SECTION.DOCUMENT.TITLE',
                  },
                  {index: index + 1}
                )}
                value={document.title}
                xs={12}
              />
            ))}
        </BorderContainer>
      </Grid.Item>
    </CardForEdit>
  );
}

import {EligiblePocsSummary} from '@Models';
import api from '../../api';

export default async function getEligiblePocsSummaryService(
  campaignId: string
): Promise<EligiblePocsSummary | any> {
  return await api.get({
    url: `/v1/admin/campaigns/${campaignId}/eligible-pocs-summary`,
  });
}

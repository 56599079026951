import {
  Country,
  getEnvironmentFromToken,
  getJsonFirebaseFeature,
} from '@martech/utils';
import {useAuthenticationService} from 'admin-portal-shared-services';
import {FeatureFlag} from '../../models';

export default function useFeatureFlag(name: string): boolean {
  const firebaseFeature = getJsonFirebaseFeature(name) as FeatureFlag;
  const authService = useAuthenticationService();

  const userEnv = getEnvironmentFromToken(authService.getIssuer());
  const userCountry = authService.getCountryB2C().toUpperCase() as Country;

  return (
    firebaseFeature &&
    firebaseFeature.active &&
    isFeatureEnabledInUserContext(userEnv, userCountry, firebaseFeature)
  );
}

function isFeatureEnabledInUserContext(
  userEnv: string,
  userCountry: Country,
  featureFlag: FeatureFlag
): boolean {
  type featureKey = keyof typeof featureFlag;

  return Boolean(
    (featureFlag[userEnv.toUpperCase() as featureKey] as Country[]).find(
      country => country === userCountry || country === 'ALL'
    )
  );
}

import MessageMap from '../../i18n';

const campaignEligiblePocs: MessageMap = {
  ELIGIBLE_POCS: 'PDV elegível',
  LIST: {
    SUMMARY: 'Resumo',
    ELIGIBLE_POCS_FOR: 'PDVs elegíveis para {title}',
    ELIGIBLE_POCS: 'PDVs elegíveis',
    PARTICIPATING: 'PDVs participantes',
    BLOCKED: 'PDVs bloqueados',
    ADD_BUTTON: 'Adicionar PDVs elegíveis',
    SEARCH: 'Pesquisar por código ou nome do PDV',
    POC_ID: 'Código do PDV',
    POC_NAME: 'Nome do PDV ',
    STATUS: 'Status',
    ADDED_ON: 'Adicionado em',
    BLOCKED_ON: 'Bloqueado em',
    ACTION: 'Ação',
    BULK_MANAGE_BUTTON: 'Gerenciar em massa PDVs',

    NO_DATA: {
      TITLE: 'Esta campanha ainda não tem PDVs elegíveis',
      DESCRIPTION: 'Adicione-os clicando em “Adicionar PDVs elegíveis”.',
    },
    FILTER: 'Status',
    STATUSES: {
      PARTICIPATING: 'Participando',
      NOT_PARTICIPATING: 'Não participando',
      BLOCKED_MANUAL: 'Bloqueado - Manualmente',
      BLOCKED_MANUAL_DISABLED: 'Bloqueado manualmente - Desativada na campanha',
      BLOCKED_MANUALLY_FRAUD_PREVENTION:
        'Bloqueado manualmente - Prevenção de fraude',
      BLOCKED_AUTOMATIC: 'Bloqueado - Prevenção de fraude',
      DISABLED_ON_PROMOTION: 'Desativado manualmente na promoção',
    },
    STATUSES_TOOLTIP_INFO: {
      BLOCKED_MANUAL: 'Participação desativada.',
      BLOCKED_MANUALLY_FRAUD_PREVENTION: 'Bloqueado para prevenção a fraudes.',
    },
    ACTIONS: {
      BLOCK: 'Bloquear PDV',
      UNBLOCK: 'Desbloquear PDV',
    },
  },
  ADD: {
    TITLE: 'Adicionar PDVs elegíveis - {title}',
    BREADCRUMB: 'Adicionar PDVs elegíveis',
    FILE_UPLOAD: 'Envio de arquivo',
    FILE_DESCRIPTION_ONE:
      'Os PDVs adicionados serão habilitados para participar da campanha {title}.',
    FILE_DESCRIPTION_TWO:
      'O arquivo deve conter apenas códigos do PDV, separados por quebras de linha.',
    FILE_DESCRIPTION_THREE:
      'Siga as instruções dentro do modelo para adicionar uma lista com sucesso.',
    FILE_DESCRIPTION_PROMOTION:
      'Os PDVs adicionados serão habilitados para participar da promoção {title}.',
    DOWNLOAD_TEMPLATE: 'Baixar modelo de planilha',
    DROP_PLACEHOLDER:
      'O arquivo deve estar no formato .csv e ter menos de 2 MB de tamanho.',
    UPLOAD_INFO:
      'Este arquivo adicionará novos códigos à lista atual de PDVs sem substituí-la.',
    INVALID_ERROR:
      'Formato de dados incorreto. Baixe o modelo e siga suas instruções.',
    TYPE_ERROR: 'Tipo de arquivo não suportado.',
    BROWSE_FILE: 'Selecionar arquivo',
    UPLOAD_ANALYZE_TITLE: 'Análise do arquivo carregado',
    PROCESSED: '{processed} de {total}',
    STARTING: 'Iniciando',
    POCS_READY: 'PDVs prontos para serem adicionados',
    POCS_ERROR: 'PDVs com informações inconsistentes',
    UPLOADED_ALERT:
      'Para revisar observações de erro, exporte a lista de PDVs com informações inconsistentes. Em seguida, você pode enviar um novo arquivo corrigido na etapa anterior ou prosseguir apenas com os PDVs prontos para serem adicionados.',
    EXPORT_ERROR_POCS: 'Exportar PDVs inconsistentes',
    CANCEL: 'Cancelar',
    ADD_POCS: 'Adicionar PDVs',
    SUCCESS_MESSAGE:
      '{value} PDVs foram adicionados com sucesso à campanha {campaignName}.',
    SUCCESS_MESSAGE_PROMOTION:
      '{value} PDVs foram adicionados com sucesso à promoção {promotionName}.',
  },
  BULK_MANAGE: {
    TITLE: 'Gerenciar PDVs em massa - {title}',
    BREADCRUMB: 'Gerenciar PDVs em massa',
    SELECT_BULK_ACTION: 'Selecione uma ação em massa',
    MANAGE_POCS_BUTTON: 'Gerenciar PDVs',
    BLOCK_FRAUD_PREVENTION_ACTION: 'Bloquear - Prevenção de fraude',
    UNBLOCK_FRAUD_PREVENTION_ACTION: 'Desbloquear - Prevenção de fraude',
    DISABLE_PARTICIPATION_ACTION: 'Desativar participação',
    ENABLE_PARTICIPATION_ACTION: 'Ativar participação',
    BLOCK_FRAUD_PREVENTION_DESCRIPTION:
      'Esta opção deve ser usada em caso de atividades suspeitas. Os PDVs não poderão resgatar cupons em nenhuma campanha.',
    UNBLOCK_FRAUD_PREVENTION_DESCRIPTION:
      'Uma vez desbloqueado, os PDVs poderão usar esses pontos BEES e validar cupons novamente.',
    UNBLOCK_FRAUD_PREVENTION_DESCRIPTION_TWO:
      'Esta ação desbloqueará os PDVs que foram bloqueados pela prevenção de fraude automática, liberando os pontos em todas as campanhas.',
    DISABLE_PARTICIPATION_DESCRIPTION:
      'Desativa os PDVs para participar da campanha e validar cupons novamente.',
    ENABLE_PARTICIPATION_DESCRIPTION:
      'Uma vez habilitado, os PDVs participarão da campanha e poderão validar cupons novamente.',
    POC_UPLOAD_LIMIT: 'Limite de {value}k PDVs por vez',
    SUCCESS_MESSAGE_ENABLE_PARTICIPATION:
      '{value} PDVs foram habilitados com sucesso à campanha {campaignName}.',
    SUCCESS_MESSAGE_DISABLE_PARTICIPATION:
      '{value} PDVs foram desabilitados com sucesso à campanha {campaignName}.',
    SUCCESS_MESSAGE_BLOCK_FRAUD_PREVENTION:
      '{value} PDVs foram bloqueados por prevenção à fraude com sucesso à campanha {campaignName}.',
    SUCCESS_MESSAGE_UNBLOCK_FRAUD_PREVENTION:
      '{value} PDVs foram desbloqueados por prevenção à fraude com sucesso à campanha {campaignName}.',
    ERROR_MESSAGE_ENABLE_PARTICIPATION:
      '{value} PDVs não foram habilitados à campanha {campaignName}.',
    ERROR_MESSAGE_DISABLE_PARTICIPATION:
      '{value} PDVs não foram desabilitados à campanha {campaignName}.',
    ERROR_MESSAGE_BLOCK_FRAUD_PREVENTION:
      '{value} PDVs não foram bloqueados por prevenção à fraude à campanha {campaignName}.',
    ERROR_MESSAGE_UNBLOCK_FRAUD_PREVENTION:
      '{value} PDVs não foram desbloqueados por prevenção à fraude à campanha {campaignName}.',
  },
};

export default campaignEligiblePocs;

export default function countCsvLines(file: File): Promise<number> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      if (file.type !== 'text/csv') {
        throw new Error('File type must be text/csv');
      }

      reader.onload = e => {
        const fileData = e.target?.result as string;
        const fileRows = fileData.split('\n');
        const nonEmptyRows = fileRows.filter(row => row.trim() !== '');

        resolve(nonEmptyRows.length);
      };

      reader.readAsText(file);
    } catch (error) {
      reject(error);
    }
  });
}

import getAspectRatio from '../getAspectRatio/getAspectRatio';
import {ProcessedFile} from '@Models';

const processFile = (src: any): Promise<ProcessedFile> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        ratio: getAspectRatio(img.width, img.height),
        height: img.height,
        width: img.width,
      });
    };
    img.onerror = reject;
    img.src = src;
  });
};

export default processFile;

import {getInconsistenciesService} from '@Services';

async function getInconsistenciesUseCase(uploadId: string, request: any) {
  const response = await getInconsistenciesService(uploadId, request);

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default getInconsistenciesUseCase;

import api from '../../api';

export default async function getInconsistenciesService(
  uploadId: string,
  request: any
): Promise<any> {
  return await api.get({
    url: `/v1/admin/campaign-uploads/${uploadId}/inconsistencies`,
    config: {
      responseType: 'blob',
      cancelToken: request.token,
    },
  });
}

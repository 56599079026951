import {
  Button,
  Card,
  Divider,
  Grid,
  Heading,
  Paragraph,
  TextLink,
  Tooltip,
} from '@hexa-ui/components';
import {useIntl} from 'react-intl';
import {ToastData, useBreadcrumb, useToast} from '@Providers';
import {Edit2, Plus} from '@hexa-ui/icons';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import {
  EligiblePocsSummary,
  LoadDataProps,
  POC,
  Paginated,
  PocCampaignsSummary,
  SortData,
  POCStatusTypes,
  ColumnsTableDataOne,
} from '@Models';
import {
  getEligiblePocsSummaryUseCase,
  getEligiblePocsUseCase,
  getCampaignsAndPointsUseCase,
  blockUnblockPocUseCase,
  blockPocManuallyFraudPreventionUseCase,
} from '@UseCase';
import {
  StatusDot,
  TableCard,
  BlockPocModal,
  UnblockPocModal,
} from '@Components';
import {formatDate} from '@Utils';
import useBaseEligiblePocsFilter from './EligiblePocs.filter';
export default function EligiblePocs() {
  const navigate = useNavigate();
  const {formatMessage} = useIntl();
  const {campaignId} = useParams();
  const {toast} = useToast();
  const [summary, setSummary] = useState<EligiblePocsSummary>({
    campaignId: 0,
    campaignName: '',
    blocked: 0,
    eligible: 0,
    participant: 0,
  });
  const baseFilter = useBaseEligiblePocsFilter();
  const [filter, setFilter] = useState(baseFilter);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortProp, setSortProp] = useState<SortData>({
    prop: 'pocName',
    direction: 'ASC',
  });
  const [loadingData, setLoadingData] = useState(false);
  const [loadingBlockUnblock, setLoadingBlockUnblock] = useState(false);
  const [pocs, setPocs] = useState<Paginated<POC>>({
    content: [],
    pagination: {
      page: 0,
      pageSize: 0,
      totalElements: 0,
      totalPages: 0,
    },
  });
  const [pocSummary, setPocSummary] = useState({id: '', name: '', status: ''});
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [unblockModalOpen, setUnblockModalOpen] = useState(false);
  const [campaignsSummary, setCampaignsSummary] = useState<
    PocCampaignsSummary[]
  >([
    {
      campaignName: '',
      campaignId: 0,
      points: 0,
      status: '',
      campaignTitle: '',
    },
  ]);
  const updateSummary = () => {
    if (campaignId) {
      getEligiblePocsSummaryUseCase(campaignId)
        .then(response => setSummary(response))
        .catch(error => {
          console.error(error);
        });
      loadPocs({page: 0, props: {filter, searchTerm}});
    }
  };
  const openBlockModal = () => {
    setBlockModalOpen(true);
  };
  const closeBlockModal = () => {
    setBlockModalOpen(false);
  };
  const showToastBlockPocMessage = (
    messageId: string,
    type: ToastData['type']
  ) => {
    toast({
      message: formatMessage(
        {id: messageId},
        {
          pocName: pocSummary.name,
          campaignName: summary.campaignName,
        }
      ),
      type: type,
    });
  };
  const blockPocManuallyFraudPrevention = () => {
    setLoadingBlockUnblock(true);
    blockPocManuallyFraudPreventionUseCase(pocSummary.id, summary.campaignId)
      .then(() => {
        showToastBlockPocMessage('BLOCK_POC.SUCCESS_MESSAGE', 'success');
      })
      .catch(() => {
        showToastBlockPocMessage('COMMON.ERRORS.DEFAULT', 'error');
      })
      .finally(() => {
        setLoadingBlockUnblock(false);
        closeBlockModal();
        updateSummary();
      });
  };
  const isUnblockForFraud = () => {
    if (
      pocSummary?.status === POCStatusTypes.BLOCKED_MANUALLY_FRAUD_PREVENTION ||
      pocSummary?.status === POCStatusTypes.BLOCKED_AUTOMATIC
    ) {
      return true;
    } else if (pocSummary?.status === POCStatusTypes.BLOCKED_MANUAL_DISABLED) {
      return false;
    } else {
      return undefined;
    }
  };
  const blockPoc = () => {
    setLoadingBlockUnblock(true);
    blockUnblockPocUseCase(
      pocSummary.id,
      summary.campaignId,
      true,
      isUnblockForFraud()
    )
      .then(() => {
        showToastBlockPocMessage('BLOCK_POC.SUCCESS_MESSAGE', 'success');
      })
      .catch(() => {
        showToastBlockPocMessage('COMMON.ERRORS.DEFAULT', 'error');
      })
      .finally(() => {
        setLoadingBlockUnblock(false);
        closeBlockModal();
        updateSummary();
      });
  };
  const openUnblockModal = () => {
    setUnblockModalOpen(true);
  };
  const closeUnblockModal = () => {
    setUnblockModalOpen(false);
  };
  const unblockPoc = () => {
    setLoadingBlockUnblock(true);
    getCampaignsAndPointsUseCase(pocSummary.id).then(response => {
      setCampaignsSummary(response.content);
    });
    blockUnblockPocUseCase(
      pocSummary.id,
      summary.campaignId,
      false,
      isUnblockForFraud()
    )
      .then(response => {
        toast({
          message: formatMessage(
            {id: 'UNBLOCK_POC.SUCCESS_MESSAGE_AUTOMATIC'},
            {
              pocName: pocSummary.name,
              countCampaigns: response.numberOfUnblockedCampaigns,
            }
          ),
          type: 'success',
        });
      })
      .catch(() => {
        toast({
          message: formatMessage({
            id: 'COMMON.ERRORS.DEFAULT',
          }),
          type: 'error',
        });
      })
      .finally(() => {
        setLoadingBlockUnblock(false);
        closeUnblockModal();
        updateSummary();
      });
  };
  const pocStatusMessageMap: {[key: string]: string} = {
    PARTICIPATING: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.PARTICIPATING',
    NOT_PARTICIPATING: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.NOT_PARTICIPATING',
    BLOCKED_MANUAL: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.BLOCKED_MANUAL',
    BLOCKED_AUTOMATIC: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.BLOCKED_AUTOMATIC',
    BLOCKED_MANUALLY_FRAUD_PREVENTION:
      'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.BLOCKED_MANUAL',
  };
  const getPocStatusMessage = (pocStatus: string) => {
    const pocStatusUppercase = pocStatus.replaceAll('-', '_').toUpperCase();
    const pocStatusMessage = pocStatusMessageMap[pocStatusUppercase] || '';
    return formatMessage({
      id: pocStatusMessage,
    });
  };
  const pocStatusTooltipInfoMessageMap: {[key: string]: string} = {
    BLOCKED_MANUAL:
      'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES_TOOLTIP_INFO.BLOCKED_MANUAL',
    BLOCKED_MANUALLY_FRAUD_PREVENTION:
      'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES_TOOLTIP_INFO.BLOCKED_MANUALLY_FRAUD_PREVENTION',
  };
  const showStatusTooltipInfo = (pocStatus: string) => {
    const pocStatusUppercase = pocStatus.replaceAll('-', '_').toUpperCase();
    const pocStatusTooltipInfoMessage =
      pocStatusTooltipInfoMessageMap[pocStatusUppercase];
    if (!pocStatusTooltipInfoMessage) return;
    return formatMessage({
      id: pocStatusTooltipInfoMessage,
    });
  };
  const handleBlockPoc = (poc: POC) => {
    setPocSummary({
      id: poc.pocId,
      name: poc.pocName,
      status: poc.status,
    });
    openBlockModal();
  };
  const handleUnblockPoc = async (poc: POC) => {
    setPocSummary({
      id: poc.pocId,
      name: poc.pocName,
      status: poc.status,
    });
    const isBlocked =
      poc.status === 'blocked-automatic' ||
      poc.status === 'blocked-manually-fraud-prevention';
    try {
      if (isBlocked) {
        await getCampaignsAndPointsUseCase(poc.pocId)
          .then(response => setCampaignsSummary(response.content))
          .catch(console.error);
      }
    } finally {
      openUnblockModal();
    }
  };
  const getStatusPocs = (status: string) => {
    if (status === 'participating') {
      return 'success';
    }
    if (status === 'not-participating') {
      return 'info';
    }
    return 'error';
  };

  const tableData = useMemo(() => {
    return {
      table: {
        columns: ColumnsTableDataOne(
          ['pocId', 'pocName', 'status', 'uploadDate', 'blockedAt', 'actions'],
          [
            formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.POC_ID'}),
            formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.POC_NAME'}),
            formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUS'}),
            formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.ADDED_ON'}),
            formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.BLOCKED_ON'}),
            formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.ACTION'}),
          ],
          true
        ),
        cellHeight: 'tight',
        fontSize: '12px',
        pagination: {
          current: pocs.pagination.page + 1,
          pageSize: 50,
          onChange: (page: number) => {
            loadPocs({page: page - 1, props: {filter, searchTerm}});
          },
          total: pocs.pagination.totalElements,
        },
        data: pocs.content.map((poc, index) => ({
          pocId: poc.pocId,
          pocName:
            poc.pocName?.length > 25 ? (
              <Tooltip text={poc.pocName}>
                {`${poc.pocName.slice(0, 25)}...`}
              </Tooltip>
            ) : (
              poc.pocName
            ),
          status: (
            <StatusDot
              status={getStatusPocs(poc.status)}
              text={getPocStatusMessage(poc.status)}
              infoTooltip={showStatusTooltipInfo(poc.status)}
            />
          ),
          uploadDate: formatDate(poc.uploadDate),
          blockedAt:
            poc.blocked && poc.blockedAt ? formatDate(poc.blockedAt) : 'N/A',
          actions:
            getStatusPocs(poc.status) !== 'error' ? (
              <TextLink
                data-testid={`block-poc-action-${index}`}
                size="xsmall"
                onClick={() => {
                  handleBlockPoc(poc);
                }}
                children={formatMessage({
                  id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.ACTIONS.BLOCK',
                })}
              />
            ) : (
              <TextLink
                data-testid={`unblock-poc-action-${index}`}
                size="xsmall"
                onClick={() => {
                  handleUnblockPoc(poc);
                }}
                children={formatMessage({
                  id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.ACTIONS.UNBLOCK',
                })}
                style={{color: '#c9201d'}}
              />
            ),
        })),
      },
      loadingData,
      noDataTitle: formatMessage({
        id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.NO_DATA.TITLE',
      }),
      noDataDescription: formatMessage({
        id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.NO_DATA.DESCRIPTION',
      }),
    };
  }, [loadingData, pocs, searchTerm]);

  useEffect(() => {
    if (campaignId) {
      getEligiblePocsSummaryUseCase(campaignId)
        .then(response => setSummary(response))
        .catch(error => {
          console.error(error);
        });
      loadPocs({});
    }
  }, []);
  const loadPocs = async ({page = 0, props}: LoadDataProps) => {
    setLoadingData(true);
    setPocs({
      content: [],
      pagination: {
        page: 0,
        pageSize: 0,
        totalElements: 0,
        totalPages: 0,
      },
    });
    try {
      const sortOption = props?.sort || sortProp;
      const fetchPocs = await getEligiblePocsUseCase(+campaignId!, page, {
        ...props,
        sort: sortOption,
      });
      setSortProp(sortOption);
      setPocs(fetchPocs);
      return fetchPocs;
    } catch {
      return undefined;
    } finally {
      setLoadingData(false);
    }
  };
  useBreadcrumb(
    [
      {
        name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
        href: '/campaigns',
      },
      {
        name: summary.campaignName,
      },
      {
        name: formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.ELIGIBLE_POCS'}),
        active: true,
      },
    ],
    [summary.campaignName]
  );
  return (
    <Grid.Container
      sidebar
      data-testid="eligible-pocs-container"
      type="fluid"
      style={{margin: 0, width: '100%'}}
    >
      <BlockPocModal
        data-testid="block-poc-modal"
        pocName={pocSummary.name}
        isOpen={blockModalOpen}
        onClose={closeBlockModal}
        onBlockPocManuallyFraudPrevention={blockPocManuallyFraudPrevention}
        onBlock={blockPoc}
        loading={loadingBlockUnblock}
      />
      <UnblockPocModal
        data-testid="unblock-poc-modal"
        pocName={pocSummary.name}
        pocStatus={pocSummary.status}
        campaigns={campaignsSummary}
        isOpen={unblockModalOpen}
        onClose={closeUnblockModal}
        onUnblock={unblockPoc}
        loading={loadingBlockUnblock}
      />
      <Grid.Item xs={12}>
        <Heading data-testid="campaignTitle" size="H2">
          {formatMessage(
            {id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.ELIGIBLE_POCS_FOR'},
            {title: summary.campaignName}
          )}
        </Heading>
      </Grid.Item>
      <Grid.Item style={{marginTop: '1.5rem'}} xs={12}>
        <Card
          style={{width: '100%', height: 135, padding: '1.5rem'}}
          elevated="medium"
          border="medium"
        >
          <Grid.Item xs={12}>
            <Heading size="H5">
              {formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.SUMMARY'})}
            </Heading>
          </Grid.Item>
          <Grid.Item style={{alignItems: 'center'}} xs={12}>
            <Grid.Item
              style={{padding: 0, justifyContent: 'space-between'}}
              xs={5}
            >
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Paragraph>
                  {formatMessage({
                    id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.ELIGIBLE_POCS',
                  })}
                </Paragraph>
                <Heading size="H4">{summary.eligible}</Heading>
              </div>
              <div>
                <Divider orientation="vertical" />
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Paragraph>
                  {formatMessage({
                    id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.PARTICIPATING',
                  })}
                </Paragraph>
                <Heading size="H4">{summary.participant}</Heading>
              </div>
              <div>
                <Divider orientation="vertical" />
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Paragraph>
                  {formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.BLOCKED'})}
                </Paragraph>
                <Heading size="H4">{summary.blocked}</Heading>
              </div>
            </Grid.Item>
            <Grid.Item style={{justifyContent: 'flex-end'}} xs={7}>
              <Button
                data-testid="bulk-manage-pocs-button"
                variant="secondary"
                onClick={() =>
                  navigate('bulk-manage', {
                    state: summary,
                  })
                }
                icon={Edit2}
                leading
              >
                {formatMessage({
                  id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.BULK_MANAGE_BUTTON',
                })}
              </Button>
              <Button
                data-testid="upload-pocs-button"
                onClick={() =>
                  navigate('upload', {
                    state: summary,
                  })
                }
                icon={Plus}
                leading
                style={{marginLeft: '1rem'}}
              >
                {formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.ADD_BUTTON'})}
              </Button>
            </Grid.Item>
          </Grid.Item>
        </Card>
      </Grid.Item>
      <Grid.Item style={{margin: '1.5rem auto'}} xs={12}>
        <TableCard
          tableData={tableData}
          filter={filter}
          handleFilterChange={value => setFilter(value)}
          searchPlaceholder={formatMessage({
            id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.SEARCH',
          })}
          loadData={loadPocs}
          onSearchTerm={term => {
            setSearchTerm(term);
          }}
        />
      </Grid.Item>
    </Grid.Container>
  );
}

import {BeeFilter, BeePlus, BeeSearch} from '@Assets';
import {BeeState} from '@Components';
import {Grid, Table} from '@hexa-ui/components';
import {useTableHeader} from '@Hooks';
import {CustomTableProps} from '@Models';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

const CustomTable: React.FC<CustomTableProps> = ({
  table,
  loadingData,
  loadingSearch,
  emptySearch,
  filtered,
  noDataTitle = '',
  noDataDescription = '',
  onSort,
}) => {
  const {formatMessage} = useIntl();

  const tableHeader = useTableHeader({options: table.columns});

  const noDataYet = useMemo(() => {
    return (
      table.data.length === 0 &&
      !loadingData &&
      !loadingSearch &&
      !filtered &&
      !emptySearch
    );
  }, [table, loadingData, loadingSearch, emptySearch, filtered]);

  return (
    <>
      {noDataYet ? (
        <BeeState
          title={noDataTitle}
          description={noDataDescription}
          image={BeePlus}
        />
      ) : (
        <>
          {loadingData || loadingSearch ? (
            <BeeState
              title={
                loadingSearch
                  ? formatMessage({id: 'COMMON.TABLE.LOADING_SEARCH'})
                  : formatMessage({id: 'COMMON.TABLE.LOADING'})
              }
              description=""
              loadingBuzz
            />
          ) : (
            <>
              {((filtered && table.data.length === 0) || emptySearch) && (
                <BeeState
                  title={formatMessage({id: 'COMMON.TABLE.EMPTY_RESULTS'})}
                  description={
                    filtered
                      ? formatMessage({id: 'COMMON.TABLE.EMPTY_FILTER'})
                      : formatMessage({id: 'COMMON.TABLE.EMPTY_SEARCH'})
                  }
                  image={filtered ? BeeFilter : BeeSearch}
                />
              )}
            </>
          )}
        </>
      )}

      <Grid.Item
        data-testid="custom-table"
        xs={12}
        style={{
          padding: 0,
          fontSize: table.fontSize || 'auto',
          visibility:
            !loadingData &&
            !loadingSearch &&
            !emptySearch &&
            table.data.length > 0
              ? 'visible'
              : 'collapse',
        }}
      >
        <Table
          columns={tableHeader}
          pagination={{
            ...table.pagination,
            quantityIndicatorIntl: formatMessage({id: 'COMMON.TABLE.OF'}),
          }}
          data={table.data}
          initialSortBy={table.initialSortBy}
          onSort={onSort}
          cellHeight={table.cellHeight as any}
        />
      </Grid.Item>
    </>
  );
};

export default CustomTable;

import {Grid, Heading} from '@hexa-ui/components';
import React, {useMemo} from 'react';
import {CreateCampaignProps} from '@Models';
import {useIntl} from 'react-intl';
import {getFileFromValueArray, getFileName, getLinkForRedirect} from '@Utils';
import {BorderContainer, TextItem} from '@martech/components';
import CardForEdit from '../CardForEdit/CardForEdit';

const UrlAndMediaSummary: React.FC<{
  values: CreateCampaignProps | undefined;
  onEdit?: () => void;
}> = ({values, onEdit}) => {
  const {formatMessage} = useIntl();

  const dtcBanner = useMemo(
    () => getFileFromValueArray(values?.adSettings?.dtcBannerFile),
    [values?.adSettings?.dtcBannerFile]
  );

  const domainType = useMemo(() => {
    return formatMessage({
      id: `COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.DOMAIN_TYPE.${
        values?.adSettings?.domainType
          ? values?.adSettings?.domainType.toUpperCase()
          : 'BEESDC'
      }`,
    });
  }, [values?.adSettings?.domainType]);

  const domainUrl = useMemo(() => {
    return values?.adSettings?.customDomain && values?.landingPageUrl
      ? values.adSettings.customDomain + values.landingPageUrl
      : '';
  }, [values?.adSettings?.customDomain, values?.landingPageUrl]);

  return (
    <CardForEdit
      id="url-and-media"
      title={formatMessage({
        id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.URL_AND_MEDIA.TITLE',
      })}
      onEdit={onEdit}
    >
      <Grid.Item xs={4}>
        <BorderContainer
          heading={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.URL_AND_MEDIA.DOMAIN',
          })}
        >
          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.DOMAIN_TYPE.TITLE',
            })}
            value={domainType}
            xs={12}
          />

          <TextItem
            title="URL"
            value={domainUrl}
            isLink
            linkTo={getLinkForRedirect(domainUrl)}
            xs={12}
          />

          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.GTM_CONTAINER_ID',
            })}
            value={values?.adSettings?.gtmContainerId}
            xs={12}
          />

          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.CUSTOM_URL_COOKIES',
            })}
            value={values?.adSettings?.customCookiesUrl}
            xs={12}
          />

          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.GOOGLE_ANALYTICS_UA',
            })}
            value={values?.adSettings?.googleAnalytics360UA}
            xs={12}
          />

          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOMAIN_AND_URL.GOOGLE_ANALYTICS_VIEW_ID',
            })}
            value={values?.adSettings?.googleAnalytics360PropertyViewId}
            xs={12}
          />
        </BorderContainer>
      </Grid.Item>
      <Grid.Item xs={8} style={{flexDirection: 'column', gap: '1.5rem'}}>
        <BorderContainer heading="DTC">
          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DTC.LINK.LABEL',
            })}
            value={values?.adSettings?.dtcRedirectLink}
            xs={12}
            isLink
            linkTo={
              values?.adSettings?.dtcRedirectLink
                ? getLinkForRedirect(values?.adSettings?.dtcRedirectLink)
                : ''
            }
          />

          <TextItem
            title={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.DTC.BANNER',
            })}
            value={
              dtcBanner
                ? dtcBanner.name
                : getFileName(values?.adSettings?.dtcBanner)
            }
            isLink
            linkTo={
              dtcBanner
                ? URL.createObjectURL(dtcBanner)
                : values?.adSettings?.dtcBanner
            }
            xs={12}
          />
        </BorderContainer>
        <BorderContainer
          heading={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.TITLE',
          })}
        >
          <Grid.Container type="fluid" style={{margin: 0, width: '100%'}}>
            <Grid.Item
              xs={6}
              style={{
                borderRight: '1px solid #DBDADA',
                flexDirection: 'column',
                gap: '1.5rem',
                padding: 0,
              }}
            >
              {values?.adSettings?.mediaAccountIds[0].channel === 'default' ? (
                <div>
                  <Heading size="H5" style={{padding: '8px'}}>
                    {formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.MEDIA_ACCOUNT.LABEL',
                    })}
                  </Heading>

                  <TextItem title="" value="" xs={6} />
                </div>
              ) : (
                <Heading size="H5" style={{padding: '8px'}}>
                  {formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.MEDIA_ACCOUNT.LABEL',
                  })}
                </Heading>
              )}

              {values?.adSettings?.mediaAccountIds?.map(
                (account, index) =>
                  account.channel !== 'default' && (
                    <div key={account.channel} style={{display: 'flex'}}>
                      <TextItem
                        title={formatMessage(
                          {
                            id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.MEDIA_ACCOUNT.CHANNEL',
                          },
                          {index: index + 1}
                        )}
                        value={account.channel}
                        xs={6}
                      />
                      <TextItem
                        title={formatMessage(
                          {
                            id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.MEDIA_ACCOUNT.ID',
                          },
                          {index: index + 1}
                        )}
                        value={account.idValue}
                        xs={6}
                      />
                    </div>
                  )
              )}
            </Grid.Item>
            <Grid.Item
              xs={6}
              style={{
                padding: 0,
                paddingLeft: '1.5rem',
                flexDirection: 'column',
                gap: '1.5rem',
              }}
            >
              {values?.adSettings?.socialMediaRedirectLinks[0].service ===
              'default' ? (
                <div>
                  <Heading size="H5" style={{padding: '8px'}}>
                    {formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.SOCIAL_MEDIA.LABEL',
                    })}
                  </Heading>

                  <TextItem title="" value="" xs={6} />
                </div>
              ) : (
                <Heading size="H5" style={{padding: '8px'}}>
                  {formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.SOCIAL_MEDIA.LABEL',
                  })}
                </Heading>
              )}

              {values?.adSettings?.socialMediaRedirectLinks?.map(
                (link, index) =>
                  link.service !== 'default' && (
                    <div key={link.service} style={{display: 'flex'}}>
                      <TextItem
                        title={formatMessage(
                          {
                            id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.SOCIAL_MEDIA.SERVICE',
                          },
                          {index: index + 1}
                        )}
                        value={link.service}
                        xs={6}
                      />
                      <TextItem
                        title={formatMessage(
                          {
                            id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.SOCIAL_MEDIA.LINK',
                          },
                          {index: index + 1}
                        )}
                        value={link.redirectLink}
                        isLink
                        linkTo={getLinkForRedirect(link.redirectLink)}
                        xs={6}
                      />
                    </div>
                  )
              )}
            </Grid.Item>
          </Grid.Container>
        </BorderContainer>
      </Grid.Item>
    </CardForEdit>
  );
};

export default UrlAndMediaSummary;

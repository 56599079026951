import MessageMap from '../../i18n';

const unblockPoc: MessageMap = {
  TITLE: 'Unblock {pocName}?',
  TEXT_WARNING_AUTOMATIC:
    'This POC has also been blocked from the campaigns below by fraud prevention, and have the following BEES Points retained:',
  TEXT_CONFIRMATION_AUTOMATIC:
    'Once unblocked, the POC will be able to use these BEES points and validate coupons again.',
  CAMPAIGNS_POINTS: '- {campaignName}: {amountPoints} points',

  TEXT_WARNING_DISABLED_PARTICIPATION:
    'The campaign participation for this POC has been disabled.',
  TEXT_CONFIRMATION_DISABLED_PARTICIPATION:
    'If unblocked, the POC will be able to participate in the campaign and validate coupons again.',

  TEXT_WARNING_MANUALLY_FRAUD_PREVENTION: `This POC has been blocked from the campaign for fraud prevention. The POC had a total of {amountPoints, plural,
      =1 {1 point}
      other {# points}} BEES points, which were also blocked.`,

  TEXT_CONFIRMATION_MANUALLY_FRAUD_PREVENTION:
    'If unblocked, the POC will be able to use these BEES points and validate coupons again.',

  ACTION: 'Yes, unblock POC',
  SUCCESS_MESSAGE_MANUAL:
    '{pocName} was successfully unblocked from the {campaignName} campaign.',
  SUCCESS_MESSAGE_AUTOMATIC:
    'POC {pocName} successfully unblocked from {countCampaigns} campaigns.',
};

export default unblockPoc;

import {LoadingBee} from '@Components';
import {useFirebase} from '@martech/utils';
import {FirebaseOptions} from 'firebase/app';
import React from 'react';
import firebaseConfig from '../../config/firebase.json';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  OPTIMIZELY_KEY: string;
};

const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  OPTIMIZELY_KEY: '',
};

export const EnvContext = React.createContext(defaultInitialValue);

export const useEnvContext = () => React.useContext(EnvContext);

const firebaseCache = process.env.NODE_ENV === 'production' ? 3600000 : 60000;

export const EnvProvider = ({
  env,
  children,
}: {
  env: EnvConfig;
  children: React.ReactNode;
}) => {
  const isFirebaseReady = useFirebase(
    firebaseConfig as FirebaseOptions,
    firebaseCache,
    15000
  );

  if (!isFirebaseReady) {
    return <LoadingBee />;
  }

  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
};

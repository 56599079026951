import MessageMap from '../../i18n';

const unblockPoc: MessageMap = {
  TITLE: 'Desbloquear {pocName}?',
  TEXT_WARNING_AUTOMATIC:
    'Este PDV também foi bloqueado das campanhas abaixo por prevenção de fraudes e tem os seguintes pontos BEES retidos:',
  TEXT_CONFIRMATION_AUTOMATIC:
    'Uma vez desbloqueado, o PDV poderá usar esses pontos BEES e validar os cupons novamente.',
  CAMPAIGNS_POINTS: '- {campaignName}: {amountPoints} pontos',

  TEXT_WARNING_DISABLED_PARTICIPATION:
    'A participação na campanha para este PDV foi desativada.',
  TEXT_CONFIRMATION_DISABLED_PARTICIPATION:
    'Se for desbloqueado, o PDV poderá participar da campanha e validar os cupons novamente.',

  TEXT_WARNING_MANUALLY_FRAUD_PREVENTION: `Este PDV foi bloqueado da campanha de prevenção a fraudes. O PDV tinha um total de {amountPoints, plural,
      =1 {1 ponto}
      other {# pontos}} BEES, que também foram bloqueados.`,

  TEXT_CONFIRMATION_MANUALLY_FRAUD_PREVENTION:
    'Se for desbloqueado, o PDV poderá usar esses pontos BEES e validar os cupons novamente.',

  ACTION: 'Sim, desbloquear PDV',
  SUCCESS_MESSAGE_MANUAL:
    '{pocName} foi desbloqueado com sucesso na campanha {campaignName}.',
  SUCCESS_MESSAGE_AUTOMATIC:
    'PDV {pocName} desbloqueada com sucesso em {countCampaigns} campanhas.',
};

export default unblockPoc;

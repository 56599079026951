import {FilterStructure} from '@martech/utils';
import {formatDate, getDateMask} from '@Utils';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import * as yup from 'yup';

export default function useBasePromotionListFilter(): [FilterStructure, any] {
  const {formatMessage} = useIntl();

  const schema = useMemo(() => {
    return yup.object({
      startDate: yup
        .date()
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
      endDate: yup
        .date()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .when(['startDate'], {
          is: (startDate: Date | undefined) => Boolean(startDate),
          then: yup
            .date()
            .nullable()
            .transform((v, o) => (o === '' ? null : v))
            .min(yup.ref('startDate'), ({min}) =>
              formatMessage(
                {
                  id: 'PROMOTION_LIST.FILTERS.INVALID_DATE',
                },
                {
                  startDate: formatDate(min),
                }
              )
            ),
        }),
    });
  }, []);

  const structure = useMemo<FilterStructure>(
    () => ({
      promotionDate: {
        label: formatMessage({id: 'PROMOTION_LIST.FILTERS.DATES.TITLE'}),
        options: [
          {
            id: 'startDate',
            label: formatMessage({id: 'PROMOTION_LIST.FILTERS.DATES.START'}),
            placeholder: getDateMask(),
            type: 'date',
          },
          {
            id: 'endDate',
            label: formatMessage({id: 'PROMOTION_LIST.FILTERS.DATES.END'}),
            placeholder: getDateMask(),
            type: 'date',
            endDate: true,
          },
        ],
      },
    }),
    []
  );

  return [structure, schema];
}

import api from '../../api';

export default async function bulkManagePocsService(
  form: FormData,
  campaignId: number,
  isFraud: boolean,
  block: boolean
): Promise<any> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return await api.post({
    url: `/v1/admin/customer/block-unblock-bulk${isFraud ? '-fraud' : ''}/${campaignId}/${block}`,
    body: form,
    config,
  });
}

import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import * as yup from 'yup';
import {processFile} from '@Utils';

export default function CreateStepOneSchema(): [any, any] {
  const {formatMessage} = useIntl();

  const getErrorMessage = (type: string, size?: string) => {
    return formatMessage(
      {
        id: `COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.VALIDATIONS.${type}`,
      },
      {
        size,
      }
    );
  };

  const droppedFilesValidation = async (files: any, field: string) => {
    const dimensions: Record<string, {height: number; width: number}> = {
      mobileBackgroundFile: {
        height: 1920,
        width: 1180,
      },
      desktopBackgroundFile: {
        height: 930,
        width: 1920,
      },
      registrationBannerFile: {
        height: 1280,
        width: 1920,
      },
    };

    const file = files[0].file;
    const objectUrl = URL.createObjectURL(file);

    return processFile(objectUrl)
      .then(imgData => {
        if (file.size > 2097152) {
          files[0] = {
            ...files[0],
            valid: false,
            errors: [getErrorMessage('MAX_SIZE', '2MB')],
          };
          return {valid: false, files};
        }
        if (
          imgData.width !== dimensions[field]?.width ||
          imgData.height !== dimensions[field]?.height
        ) {
          files[0] = {
            ...files[0],
            errors: [getErrorMessage('UPLOAD_FAILED')],
            valid: false,
          };
          return {valid: false, files};
        }

        return {valid: true, files};
      })
      .catch(() => {
        files[0] = {
          ...files[0],
          errors: [getErrorMessage('WRONG_FORMAT')],
          valid: false,
        };
        return {valid: false, files};
      });
  };

  const schema = useMemo(() => {
    const phoneRegex = /^\+\d+$/;
    const whatsappRegex = /^\+\d+$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const websiteRegex =
      /^(https?:\/\/)?([a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)$/i;
    const hexCodeRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

    const requiredMessage = formatMessage({
      id: 'COMMON.FORM.REQUIRED',
    });

    const NUMBER_MIN = formatMessage(
      {id: 'COMMON.ERRORS.NUMBER_MIN'},
      {min: 1}
    );

    const contactSchema = yup.object().shape({
      type: yup.string().required(requiredMessage).nullable(),
      value: yup
        .string()
        .required(requiredMessage)
        .test('validate value based on type', '', function (value) {
          const {path, parent, createError} = this;
          if (!value || !parent.type) {
            return true;
          }

          switch (parent.type) {
            case 'email':
              if (emailRegex.test(value)) {
                return true;
              }

              return createError({
                path,
                message: getErrorMessage('INVALID_EMAIL'),
              });
            case 'phone':
              if (phoneRegex.test(value)) {
                return true;
              }

              return createError({
                path,
                message: getErrorMessage('INVALID_PHONE'),
              });
            case 'whatsapp':
              if (whatsappRegex.test(value)) {
                return true;
              }

              return createError({
                path,
                message: getErrorMessage('INVALID_WHATSAPP'),
              });
            case 'website':
              if (websiteRegex.test(value)) {
                return true;
              }

              return createError({
                path,
                message: getErrorMessage('INVALID_URL'),
              });
            default:
              return true;
          }
        }),
    });

    return yup.object().shape({
      campaignName: yup.string().required(requiredMessage).min(1).max(30),
      imageFile: yup.mixed().required(),
      campaignDescription: yup.string().optional().max(150).nullable(),
      campaignImgAlt: yup.string().optional().max(150).nullable(),
      template: yup.object().shape({
        ageGate: yup.object().shape({
          enabled: yup.boolean().optional(),
          type: yup.string().when('enabled', {
            is: (value: boolean) => value === true,
            then: yup.string().required(requiredMessage),
            otherwise: yup.string().nullable(),
          }),
          mobileBackgroundFile: yup.mixed().when('enabled', {
            is: (value: boolean) => value === true,
            then: yup.mixed().required(requiredMessage),
            otherwise: yup.mixed().nullable(),
          }),
          desktopBackgroundFile: yup.mixed().when('enabled', {
            is: (value: boolean) => value === true,
            then: yup.mixed().required(requiredMessage),
            otherwise: yup.mixed().nullable(),
          }),
        }),
        logoFile: yup.mixed().required(requiredMessage),
        headerColor: yup
          .string()
          .required(requiredMessage)
          .matches(hexCodeRegex, getErrorMessage('INVALID_COLOR')),
        contacts: yup.array().of(contactSchema),
        registrationBannerFile: yup.mixed().required(),
        govIdentifier: yup.boolean().optional(),
        pocPinType: yup.string().required(requiredMessage),
        customPinFile: yup.mixed().when('pocPinType', {
          is: (value: string) => value === 'CUSTOM',
          then: yup.mixed().required(requiredMessage),
          otherwise: yup.mixed().nullable(),
        }),
        ageGateColor: yup
          .string()
          .required(requiredMessage)
          .matches(hexCodeRegex, getErrorMessage('INVALID_COLOR')),
        linksColor: yup
          .string()
          .required(requiredMessage)
          .matches(hexCodeRegex, getErrorMessage('INVALID_COLOR')),
      }),
      campaignHost: yup.string().required(requiredMessage),
      startDateCampaign: yup
        .date()
        .required(requiredMessage)
        .transform((v, o) => (o === '' ? null : v)),
      endDateCampaign: yup
        .date()
        .required(requiredMessage)
        .transform((v, o) => (o === '' ? null : v))
        .when(['startDateCampaign'], {
          is: (onboardingStart: Date | undefined) => Boolean(onboardingStart),
          then: yup
            .date()
            .required(requiredMessage)
            .transform((v, o) => (o === '' ? null : v))
            .min(yup.ref('startDateCampaign'), () =>
              formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.INVALID_DATE',
              })
            ),
        }),
      globalB2OTag: yup.string().required(requiredMessage),
      initiative: yup.string().required(requiredMessage),
      country: yup.string().required(requiredMessage),
      year: yup.string().required(requiredMessage),
      uniqueName: yup
        .string()
        .required(requiredMessage)
        .test(
          'specialFilter',
          formatMessage({id: 'COMMON.ERRORS.SPECIAL_INVALID'}),
          value => /^[a-z0-9-]+$/i.test(value ?? '')
        ),
      launch: yup.string().required(requiredMessage),
      couponOnSms: yup.boolean().optional(),
      dollarQuote: yup
        .number()
        .required(requiredMessage)
        .min(
          0.01,
          formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.INVALID_DOLLAR_QUOTE',
          })
        ),
      maximumCouponsValidationAttemptsByPeriod: yup
        .number()
        .required(requiredMessage)
        .min(1, NUMBER_MIN),
      periodForCouponValidation: yup.string().required(requiredMessage),
      hasLimitPerConsumer: yup.boolean().optional(),
      consumerRedemptionCouponLimit: yup.number().when('hasLimitPerConsumer', {
        is: (value: boolean) => value === true,
        then: yup.number().required(requiredMessage),
        otherwise: yup.number().nullable(),
      }),
    });
  }, []);

  return [schema, droppedFilesValidation];
}

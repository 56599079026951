import {POC, ListRequestProps, Paginated} from '@Models';
import {getEligiblePocsService} from '@Services';

async function getEligiblePocsUseCase(
  campaignId: number,
  pageNumber: number,
  props: ListRequestProps = {}
): Promise<Paginated<POC>> {
  const response = await getEligiblePocsService(campaignId, pageNumber, props);

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default getEligiblePocsUseCase;

import {useAuthenticationService} from 'admin-portal-shared-services';
import Axios from 'axios';

export interface IRequest {
  url: string;
  body?: any;
  config?: any;
  isPromotionActive?: boolean;
  campaignId?: number;
  block?: boolean;
  params?: any;
}

const authentication = useAuthenticationService();
const baseURL = '/api/bees-coupons-bff-service';
const {user_country: userCountry} = authentication.getUserCountryAndLanguage();

const initialAxios = Axios.create({
  baseURL,
});

authentication.enhancedAxios(initialAxios, {
  headers: [{country: userCountry || 'BR'}],
});

const api = {
  post: ({url, body, config}: IRequest): Promise<any> =>
    initialAxios.post(url, body, config).catch(error => ({
      hasError: true,
      ...error,
    })),

  delete: ({url}: IRequest): Promise<any> =>
    initialAxios.delete(url).catch(error => ({hasError: true, ...error})),

  get: ({url, config}: IRequest): Promise<any> =>
    initialAxios.get(url, config).catch(error => ({hasError: true, ...error})),

  put: ({url, body}: IRequest): Promise<any> =>
    initialAxios.put(url, body).catch(error => ({
      hasError: true,
      ...error,
    })),

  patch: ({url, body}: IRequest): Promise<any> =>
    initialAxios.patch(url, body).catch(error => ({
      hasError: true,
      ...error,
    })),
};

export default api;

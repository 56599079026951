import {FilterStructure} from '@martech/utils';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';

export default function useBaseEligiblePocsFilter(): FilterStructure {
  const {formatMessage} = useIntl();

  const structure = useMemo<FilterStructure>(
    () => ({
      status: {
        label: formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.FILTER'}),
        id: 'status',
        array: true,
        options: [
          {
            id: 'participating',
            label: formatMessage({
              id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.PARTICIPATING',
            }),
            type: 'checkbox',
          },
          {
            id: 'not-participating',
            label: formatMessage({
              id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.NOT_PARTICIPATING',
            }),
            type: 'checkbox',
          },
          {
            id: 'blocked-manual',
            label: formatMessage({
              id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.BLOCKED_MANUAL_DISABLED',
            }),
            type: 'checkbox',
          },
          {
            id: 'blocked-manually-fraud-prevention',
            label: formatMessage({
              id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.BLOCKED_MANUALLY_FRAUD_PREVENTION',
            }),
            type: 'checkbox',
          },
          {
            id: 'blocked-automatic',
            label: formatMessage({
              id: 'CAMPAIGN_ELIGIBLE_POCS.LIST.STATUSES.BLOCKED_AUTOMATIC',
            }),
            type: 'checkbox',
          },
        ],
      },
    }),
    []
  );

  return structure;
}

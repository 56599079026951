export default function getFileFromValueArray(
  value: any[] | undefined
): File | undefined {
  if (!value) {
    return undefined;
  }

  if (value.length === 0) {
    return undefined;
  }

  return value[0].file;
}

import MessageMap from '../../i18n';

const promotionDetails: MessageMap = {
  DETAILS_TITLE: 'Detalhes de {title}',
  DETAILS_BREADCRUMB: 'Detalhes da promoção',
  DETAILS_ADD_BUTTON: 'Adicionar PDVs elegíveis',
  DETAILS_SEARCH: 'Pesquisar por código ou nome do PDV',
  STATUSES: {
    POC_PARTICIPATING: 'Participando',
    POC_NOT_PARTICIPATING: 'Não participando',
    POC_BLOCKED_MANUAL: 'Bloqueado - Manualmente',
    POC_BLOCKED_MANUAL_DISABLED:
      'Bloqueado manualmente - Desativada na campanha',
    POC_BLOCKED_MANUALLY_FRAUD_PREVENTION:
      'Bloqueado manualmente - Prevenção de fraude',
    POC_BLOCKED_AUTOMATIC: 'Bloqueado - Prevenção de fraude',
    POC_DISABLED_ON_PROMOTION: 'Desativado manualmente na promoção',
  },
  STATUSES_TOOLTIP_INFO: {
    POC_BLOCKED_MANUAL: 'Participação desativada.',
    POC_BLOCKED_MANUALLY_FRAUD_PREVENTION:
      'Bloqueado para prevenção a fraudes.',
  },
  TABLE: {
    DETAILS_POC_ID: 'Código do PDV',
    DETAILS_POC_NAME: 'Nome do PDV ',
    DETAILS_STATUS: 'Status',
    DETAILS_ADDED_ON: 'Adicionado em',
    DETAILS_BLOCKED_ON: 'Bloqueado em',
    DETAILS_ACTION: 'Ação',
    DETAILS_DISABLE: 'Desabilitar',
    DETAILS_ENABLE: 'Habilitar',
  },
  NO_DATA: {
    DETAILS_TITLE: 'Esta promoção ainda não tem PDVs elegíveis.',
    DETAILS_DESCRIPTION: 'Adicione-os clicando em “Adicionar PDVs elegíveis”.',
  },
};

export default promotionDetails;

import MessageMap from '../../i18n';

const enablePoc: MessageMap = {
  TITLE: 'Habilitar {pocName}?',

  ENABLE_PARTICIPATION_DESCRIPTION:
    'Si se habilita, el PDV podrá participar en la promoción y validar cupones nuevamente.',
  ACTION: 'Sí, habilita el PDV',
  SUCCESS_MESSAGE:
    '{pocName} se habilitó correctamente para la promoción {promotionName}.',
};

export default enablePoc;

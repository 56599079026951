import {InputProps, InputWrapper} from '@hexa-ui/components';
import {useField} from 'formik';
import ReactQuill from 'react-quill';

export default function CustomEditor(props: InputProps) {
  const [, meta, helper] = useField(props as any);

  return (
    <InputWrapper
      width="100%"
      hasError={Boolean(meta.touched && meta.error)}
      errorText={meta.error}
      label={props.label}
      hint={props.hint}
      data-testid="custom-editor"
      disabled={props.disabled}
    >
      <ReactQuill
        theme="snow"
        value={meta.value}
        onChange={e => {
          helper.setValue(e);
          helper.setTouched(true);
        }}
        onBlur={() => helper.setTouched(true)}
        readOnly={props.disabled}
      />
    </InputWrapper>
  );
}

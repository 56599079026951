import MessageMap from '../../i18n';

const promotionDetails: MessageMap = {
  DETAILS_TITLE: 'Details for {title}',
  DETAILS_BREADCRUMB: 'Promotion details',
  DETAILS_ADD_BUTTON: 'Add eligible POCs',
  DETAILS_SEARCH: 'Search by POC ID or name',
  STATUSES: {
    POC_PARTICIPATING: 'Participating',
    POC_NOT_PARTICIPATING: 'Not participating',
    POC_BLOCKED_MANUAL: 'Blocked - Manually',
    POC_BLOCKED_MANUAL_DISABLED: 'Blocked manually - Disabled in campaign',
    POC_BLOCKED_MANUALLY_FRAUD_PREVENTION:
      'Blocked manually - Fraud prevention',
    POC_BLOCKED_AUTOMATIC: 'Blocked - Fraud prevention',
    POC_DISABLED_ON_PROMOTION: 'Disabled manually in promotion',
  },
  STATUSES_TOOLTIP_INFO: {
    POC_BLOCKED_MANUAL: 'Participation disabled.',
    POC_BLOCKED_MANUALLY_FRAUD_PREVENTION: 'Blocked for fraud prevention.',
  },
  TABLE: {
    DETAILS_POC_ID: 'POC ID',
    DETAILS_POC_NAME: 'POC name',
    DETAILS_STATUS: 'Status',
    DETAILS_ADDED_ON: 'Added on',
    DETAILS_BLOCKED_ON: 'Blocked on',
    DETAILS_ACTION: 'Action',
    DETAILS_DISABLE: 'Disable',
    DETAILS_ENABLE: 'Enable',
  },
  NO_DATA: {
    DETAILS_TITLE: "This promotion doesn't have any eligible POCs yet",
    DETAILS_DESCRIPTION: 'Add them by clicking on “Add eligible POCs”.',
  },
};

export default promotionDetails;

import MessageMap from '../../i18n';

const campaignEligiblePocs: MessageMap = {
  ELIGIBLE_POCS: 'Eligible POCs',
  LIST: {
    SUMMARY: 'Summary',
    ELIGIBLE_POCS_FOR: 'Eligible POCs for {title}',
    ELIGIBLE_POCS: 'Eligible POCs',
    PARTICIPATING: 'Participating POCs',
    BLOCKED: 'Blocked POCs',
    ADD_BUTTON: 'Add eligible POCs',
    SEARCH: 'Search by POC ID or name',
    POC_ID: 'POC ID',
    POC_NAME: 'POC name',
    STATUS: 'Status',
    ADDED_ON: 'Added on',
    BLOCKED_ON: 'Blocked on',
    ACTION: 'Action',
    BULK_MANAGE_BUTTON: 'Bulk Manage POCs',

    NO_DATA: {
      TITLE: "This campaign doesn't have any eligible POCs yet",

      DESCRIPTION: 'Add them by clicking on “Add eligible POCs”.',
    },
    FILTER: 'Status',
    STATUSES: {
      PARTICIPATING: 'Participating',
      NOT_PARTICIPATING: 'Not participating',
      BLOCKED_MANUAL: 'Blocked - Manually',
      BLOCKED_MANUAL_DISABLED: 'Blocked manually - Disabled in campaign',
      BLOCKED_MANUALLY_FRAUD_PREVENTION: 'Blocked manually - Fraud prevention',
      BLOCKED_AUTOMATIC: 'Blocked - Fraud prevention',
      DISABLED_ON_PROMOTION: 'Disabled manually in promotion',
    },
    STATUSES_TOOLTIP_INFO: {
      BLOCKED_MANUAL: 'Participation disabled.',
      BLOCKED_MANUALLY_FRAUD_PREVENTION: 'Blocked for fraud prevention.',
    },
    ACTIONS: {
      BLOCK: 'Block POC',
      UNBLOCK: 'Unblock POC',
    },
  },
  ADD: {
    TITLE: 'Add eligible POCs - {title}',
    BREADCRUMB: 'Add eligible POCs',
    FILE_UPLOAD: 'File upload',
    FILE_DESCRIPTION_ONE:
      'The added POCs will be enabled to participate in the {title} campaign.',
    FILE_DESCRIPTION_TWO:
      'The file must contain only POC IDs, separated by line breaks.',
    FILE_DESCRIPTION_THREE:
      'Follow the instructions inside the template to successfully add a list.',
    FILE_DESCRIPTION_PROMOTION:
      'The added POCs will be enabled to participate in the {title} promotion.',
    DOWNLOAD_TEMPLATE: 'Download spreadsheet template',
    DROP_PLACEHOLDER:
      'The file must be in .csv format, and less than 2MB in size.',
    UPLOAD_INFO:
      'This file will add new IDs to the current list of POCs without overriding it.',
    INVALID_ERROR:
      'Wrong data format. Download the template and follow its instructions.',
    TYPE_ERROR: 'File type not supported.',
    BROWSE_FILE: 'Select file',
    UPLOAD_ANALYZE_TITLE: 'Analysis of uploaded file',
    PROCESSED: 'Processed {processed} out of {total}',
    STARTING: 'Starting',
    POCS_READY: 'POCs ready to be added',
    POCS_ERROR: 'POCs with inconsistent information',
    UPLOADED_ALERT:
      'To review error notes, export the list of POCs with inconsistent information. Then you can either upload a fixed new file in the previous step or proceed with only the POCs ready to be added.',
    EXPORT_ERROR_POCS: 'Export inconsistent POCs',
    CANCEL: 'Cancel',
    ADD_POCS: 'Add POCs',
    SUCCESS_MESSAGE:
      '{value} POCs were successfully added to the {campaignName} campaign.',
    SUCCESS_MESSAGE_PROMOTION:
      '{value} POCs were successfully added to the {promotionName} promotion.',
  },
  BULK_MANAGE: {
    TITLE: 'Bulk Manage POCs - {title}',
    BREADCRUMB: 'Bulk Manage POCs',
    SELECT_BULK_ACTION: 'Select a bulk action',
    MANAGE_POCS_BUTTON: 'Manage POCs',
    BLOCK_FRAUD_PREVENTION_ACTION: 'Block - Fraud prevention',
    UNBLOCK_FRAUD_PREVENTION_ACTION: 'Unblock - Fraud prevention',
    DISABLE_PARTICIPATION_ACTION: 'Disable participation',
    ENABLE_PARTICIPATION_ACTION: 'Enable participation',
    BLOCK_FRAUD_PREVENTION_DESCRIPTION:
      'This option should be used in case of suspicious activities. POC will not be able to redeem coupons in any campaign.',
    UNBLOCK_FRAUD_PREVENTION_DESCRIPTION:
      'Once unblocked, POCs will be able to use these BEES points and validate coupons again.',
    UNBLOCK_FRAUD_PREVENTION_DESCRIPTION_TWO:
      'This action will unblock POCS that were blocked by fraud prevention automatically, releasing the points in all the campaigns.',
    DISABLE_PARTICIPATION_DESCRIPTION:
      'Disabled the POC to participate in the campaign and validate coupons again.',
    ENABLE_PARTICIPATION_DESCRIPTION:
      'Once enabled, POCs will participate in the campaign and validate coupons again.',
    POC_UPLOAD_LIMIT: 'Limit of {value}K POCs at a time',
    SUCCESS_MESSAGE_ENABLE_PARTICIPATION:
      '{value} POCs were successfully enabled for campaign {campaignName}.',
    SUCCESS_MESSAGE_DISABLE_PARTICIPATION:
      '{value} POCs were successfully disabled for campaign {campaignName}.',
    SUCCESS_MESSAGE_BLOCK_FRAUD_PREVENTION:
      '{value} POCs were successfully blocked for fraud prevention in campaign {campaignName}.',
    SUCCESS_MESSAGE_UNBLOCK_FRAUD_PREVENTION:
      '{value} POCs were successfully unblocked for fraud prevention in campaign {campaignName}.',
    ERROR_MESSAGE_ENABLE_PARTICIPATION:
      '{value} POCs were not enabled for campaign {campaignName}.',
    ERROR_MESSAGE_DISABLE_PARTICIPATION:
      '{value} POCs were not disabled for campaign {campaignName}.',
    ERROR_MESSAGE_BLOCK_FRAUD_PREVENTION:
      '{value} POCs were not blocked for fraud prevention in campaign {campaignName}.',
    ERROR_MESSAGE_UNBLOCK_FRAUD_PREVENTION:
      '{value} POCs were not unblocked for fraud prevention in campaign {campaignName}.',
  },
};

export default campaignEligiblePocs;

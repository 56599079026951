import {isMediaAnImage} from '@Utils';

export default function Thumbnail({url}: {url: string}) {
  return isMediaAnImage(url) ? (
    <img
      data-testid="thumbnail-image"
      style={{borderRadius: 8, objectFit: 'contain'}}
      width={60}
      height={60}
      src={url}
    />
  ) : (
    <video
      data-testid="thumbnail-video"
      width={60}
      height={60}
      style={{borderRadius: 8, objectFit: 'contain'}}
      src={url}
      autoPlay
      loop
      playsInline
      muted
    />
  );
}

import {Campaign, ListRequestProps, Paginated} from '@Models';
import {getCampaignsService} from '@Services';

async function getCampaignsUseCase(
  pageNumber: number,
  props: ListRequestProps = {}
): Promise<Paginated<Campaign>> {
  const response = await getCampaignsService(pageNumber, props);

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default getCampaignsUseCase;

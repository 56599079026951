import React, {memo, useCallback, useMemo} from 'react';
import {
  Card,
  Divider,
  Grid,
  Heading,
  IconButton,
  Select,
  TextButton,
} from '@hexa-ui/components';
import {useIntl} from 'react-intl';
import {FieldArray, useFormikContext} from 'formik';
import CustomInput from '../../../Form/CustomInput/CustomInput';
import {Plus, Trash2} from '@hexa-ui/icons';
import CustomSelectInput from '../../../Form/CustomSelectInput/CustomSelectInput';

interface MediaAccount {
  channel: string;
  idValue: string;
  position: number;
}

interface SocialMediaLinks {
  service: string;
  redirectLink: string;
  position: number;
}

const MediaCampaigns: React.FC = () => {
  const {values} = useFormikContext<any>();
  const {formatMessage} = useIntl();

  const ChannelOptions = useMemo(() => {
    const options = [
      'default',
      'adsmovil',
      'email_marketing',
      'facebook',
      'google_ads',
      'push_notification',
      'sms',
      'twitter',
      'verizon',
      'youtube',
    ];

    return options.map(option => ({
      label: formatMessage({
        id: `COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.MEDIA_ACCOUNT.TYPES.${option.toUpperCase()}`,
      }),
      value: option,
    }));
  }, []);

  const ServiceOptions = useMemo(() => {
    const options = [
      'default',
      'facebook',
      'instagram',
      'tiktok',
      'twitter',
      'youtube',
    ];

    return options.map(option => ({
      label: formatMessage({
        id: `COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.SOCIAL_MEDIA.TYPES.${option.toUpperCase()}`,
      }),
      value: option,
    }));
  }, []);

  const getSelectedOptions = (
    options: any[],
    valueIndex: number,
    returnField: string
  ) => {
    return options
      .filter((_: any, index: number) => index !== valueIndex)
      .map((item: any) => item[returnField]);
  };

  const filterOptions = (options: any[], selected: any[]) => {
    return options.filter(
      option => !selected.includes(option.value) || option.value === 'select'
    );
  };

  const getFilteredChannelOptions = useCallback(
    (valueIndex: number) => {
      if (values.adSettings?.mediaAccountIds?.length > 0) {
        const selectedChannels = getSelectedOptions(
          values.adSettings?.mediaAccountIds,
          valueIndex,
          'channel'
        );

        return filterOptions(ChannelOptions, selectedChannels);
      }

      return ChannelOptions;
    },
    [ChannelOptions, values.adSettings?.mediaAccountIds]
  );

  const getFilteredServiceOptionsOptions = useCallback(
    (valueIndex: number) => {
      if (values.adSettings?.socialMediaRedirectLinks?.length > 0) {
        const selectedServices = getSelectedOptions(
          values.adSettings?.socialMediaRedirectLinks,
          valueIndex,
          'service'
        );
        return filterOptions(ServiceOptions, selectedServices);
      }

      return ServiceOptions;
    },
    [ServiceOptions, values.adSettings?.socialMediaRedirectLinks]
  );

  return (
    <Card
      border="medium"
      elevated="small"
      style={{width: '100%', padding: '2rem'}}
      data-testid="media-campaigns"
    >
      <Grid.Container type="fluid" style={{margin: 0, gap: '1rem'}}>
        <Grid.Item xs={12}>
          <Heading size="H3">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.TITLE',
            })}
          </Heading>
        </Grid.Item>

        <Grid.Item xs={12}>
          <Heading size="H4">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.MEDIA_ACCOUNT.LABEL',
            })}
          </Heading>
        </Grid.Item>

        <Grid.Container
          type="fluid"
          style={{
            margin: 0,
            width: '100%',
          }}
        >
          <FieldArray name="adSettings.mediaAccountIds">
            {({push, remove}) => (
              <>
                {values.adSettings?.mediaAccountIds?.map(
                  (_: MediaAccount, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'end',
                        width: '100%',
                        marginTop: index !== 0 ? '1rem' : 0,
                      }}
                    >
                      <Grid.Item xs={3}>
                        <CustomSelectInput
                          data-testid={`media-account-channel-${index}`}
                          label={formatMessage(
                            {
                              id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.MEDIA_ACCOUNT.CHANNEL',
                            },
                            {index: index + 1}
                          )}
                          name={`adSettings.mediaAccountIds[${index}].channel`}
                          multiple={false}
                          defaultValue={'select'}
                          value={'select'}
                        >
                          {getFilteredChannelOptions(index).map(item => (
                            <Select.Option key={item.value} value={item.value}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </CustomSelectInput>
                      </Grid.Item>
                      <Grid.Item xs={4}>
                        <CustomInput
                          data-testid={`media-account-id-${index}`}
                          name={`adSettings.mediaAccountIds[${index}].idValue`}
                          label={formatMessage(
                            {
                              id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.MEDIA_ACCOUNT.ID',
                            },
                            {index: index + 1}
                          )}
                          optionalText={formatMessage({
                            id: 'COMMON.FORM.OPTIONAL',
                          })}
                          required={false}
                        />
                      </Grid.Item>
                      {index !== 0 && (
                        <Grid.Item
                          xs={1}
                          style={{alignSelf: 'center', marginTop: '1rem'}}
                        >
                          <IconButton
                            data-testid={'remove-media-account'}
                            icon={Trash2}
                            variant="inherit"
                            type="button"
                            onClick={() => remove(index)}
                          />
                        </Grid.Item>
                      )}
                    </div>
                  )
                )}
                <Grid.Item xs={12} style={{marginTop: '1.5rem'}}>
                  <TextButton
                    data-testid="add-media-account"
                    icon={Plus}
                    iconPosition="leading"
                    size="small"
                    onClick={() =>
                      push({
                        channel: 'select',
                        idValue: '',
                        position: values.adSettings?.mediaAccountIds.length,
                      })
                    }
                    type="button"
                  >
                    {formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.MEDIA_ACCOUNT.ADD',
                    })}
                  </TextButton>
                </Grid.Item>
              </>
            )}
          </FieldArray>
        </Grid.Container>

        <Divider css={{margin: '1rem 0'}} />

        <Grid.Item xs={12}>
          <Heading size="H4">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.SOCIAL_MEDIA.LABEL',
            })}
          </Heading>
        </Grid.Item>

        <Grid.Container
          type="fluid"
          style={{
            margin: 0,
            width: '100%',
          }}
        >
          <FieldArray name="adSettings.socialMediaRedirectLinks">
            {({push, remove}) => (
              <>
                {values.adSettings?.socialMediaRedirectLinks?.map(
                  (_: SocialMediaLinks, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'end',
                        width: '100%',
                        marginTop: index !== 0 ? '1rem' : 0,
                      }}
                    >
                      <Grid.Item xs={3}>
                        <CustomSelectInput
                          data-testid={`social-media-service-${index}`}
                          label={formatMessage(
                            {
                              id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.SOCIAL_MEDIA.SERVICE',
                            },
                            {index: index + 1}
                          )}
                          name={`adSettings.socialMediaRedirectLinks[${index}].service`}
                          multiple={false}
                          defaultValue={'select'}
                          value={'select'}
                        >
                          {getFilteredServiceOptionsOptions(index).map(item => (
                            <Select.Option key={item.value} value={item.value}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </CustomSelectInput>
                      </Grid.Item>
                      <Grid.Item xs={4}>
                        <CustomInput
                          data-testid={`social-media-link-${index}`}
                          name={`adSettings.socialMediaRedirectLinks[${index}].redirectLink`}
                          label={formatMessage(
                            {
                              id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.SOCIAL_MEDIA.LINK',
                            },
                            {index: index + 1}
                          )}
                          optionalText={formatMessage({
                            id: 'COMMON.FORM.OPTIONAL',
                          })}
                          required={false}
                        />
                      </Grid.Item>
                      {index !== 0 && (
                        <Grid.Item
                          xs={1}
                          style={{alignSelf: 'center', marginTop: '1rem'}}
                        >
                          <IconButton
                            data-testid={'remove-social-media'}
                            icon={Trash2}
                            variant="inherit"
                            type="button"
                            onClick={() => remove(index)}
                          />
                        </Grid.Item>
                      )}
                    </div>
                  )
                )}
                <Grid.Item xs={12} style={{marginTop: '1.5rem'}}>
                  <TextButton
                    data-testid="add-social-media"
                    icon={Plus}
                    iconPosition="leading"
                    size="small"
                    onClick={() =>
                      push({
                        service: 'select',
                        redirectLink: '',
                        position:
                          values.adSettings?.socialMediaRedirectLinks.length,
                      })
                    }
                    type="button"
                  >
                    {formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.MEDIA_CAMPAIGNS.SOCIAL_MEDIA.ADD',
                    })}
                  </TextButton>
                </Grid.Item>
              </>
            )}
          </FieldArray>
        </Grid.Container>
      </Grid.Container>
    </Card>
  );
};

export default memo(MediaCampaigns);

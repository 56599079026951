import api from '../../api';

export default async function enableDisablePocService(
  pocCode: string,
  campaignId: number,
  blocked: boolean,
  promotionId: number
): Promise<any> {
  return await api.patch({
    url: `/v1/admin/promotions/poc`,
    body: {campaignId: campaignId, blocked: blocked, promotionId: promotionId, pocCode: pocCode},
  });
}

import {Grid, Heading, Paragraph} from '@hexa-ui/components';
import React from 'react';
import {BeeAlert, MapPreview} from '@Assets';
import {useIntl} from 'react-intl';
import {styled} from '@stitches/react';

interface SectionCardProps {
  icon: any;
  title: string;
  withPreview?: boolean;
  previewType?: 'image' | 'map' | 'button';
  preview?: string;
  color?: string;
  secondaryColor?: string;
}

const StyledPreview = styled('div', {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  borderRadius: '12px',
  variants: {
    type: {
      map: {
        backgroundImage: `url(${MapPreview})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      button: {
        border: '1px solid #DBDADA',
      },
    },
  },
});

const SectionCard: React.FC<SectionCardProps> = ({
  icon,
  title,
  withPreview,
  previewType = 'image',
  preview,
  color,
  secondaryColor,
  children,
}) => {
  const {formatMessage} = useIntl();

  return (
    <Grid.Container
      type="fluid"
      style={{
        margin: 0,
        width: '100%',
        border: '1px solid #DBDADA',
        borderRadius: '16px',
        overflow: 'hidden',
      }}
    >
      <Grid.Item
        xs={2}
        style={{background: '#F2F2F2', padding: '1.5rem', gap: '1rem'}}
      >
        <img data-testid="section-icon" src={icon} alt="" height={51} />
        <Heading size="H5">{title}</Heading>
      </Grid.Item>
      <Grid.Item xs={withPreview ? 7 : 10} style={{padding: '2rem'}}>
        {withPreview ? (
          <Grid.Container
            type="fluid"
            style={{
              margin: 0,
              width: '100%',
              borderRight: '1px solid #DBDADA',
              paddingRight: '2rem',
            }}
          >
            {children}
          </Grid.Container>
        ) : (
          children
        )}
      </Grid.Item>

      {withPreview && (
        <Grid.Item
          xs={3}
          style={{padding: '2rem', flexDirection: 'column', gap: '0.5rem'}}
        >
          <Paragraph size="small" weight="semibold">
            {formatMessage({id: 'COUPON_CAMPAIGNS.CREATE.PREVIEW'})}
          </Paragraph>
          {previewType === 'image' && (
            <StyledPreview
              style={{
                border: preview && color ? 'none' : '1px solid #DBDADA',
                background: color ? color : '#fff',
              }}
            >
              {preview ? (
                <img
                  data-testid="preview"
                  src={preview}
                  alt="image"
                  width="100%"
                />
              ) : (
                <img src={BeeAlert} alt="empty" width={131} />
              )}
            </StyledPreview>
          )}

          {previewType === 'map' && (
            <StyledPreview type="map">
              {preview && <img src={preview} alt="image" width="50px" />}
            </StyledPreview>
          )}

          {previewType === 'button' && (
            <StyledPreview type="button">
              <div
                style={{
                  border: '2px solid #DBDADA',
                  borderRadius: '8px',
                  padding: '14px 34px',
                  color: color ?? '#000',
                  background: secondaryColor ?? '#FFF',
                }}
              >
                {formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.BUTTONS.BUTTON',
                })}
              </div>
            </StyledPreview>
          )}
        </Grid.Item>
      )}
    </Grid.Container>
  );
};

export default SectionCard;

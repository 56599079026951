import {ListRequestProps, Paginated, Promotion} from '@Models';
import {getPromotionListService} from '@Services';

async function getPromotionListUseCase(
  campaignId: number,
  pageNumber: number,
  pageSize: number,
  props: ListRequestProps = {}
): Promise<Paginated<Promotion>> {
  const response = await getPromotionListService(
    campaignId,
    pageNumber,
    pageSize,
    props
  );

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default getPromotionListUseCase;

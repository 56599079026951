import React from 'react';
import {useField} from 'formik';
import {Checkbox, CheckboxProps} from '@hexa-ui/components';

const CustomCheckbox: React.FC<CheckboxProps> = props => {
  const [field, meta, helper] = useField(props as any);

  return (
    <Checkbox
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
      onCheckedChange={(checked: boolean) => {
        helper.setValue(checked);
      }}
    />
  );
};

export default CustomCheckbox;

import MessageMap from '../../i18n';

const CouponCampaigns: MessageMap = {
  TITLE: 'Campañas de cupones',
  CREATE_COUPON_CAMPAIGN_BUTTON: 'Crear campaña de cupones',
  SEARCH_PLACEHOLDER: 'Buscar por nombre de la campaña',
  NO_DATA: {
    TITLE: 'No tenés campañas de cupones',
    DESCRIPTION:
      'Agregar una ahora, haciendo click en "Crear campaña de cupones".',
  },
  FILTERS: {
    STATUS: 'Estado de la campaña',
    DATES: {
      TITLE: 'Fechas de la campaña',
      START: 'Fecha de inicio',
      END: 'Fecha de finalización',
    },
    INVALID_DATE: 'Elija una fecha posterior al {startDate}.',
  },
  TABLE: {
    CAMPAIGN_NAME: 'Nombre de campaña',
    STATUS: 'Estado',
    ACTIVE_PROMOTIONS: 'Promociones activas',
    ACTIVE_POCS: 'PDVs participantes',
    START_DATE: 'Fecha de inicio',
    END_DATE: 'Fecha de finalización',
    ACTIONS: 'Acciones',
    TOOLTIPS: {
      ELIGIBLE_POCS:
        'Los PDVs elegibles que se unieron a la campaña de cupones.',
      MANAGE_ELIGIBLE_POCS: 'Administrar los PDVs elegibles',
      EDIT: 'Editar',
    },
    STATUSES: {
      ACTIVE: 'Activa',
      SCHEDULED: 'Programada',
      FINISHED: 'Terminada',
    },
    DROPDOWN: {
      POC_MANAGEMENT: 'Gestión de PDV',
      EDIT_CAMPAIGN: 'Editar Campaña',
      PROMOTIONS: 'Promociones',
    },
  },

  CREATE: {
    TITLE: 'Crear campaña de cupones',
    DOWNLOAD_TEMPLATE: 'Descargar plantilla',
    PREVIEW: 'Vista previa',
    BUTTON: 'Crear campaña',
    SUCCESS_MESSAGE: 'La campaña {campaignName} se creó correctamente.',
    UPDATED_MESSAGE: 'La campaña {campaignName} se actualizó correctamente.',
    STEPS: {
      CAMPAIGN: {
        TITLE: 'Campaña',
        DESCRIPTION: 'Define los detalles de la campaña.',
      },
      PROMOTIONS: {
        TITLE: 'Promociones',
        DESCRIPTION: 'Define la configuración del cupón.',
      },
      LEGAL_INFO: {
        TITLE: 'Información legal',
        DESCRIPTION: 'Proporciona información sobre términos y políticas.',
      },
      URL_AND_ADS: {
        TITLE: 'URL y anuncios',
        DESCRIPTION: 'Define la configuración del dominio y del anuncio.',
      },
      SUMMARY: {
        TITLE: 'Resumen',
        DESCRIPTION: 'Revisa y publica.',
      },
    },
    DISCARD_MODAL: {
      TITLE: '¿Descartar campaña?',
      DESCRIPTION: 'Esta campaña se descartará de forma permanente.',
      DISCARD: 'Descartar',
    },
    CUSTOMER_SETTINGS: {
      TITLE: 'Configuración del cliente',
      NAME: {
        LABEL: 'Nombre de campaña',
        HINT: 'Este nombre también se muestra al consumidor.',
      },
      IMAGE: {
        LABEL: 'Imagen de campaña',
        PLACEHOLDER:
          'Formato de archivo: JPG o PNG. Resolución: 1920 x 1080. Tamaño: Hasta 2 MB.',
        BUTTON: 'Seleccionar archivo',
        TYPE_ERROR: 'Tipo de archivo no soportado.',
        INVALID_ERROR: 'Carga fallida.',
      },
      DOWNLOAD_TEMPLATE: 'Descargar plantilla',
      CAMPAIGN_PREVIEW: {
        TITLE: 'Vista previa de la tarjeta de campaña',
        NAME: 'Nombre de campaña',
        PROMOTIONS: 'Promociones',
        PROMO: 'promociones',
        POINTS: 'Puntos BEES',
      },
      ALT_TEXT: {LABEL: 'Texto ALT de la imagen'},
      DESCRIPTION: {
        LABEL: 'Descripción de la campaña',
      },
    },
    CONSUMER_SETTINGS: {
      TITLE: 'Configuración del consumidor',
      AGE_GATE: {
        TITLE: 'Verificación de edad',
        CHECKBOX: 'Mostrar verificación de edad',
        TYPE: {
          TITLE: 'Tipo',
          FIRST: 'Sí o No',
          SECOND: 'DD/MM/YYYY',
        },
        MOBILE_BG: {
          TITLE: 'Fondo móvil',
          DESCRIPTION:
            'Formato de archivo: JPG o PNG. Resolución: 1180x1920. Tamaño: Hasta 2 MB.',
        },
        DESKTOP_BG: {
          TITLE: 'Fondo del escritorio',
          DESCRIPTION:
            'Formato de archivo: JPG o PNG. Resolución: 1920x930. Tamaño: Hasta 2 MB.',
        },
      },
      HEADER: {
        TITLE: 'Encabezado',
        LOGO: {
          TITLE: 'Logotipo',
          DESCRIPTION: 'Formato de archivo: JPG o PNG. Tamaño: Hasta 1 MB.',
        },
        COLOR: {
          TITLE: 'Color del encabezado',
          HINT: 'Ejemplo: #ED1941.',
        },
      },
      FOOTER: {
        TITLE: 'Pie de página',
        CONTACT: {
          TYPE: 'Tipo de contacto {index}',
          CONTACT: 'Contacto {index}',
        },
        TYPES: {
          EMAIL: 'Correo electrónico',
          PHONE: 'Teléfono',
          WEBSITE: 'Website',
          WHATSAPP: 'WhatsApp',
        },
        ADD: 'Agregar contacto',
      },
      REGISTER_FORM: {
        TITLE: 'Formulario de registro',
        BANNER: {
          TITLE: 'Anuncio de registro',
          DESCRIPTION:
            'Formato de archivo: JPG o PNG. Resolución: 1920x1280. Tamaño: Hasta 2 MB.',
        },
        UNIQUE_ID:
          'Muestra el campo de entrada de identificador de gobierno único.',
      },
      MAP: {
        TITLE: 'Mapa',
        PIN_TYPE: {
          TITLE: 'Tipo de pin PDV',
          FIRST: 'Predeterminado',
          SECOND: 'Personalizado (icono cargado)',
        },
        CUSTOM: {
          TITLE: 'Pasador personalizado',
          DESCRIPTION: 'Formato de archivo: JPG o PNG. Tamaño: Hasta 1 MB.',
        },
      },
      BUTTONS: {
        TITLE: 'Botón y color de texto',
        FIRST: {
          TITLE: 'Botones y color de texto de la verificación de edad',
          HINT: 'Ejemplo: #ED1941',
        },
        SECOND: {
          TITLE: 'Color de botones y enlaces',
          HINT: 'Ejemplo: #ED1941',
        },
        BUTTON: 'Sí',
      },
      VALIDATIONS: {
        UPLOAD_FAILED: 'Carga fallida.',
        WRONG_FORMAT: 'Formato Erróneo',
        INVALID_EMAIL:
          'Ingresa un correo electrónico válido. Ejemplo: name@domain.com.',
        INVALID_PHONE: 'Ingresa un número de teléfono válido.',
        INVALID_WHATSAPP: 'Ingresa un número de WhatsApp válido.',
        INVALID_URL: 'Ingresa una URL válida.',
        INVALID_COLOR: 'Ingresa un color válido.',
        MAX_SIZE: 'El archivo superó los {size}.',
      },
    },
    PLATFORM_SETTINGS: {
      TITLE: 'Configuración de la plataforma',
      CAMPAIGN_HOST: 'Anfitrión de campaña',
      BEES_COUPONS: 'BEES Digital Coupons',
      THIRD_PARTY_PLATFORM: 'Plataforma de terceros',
      CAMPAIGN_PERIOD: 'Período de campaña',
      START_DATE: 'Fecha de inicio',
      END_DATE: 'Fecha de finalización',
      CAMPAIGN_TAG: 'Etiqueta de campaña',
      INITIATIVE: 'Iniciativa',
      YEAR: 'Año',
      LAUNCH: 'Lanzamiento',
      COUNTRY: 'País',
      UNIQUE_NAME: 'Nombre único',
      B2O_TAG: 'Etiqueta B2O global',
      DOLLAR_QUOTE: 'Cotización en dólares en la campaña',
      SEND_SMS: 'Envía el código del cupón por SMS al consumidor.',
      INVALID_DATE:
        'La fecha de finalización no puede ser anterior a la fecha de inicio.',
      INVALID_DOLLAR_QUOTE: 'El valor debe ser mayor o igual a 0.01.',
      COUPON_REDEMPTION_LIMIT: 'Límite de canje de cupones',
      COUPON_REDEMPTION_FREQUENCY_MINUTES: 'cada {time} minutos',
      COUPON_REDEMPTION_FREQUENCY_HOUR: 'cada {time} hora',
      LIMIT: 'Límite de canje (por período)',
      DAILY_REDEMPTION_LIMIT: 'Límite de canje diario por consumidor',
      PERIOD: 'Período',
      PERIODS: {
        FIVE_MIN: 'Cada 5 minutos',
        FIFTEEN_MIN: 'Cada 15 minutos',
        THIRTY_MIN: 'Cada 30 minutos',
        ONE_HOUR: 'Cada 1 hora',
      },
      CUSTOMER_LIMITS: 'Límites do PDV',
      TOOLTIP_INFO:
        'Establece un límite de canje diario por consumidor para prevenir el fraude de cupones y mejorar los resultados de la campaña.',
    },
    LEGAL_INFO: {
      TITLE: 'Información legal',
      CUSTOMER_SECTION: {
        TITLE: 'Cliente',
        DOCUMENT: {
          FILE: 'Archivo de documento {index}',
          FILE_PLACEHOLDER: 'Formato: PDF. Tamaño: Hasta 2 MB.',
          TITLE: 'Título del documento {index}',
          TITLE_HINT: 'Ejemplo: Términos y condiciones.',
          ADD: 'Agregar documento',
          LEGAL_TEXT: 'Información legal',
        },
      },
      CONSUMER_SECTION: {
        TITLE: 'Consumidor',
        TERMS_OF_USE: 'Términos de uso (URL)',
        PRIVACY_POLICY: 'Política de privacidad (URL)',
        CAMPAIGN_RULES: 'Reglas de campaña',
        CAMPAIGN_RULES_PLACEHOLDER: 'Formato: PDF. Tamaño: Hasta 2 MB.',
      },
    },
    PROMOTIONS: {
      ALERT:
        'Una vez creada la campaña, las promociones no se pueden eliminar, solo se desactivan. Aún es posible agregar nuevas promociones. Se debe habilitar al menos 1 promoción por campaña.',

      PROMOTION: 'Promoción',
      NAME: {
        LABEL: 'Nombre de la promoción',
        HINT: 'Ejemplo: 10 % de descuento en botella no retornable de Stella Artois. (Máx. 60 caracteres)',
      },
      CARD: {
        PREVIEW: 'Vista previa de la tarjeta de promoción',
        POINTS: 'Puntos BEES',
        TYPE: 'Tipo de cupón',
      },
      IMAGE: {
        NAME: 'Imagen informativa',
        SELECT: 'Seleccionar archivo',
        FORMAT:
          'Formato de archivo: JPG o PNG. Resolución: 1920 x 721. Tamaño: Hasta 1 MB.',
        ALT_TEXT: 'Texto ALT de imagen de promoción',
      },
      TOGGLE: {
        POC_BASE: 'Usar la base de PDV del nivel de la campaña',
      },
      STATUS: {
        IN_PROGRESS: 'En curso',
        DISABLED: 'Deshabilitado',
      },
      DELETE: {
        TITLE: '¿Eliminar promoción {index}?',
        DESCRIPTION: 'Esta promoción será eliminada permanentemente.',
        CONFIRM: 'Eliminar',
      },
      DOWNLOAD_TEMPLATE: 'Descargar plantilla',
      ADD_BUTTON: 'Agregar promoción',

      PROMOTION_GOAL: {
        TITLE: 'Objetivo de la promoción',
        INTRODUCE: {
          TITLE: 'Presentar',
          DESCRIPTION: 'Aumentar la cobertura de la marca.',
        },
        SUSTAIN: {
          TITLE: 'Mantener',
          DESCRIPTION: 'Aumenta la frecuencia de compra.',
        },
        GENERATE: {
          TITLE: 'Generar demanda',
          DESCRIPTION: 'Aumenta el monto por compra.',
        },
      },
      PRODUCT_SETTINGS: {
        TITLE: 'Configuración del producto',
        PRODUCT_TYPE: {
          TITLE: 'Tipo de producto',
          HINT: 'Ejemplo: Cerveza.',
        },
        PRODUCT_BRAND: {
          TITLE: 'Marca del producto',
          HINT: 'Ejemplo: Stella Artois.',
        },
        PRODUCT_NAME: {
          TITLE: 'Nombre del producto',
          HINT: 'Ejemplo: Botella no retornable de Stella Artois.',
        },
        TOTAL_UNITS: {
          TITLE: 'Unidades totales',
          HINT: 'Unidades en un solo cupón.',
        },
        PRICE_PER_UNIT: {
          TITLE: 'Precio por unidad',
          HINT: 'Valor individual.',
        },
        VOLUME_PER_UNIT: {
          TITLE: 'Volumen por unidad',
          HINT: 'En mililitros (ml).',
        },
      },
      COUPON_SETTINGS: {
        TITLE: 'Configuración de cupones',
        COUPON_TYPE: 'Tipo de cupón',
        DISCOUNT: 'Descuento',
        DISCOUNT_TITLE: 'Descuento (%)',
        FREE_GOOD: 'Producto gratis',
        FREE_GOOD_TITLE: 'Producto gratis (un.)',
        COUPON_AMOUNTS: {
          TITLE: 'Montos de cupones',
          TOTAL_GEN: 'Generación total máx.',
          CONSUMER_GEN: 'Generación máx. de consumidores',
          CONSUMER_ACTIVE_COUPONS: 'Máx. de cupones activos para consumidores',
          COUPON_AMOUNT_ALERT:
            'La cantidad de generación de cupones debe ser mayor que el límite de canje diario.',
        },
        COUPON_VALUATION: {
          TITLE: 'Valuación de cupones',
          POINTS_PER_COUPON: 'Puntos BEES por cupón',
          COUPON_PRICE: 'Precio del cupón',
          SUCCESS_MESSAGE: {
            TITLE: 'Mensaje de éxito',
            HINT: 'Ejemplo: ¡Felicitaciones! Acabas de ganar un cupón por una bebida gratis. Canjea tu beneficio en la tienda más cercana.',
          },
        },
      },
      DATES: {
        TITLE: 'Fechas',
        DESCRIPTION:
          'Las fechas deben estar dentro del período de fechas de inicio y fin de la campaña.',
        ALERT:
          'Si se supera el límite, se suspende el canje durante 30 min. Tres suspensiones en 24 h bloquean el PDV en todas las campañas para actividades sospechosas. Puedes desbloquearlas en PDV elegibles.',
        COUPON_GENERATION: {
          TITLE: 'Generación de cupón',
          START_DATE: 'Fecha de inicio',
          END_DATE: 'Fecha de finalización',
          EXP_TIME: 'Tiempo de vencimiento',
          HOURS: 'horas',
          EXP_TIME_ALERT:
            'Los cupones pueden vencer en caso de que el tiempo de vencimiento definido sea demasiado corto.',
        },
        COUPON_REDEMPTION: {
          TITLE: 'Canje de cupones',
          START_DATE: 'Fecha de inicio',
          END_DATE: 'Fecha de finalización',
        },
      },
      DIALOG_CONTENT:
        'Tenga en cuenta que si se desactiva esta promoción ya no podrá activarla ni verla en esta página. Si necesita habilitarla en el futuro, abra un ticket con nuestro equipo técnico.',
    },
    DOMAIN_AND_URL: {
      TITLE: 'Dominio y URL',
      DOMAIN_TYPE: {
        TITLE: 'Tipo de dominio',
        BEESDC: 'BEES Digital Coupons',
        CUSTOM: 'Personalizado',
      },
      DEFAULT_DOMAIN: {
        TITLE: 'Dominio predeterminado',
        HINT_BEESDC: 'Se utilizará el dominio Digital Coupons.',
        HINT_CUSTOM: 'Ejemplo: www.brandname.com/',
      },
      PATH_NAME: {
        TITLE: 'Nombre de ruta',
        HINT: 'Usa solo letras minúsculas, números y guiones.',
        ERROR: 'El URL de la campaña ya existe. Por favor elije otro.',
      },
      GTM_CONTAINER_ID: 'ID del contenedor GTM',
      CUSTOM_URL_COOKIES: 'Consentimiento personalizado de cookies de URL',
      GOOGLE_ANALYTICS_UA: 'Número de UA de Google Analytics 360',
      GOOGLE_ANALYTICS_VIEW_ID:
        'ID de vista de propiedad de Google Analytics 360',
    },
    DTC: {
      TITLE: 'Anuncio de código DTC',
      LINK: {
        LABEL: 'Enlace de redireccionamiento de códigos DTC',
      },
      IMAGE: {
        LABEL: 'Anuncio de código DTC',
        PLACEHOLDER:
          'Formato de archivo: JPG o PNG. Resolución: 1366x513. Tamaño: Hasta 2MB.',
        BUTTON: 'Seleccionar archivo',
      },
    },
    MEDIA_CAMPAIGNS: {
      TITLE: 'Campañas de medios',
      MEDIA_ACCOUNT: {
        LABEL: 'Lista de ID de cuenta de medios',
        CHANNEL: 'Canal {index}',
        ID: 'ID {index}',
        ADD: 'Agregar canal',
        TYPES: {
          DEFAULT: 'Seleccionar',
          ADSMOVIL: 'Adsmovil',
          EMAIL_MARKETING: 'Email marketing',
          FACEBOOK: 'Facebook',
          GOOGLE_ADS: 'Google Ads',
          PUSH_NOTIFICATION: 'Push notification',
          SMS: 'SMS',
          TWITTER: 'Twitter',
          VERIZON: 'Verizon',
          YOUTUBE: 'YouTube',
        },
      },
      SOCIAL_MEDIA: {
        LABEL: 'Enlace de redireccionamiento de redes sociales',
        SERVICE: 'Servicio {index}',
        LINK: 'Redirigir enlace {index}',
        ADD: 'Agregar servicio',
        TYPES: {
          DEFAULT: 'Seleccionar',
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          TIKTOK: 'TikTok',
          TWITTER: 'Twitter',
          YOUTUBE: 'YouTube',
        },
      },
    },
    SUMMARY: {
      UP_TO: 'Hasta {points} puntos',
      CAMPAIGN: 'Campaña',
      CONSUMER: {
        UNIQUE_ID: 'Identificación única',
        CONTACT_TYPE: 'Tipo de contacto',
      },
      PLATFORM: {
        SEND_SMS: 'Enviar código de cupón por SMS',
        START_DATE: 'Fecha de inicio de la campaña',
        END_DATE: 'Fecha de finalización de la campaña',
      },
      PROMOTIONS: {
        INDEX: 'Promoción {index}',
        POINTS: '{points} puntos',
        GOAL: 'Objetivo',
        COUPON_GENERATION_PERIOD: 'Período de generación de cupones',
        COUPON_REDEMPTION_PERIOD: 'Período de canje de cupones',
      },
      URL_AND_MEDIA: {
        TITLE: 'URL & medios',
        DOMAIN: 'Dominio',
      },
      DTC: {
        BANNER: 'Banner DTC',
      },
    },
  },
  EDIT: {
    EDIT_BUTTON: 'Editar',
    GO_BACK_BUTTON: 'Volver a la campaña de cupones',
    DISCARD_MODAL: {
      TITLE: '¿Descartar los cambios?',
      DESCRIPTION: 'Se perderán los cambios no guardados.',
      DISCARD: 'Descartar',
    },
  },
};

export default CouponCampaigns;

import {Grid, Heading} from '@hexa-ui/components';
import {useIntl} from 'react-intl';
import {useBreadcrumb, useToast} from '@Providers';
import {
  addPocsUseCase,
  getInconsistenciesUseCase,
  getPocsValidationStatusUseCase,
  uploadPocsByPromotionUseCase,
} from '@UseCase';
import {StyledFileUploader} from '@martech/components';
import {useEffect, useRef, useState} from 'react';
import {openBlob} from '@martech/utils';
import Axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {countCsvLines, formatNumber, getStorageURL} from '@Utils';
import {useAuthenticationService} from 'admin-portal-shared-services';
import {useCampaignDetails} from '@Hooks';
import {ButtonsBackSubmit, CustomCardFileUploader} from '@Components';

type ProcessStatus =
  | 'waiting'
  | 'loading'
  | 'processing'
  | 'done'
  | 'error'
  | undefined;

export const MAX_POCS_UPLOAD = 50000;

export default function AddEligiblePocsByPromotion() {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {toast} = useToast();
  const validationRef = useRef() as any;
  const authService = useAuthenticationService();

  const url = window.location.href;
  const segments = url.split('/');
  const promoIdUrl = segments[segments.length - 2];

  const {campaignId, promotionId} = useParams();
  const [file, setFile] = useState<File>();
  const [statusByPromo, setStatusByPromo] = useState<ProcessStatus>('done');
  const [processedLinesByPromo, setProcessedLinesByPromo] = useState(0);
  const [inconsistentLinesByPromo, setInconsistentLinesByPromo] = useState(0);
  const [csvLines, setCsvLines] = useState(0);
  const [uploadId, setUploadId] = useState<string>();
  const [validatingByPromo, setValidatingByPromo] = useState(false);
  const [addingByPromo, setAddingByPromo] = useState(false);
  const [onCancel, setOnCancel] = useState<any>();
  const [fileError, setFileError] = useState('');

  const [campaign] = useCampaignDetails({
    includeBlob: true,
    path: 'Promotions',
  });

  useBreadcrumb(
    [
      {
        name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
        href: '/campaigns',
      },
      {
        name: campaign?.campaignName || '',
      },
      {
        name: formatMessage({
          id: 'PROMOTION_LIST.BREADCRUMB_PROMOTION_LIST',
        }),
        href: `/campaigns/${campaignId}/promotion-list`,
      },
      {
        name: formatMessage({
          id: 'PROMOTION_DETAILS.DETAILS_BREADCRUMB',
        }),
        href: `/campaigns/${campaignId}/promotion-list/${promoIdUrl}`,
      },
      {
        name: formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.ELIGIBLE_POCS'}),
        active: true,
      },
    ],
    [campaign?.campaignName]
  );

  useEffect(() => {
    return () => {
      clearInterval(validationRef.current);
    };
  }, []);

  useEffect(() => {
    if (uploadId) {
      validationRef.current = setInterval(() => {
        getValidationStatus(uploadId);
      }, 5000);
    }
  }, [uploadId]);

  const getPromoName = () => {
    const promo = campaign?.promotions?.find(
      (promo: any) => promo.promotionId === Number(promoIdUrl)
    );
    const promoName = promo ? promo.promotionName : '';

    return promoName;
  };

  const handlePocValidationByPromotion = (
    file: File,
    callback: (uploadState: boolean) => void
  ) => {
    const handleFailure = () => {
      setStatusByPromo('error');
      callback(false);
      setValidatingByPromo(false);
    };

    setFileError('');
    setProcessedLinesByPromo(0);
    setFile(file);
    setStatusByPromo('loading');
    setValidatingByPromo(true);

    countCsvLines(file)
      .then(lines => {
        const actualLines = lines - 4;
        if (actualLines > MAX_POCS_UPLOAD || actualLines < 1) {
          setFileError('INVALID');
          handleFailure();
        } else {
          uploadPocsByPromotionUseCase(+campaignId!, file!, +promotionId!)
            .then(response => {
              setStatusByPromo('processing');
              callback(true);
              setCsvLines(response.numberOfPocs);
              setUploadId(response.pocUploadId);
            })
            .catch(error => {
              if (error.id === 'invalid') {
                setFileError('INVALID');
              }
              handleFailure();
            });
        }
      })
      .catch(() => {
        handleFailure();
        setFileError('TYPE');
      });
  };

  const getValidationStatus = (uploadedFileId: string) => {
    getPocsValidationStatusUseCase(uploadedFileId)
      .then(response => {
        setProcessedLinesByPromo(response.processedPocs);
        setInconsistentLinesByPromo(response.inconsistentPocs);

        if (response.processedPocs === csvLines) {
          setStatusByPromo('done');
          setValidatingByPromo(false);
          clearInterval(validationRef.current);
        }
      })
      .catch(() => {
        setStatusByPromo('done');
        setValidatingByPromo(false);
        clearInterval(validationRef.current);
      });
  };

  const handleExportInconsistentPocsByPromotion = () => {
    const request = Axios.CancelToken.source();

    setOnCancel(request);
    getInconsistenciesUseCase(uploadId!, request)
      .then(response => {
        const datetime = new Date().toISOString().split('T');
        const date = datetime[0].replaceAll('-', '_');
        const time = datetime[1].replaceAll(':', '_').slice(0, 8);

        openBlob(
          response,
          `pocs_upload_inconsistencies_report_${date}_${time}.csv`
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  const addPocsByPromotion = () => {
    const request = Axios.CancelToken.source();

    setOnCancel(request);
    setAddingByPromo(true);
    addPocsUseCase(uploadId!, request)
      .then(() => {
        toast({
          message: formatMessage(
            {id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.SUCCESS_MESSAGE_PROMOTION'},
            {
              value: formatNumber(csvLines - inconsistentLinesByPromo),
              promotionName: getPromoName(),
            }
          ),
          type: 'success',
        });
        navigateToPromotionDetails();
      })
      .catch(() => {
        toast({
          message: formatMessage({
            id: 'COMMON.ERRORS.DEFAULT',
          }),
          type: 'error',
        });
      })
      .finally(() => {
        setAddingByPromo(false);
      });
  };

  const handleCancel = () => {
    onCancel?.cancel();
    navigateToPromotionDetails();
  };

  const handleTemplateDownloadByPromo = () => {
    const storageURL = getStorageURL();
    const country = authService.getCountryB2C().toLowerCase();

    const templateUrl = `${storageURL}/templates/${country}/POC_Upload_template.csv`;
    window.open(templateUrl, '_blank');
  };

  const navigateToPromotionDetails = () => {
    navigate(`/campaigns/${campaignId}/promotion-list/${promoIdUrl}`);
  };

  return (
    <Grid.Container
      sidebar
      data-testid="add-eligible-pocs-by-promotion-page"
      type="fluid"
      style={{margin: 0, width: '100%', justifyContent: 'center'}}
    >
      <Grid.Item xs={12}>
        <Heading data-testid="campaignTitle" size="H2">
          {formatMessage(
            {
              id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.TITLE',
            },
            {title: getPromoName()}
          )}
        </Heading>
      </Grid.Item>

      <CustomCardFileUploader
        titleName={getPromoName()}
        handleTemplateDownload={handleTemplateDownloadByPromo}
        isDisabledDownloadTemplate={addingByPromo}
        hasEligiblePocs={campaign && campaign.eligible > 0}
        textIdDescriptionOne="CAMPAIGN_ELIGIBLE_POCS.ADD.FILE_DESCRIPTION_PROMOTION"
        textIdDescriptionTwo="CAMPAIGN_ELIGIBLE_POCS.ADD.FILE_DESCRIPTION_TWO"
        hasFeedbackCard
        file={file}
        status={statusByPromo}
        dataTestIdProcessingFeedbackCard="processing-container-by-promotion"
        dataTestIdProcessingFeedbackCardStatus="process-status-by-promotion"
        dataTestIdDoneFeedbackCard="done-container-by-promotion"
        dataTestIdDoneFeedbackCardInconsistencies="pocs-to-add-by-promotion"
        dataTestIdDoneFeedbackCardExportError="export-error-pocs-by-promotion"
        isDisabledExportError={addingByPromo}
        processedLines={processedLinesByPromo}
        inconsistentLines={inconsistentLinesByPromo}
        csvLines={csvLines}
        handleExportInconsistentPocs={handleExportInconsistentPocsByPromotion}
      >
        <StyledFileUploader
          onChange={handlePocValidationByPromotion}
          isLoading={statusByPromo === 'loading'}
          handleRemove={() => {
            setFile(undefined);
            setFileError('');
          }}
          acceptedType=".csv"
          placeholder={formatMessage({
            id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.DROP_PLACEHOLDER',
          })}
          buttonText={formatMessage({
            id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.BROWSE_FILE',
          })}
          isDisabled={addingByPromo || validatingByPromo}
          errorMessage={
            fileError
              ? formatMessage({
                  id: `CAMPAIGN_ELIGIBLE_POCS.ADD.${fileError}_ERROR`,
                })
              : undefined
          }
          iconType="doc"
          value={file}
        />
      </CustomCardFileUploader>

      <Grid.Item
        xs={9}
        style={{justifyContent: 'flex-end', margin: '2rem auto'}}
      >
        <ButtonsBackSubmit
          dataTestIdBtnOne="cancel"
          dataTestIdBtnTwo="add-pocs-by-promotion"
          variantBtnOne="secondary"
          styleBtnOne={{marginRight: '1rem'}}
          onClickBtnOne={handleCancel}
          onClickBtnTwo={addPocsByPromotion}
          textIdBtnOne="CAMPAIGN_ELIGIBLE_POCS.ADD.CANCEL"
          textIdBtnTwo="CAMPAIGN_ELIGIBLE_POCS.ADD.ADD_POCS"
          disabledBtnTwo={
            statusByPromo !== 'done' ||
            !uploadId ||
            !file ||
            inconsistentLinesByPromo === csvLines
          }
          isLoadingBtnTwo={addingByPromo}
        />
      </Grid.Item>
    </Grid.Container>
  );
}

import MessageMap from '../../i18n';

const promotionList: MessageMap = {
  TITLE: 'Promoções de {title}',
  SEARCH_PLACEHOLDER: 'Pesquisar por nome da promoção',
  TABLE: {
    PROMOTION_NAME: 'Nome da promoção',
    PARTICIPATING_POCS: 'PDVs participantes',
    START_DATE: 'Data de início',
    END_DATE: 'Data de término',
    ACTIONS: 'Ações',
  },
  NO_DATA: {
    TITLE: 'Você não tem promoções',
    DESCRIPTION: 'Adicione uma nova clicando em "Adicionar promoção".',
  },
  FILTERS: {
    DATES: {
      TITLE: 'Datas da promoção',
      START: 'Data de início',
      END: 'Data final',
    },
    INVALID_DATE: 'Escolha uma data posterior {startDate}.',
  },
  BREADCRUMB_PROMOTION_LIST: 'Promoções',
};

export default promotionList;

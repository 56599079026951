import React from 'react';
import {useField} from 'formik';
import {Select, SelectProps} from '@hexa-ui/components';

const CustomSelectInput: React.FC<SelectProps> = props => {
  const [field, meta, helper] = useField(props as any);

  const {width = '100%'} = props;

  return (
    <div style={{width}}>
      <Select.Root
        {...field}
        error={meta.touched && meta.error ? meta.error : undefined}
        label={props.label}
        id={props.name}
        placeholder={props.placeholder ?? ''}
        width={width}
        data-testid={`select-input:${props.name}`}
        value={field.value ? field.value.toString() : ''}
        onChange={value => {
          if (props.onChange) {
            props.onChange(value);
          } else {
            helper.setTouched(true);
            value && helper.setValue(value);
          }
        }}
        onBlur={() => {
          helper.setTouched(true);
        }}
      >
        {props.children}
      </Select.Root>
    </div>
  );
};

export default CustomSelectInput;

import MessageMap from '../../i18n';

const blockPoc: MessageMap = {
  TITLE: 'Block {pocName}?',
  ACTION: 'Yes, block POC',
  SUCCESS_MESSAGE:
    '{pocName} was successfully blocked from the {campaignName} campaign.',
  MANUALLY_FRAUD_PREVENTION: 'Block by fraud prevention',
  MANUALLY_FRAUD_PREVENTION_DESCRIPTION:
    'This option should be used in case of suspicious activities. The POC will not be able to redeem coupons in any campaign. Any pending BEES points will not be provided.',
  DISABLED_PARTICIPATION: 'Disable participation',
  DISABLED_PARTICIPATION_DESCRIPTION:
    'This POC will not be able to redeem coupons in this campaign.',
};

export default blockPoc;

import {InputWrapper} from '@hexa-ui/components';
import {StyledFileUploader} from '@martech/components';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

interface CustomStyledUploaderProps {
  acceptedTypes: string;
  onChange: (file: File, callback: (uploadState: boolean) => void) => void;
  onRemove: () => void;
  placeholder: string;
  maxSizeInMB: number;
  type?: 'img' | 'file' | 'doc';
  label?: string;
  width?: string;
  error?: string;
  buttonText?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  value?: File;
}

const CustomStyledUploader: React.FC<CustomStyledUploaderProps> = ({
  acceptedTypes,
  onChange,
  onRemove,
  placeholder,
  maxSizeInMB,
  type,
  label,
  width = '100%',
  error,
  buttonText,
  value,
  isLoading = false,
  isDisabled = false,
}) => {
  const {formatMessage} = useIntl();

  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  const errorMessage = useMemo(() => {
    if (typeError) {
      return formatMessage({
        id: 'COMMON.ERRORS.TYPE_ERROR',
      });
    }
    if (sizeError) {
      return formatMessage(
        {
          id: 'COMMON.ERRORS.MAX_SIZE',
        },
        {
          size: maxSizeInMB,
        }
      );
    }
    if (error) return error;

    return undefined;
  }, [error, typeError, sizeError, maxSizeInMB]);

  const isTypeValid = (file: File) => {
    const types = acceptedTypes.replace(/\s/g, '').split(',');
    if (types.includes(file.type)) {
      setTypeError(false);
      return true;
    }

    setTypeError(true);
    return false;
  };

  const isSizeValid = (file: File) => {
    const maxSize = maxSizeInMB * 1024 * 1024;
    if (file.size <= maxSize) {
      setSizeError(false);
      return true;
    }

    setSizeError(true);
    return false;
  };

  const isFileValid = (file: File) => {
    return isTypeValid(file) && isSizeValid(file);
  };

  const handleChange = (
    file: File,
    callback: (uploadState: boolean) => void
  ) => {
    if (isFileValid(file)) {
      onChange(file, callback);
      setTypeError(false);
    }
  };

  const handleRemove = () => {
    setTypeError(false);
    setSizeError(false);
    onRemove();
  };

  return (
    <InputWrapper label={label} width={width}>
      <StyledFileUploader
        acceptedType={acceptedTypes}
        buttonText={
          buttonText ||
          formatMessage({
            id: 'COMMON.BUTTONS.SELECT_FILE',
          })
        }
        onChange={handleChange}
        handleRemove={handleRemove}
        placeholder={placeholder}
        errorMessage={errorMessage}
        iconType={type}
        isLoading={isLoading}
        isDisabled={isDisabled}
        value={value}
      />
    </InputWrapper>
  );
};

export default CustomStyledUploader;

import api from '../../api';

export default async function validateLandingPageUrlService(
  landingPageUrl: string,
  campaignId?: string
): Promise<any> {
  const id = campaignId ? `&campaignId=${campaignId}` : '';

  return await api.get({
    url: `/v1/admin/campaign-landing-pages?page=0&pageSize=1&path=${landingPageUrl}${id}`,
  });
}

import axios from 'axios';

export default async function urlToFile(url: string) {
  try {
    const {data} = await axios.get(url, {responseType: 'blob'});
    const fileName = url.substring(url.lastIndexOf('/') + 1);

    return new File([data], fileName, {type: data.type});
  } catch (error) {
    return undefined;
  }
}

import MessageMap from '../../i18n';

const campaignEligiblePocs: MessageMap = {
  ELIGIBLE_POCS: 'PDV elegibles',
  LIST: {
    SUMMARY: 'Resumen',
    ELIGIBLE_POCS_FOR: 'PDVs elegibles para {title}',
    ELIGIBLE_POCS: 'PDVs elegibles',
    PARTICIPATING: 'PDVs participantes',
    BLOCKED: 'PDVs bloqueados',
    ADD_BUTTON: 'Agregar PDV elegibles',
    SEARCH: 'Buscar por código de PDV o nombre',
    POC_ID: 'Código de PDV',
    POC_NAME: 'Nombre del PDV',
    STATUS: 'Estado',
    ADDED_ON: 'Agregado el',
    BLOCKED_ON: 'Bloqueado el',
    ACTION: 'Acción',
    BULK_MANAGE_BUTTON: 'Gestión masiva de PDVs',

    NO_DATA: {
      TITLE: 'Esta campaña aún no tiene ningún PDV elegible',
      DESCRIPTION: 'Agrégalos haciendo clic en “Agregar PDV elegibles”.',
    },
    FILTER: 'Estado',
    STATUSES: {
      PARTICIPATING: 'Participando',
      NOT_PARTICIPATING: 'Sin participar',
      BLOCKED_MANUAL: 'Bloqueado - Manualmente',
      BLOCKED_MANUAL_DISABLED:
        'Bloqueado manualmente - Desactivado en la campaña',
      BLOCKED_MANUALLY_FRAUD_PREVENTION:
        'Bloqueado manualmente - Prevención del fraude',
      BLOCKED_AUTOMATIC: 'Bloqueado - Prevención del fraude',
      DISABLED_ON_PROMOTION: 'Desactivado manualmente en promoción',
    },
    STATUSES_TOOLTIP_INFO: {
      BLOCKED_MANUAL: 'Participación desactivada.',
      BLOCKED_MANUALLY_FRAUD_PREVENTION: 'Bloqueado por prevención del fraude.',
    },
    ACTIONS: {
      BLOCK: 'Bloquear PDV',
      UNBLOCK: 'Desbloquear PDV',
    },
  },
  ADD: {
    TITLE: 'Agregar PDV elegibles - {title}',
    BREADCRUMB: 'Agregar PDV elegibles',
    FILE_UPLOAD: 'Examinar archivo',
    FILE_DESCRIPTION_ONE:
      'Los PDV agregados estarán habilitados para participar en la campaña del {title}.',
    FILE_DESCRIPTION_TWO:
      'El archivo debe contener solo los códigos de PDV, separados por un salto de línea.',
    FILE_DESCRIPTION_THREE:
      'Sigue las instrucciones dentro de la plantilla para agregar una lista exitosamente.',
    FILE_DESCRIPTION_PROMOTION:
      'Los PDV agregados estarán habilitados para participar en la promoción del {title}.',
    DOWNLOAD_TEMPLATE: 'Descargar plantilla de hoja de cálculo',
    DROP_PLACEHOLDER:
      'El archivo debe tener formato .csv y menos de 2MB de tamaño.',
    UPLOAD_INFO:
      'Este archivo agregará nuevos códigos a la lista actual de PDV sin anularlo.',
    INVALID_ERROR:
      'Formato de datos incorrecto. Descarga la plantilla y sigue sus instrucciones.',
    TYPE_ERROR: 'Tipo de archivo no soportado.',
    BROWSE_FILE: 'Seleccionar archivo',
    UPLOAD_ANALYZE_TITLE: 'Análisis del archivo cargado',
    PROCESSED: '{processed} procesado de {total}',
    STARTING: 'Comenzando',
    POCS_READY: 'PDV listos para ser agregados',
    POCS_ERROR: 'PDV con información inconsistente',
    UPLOADED_ALERT:
      'Para revisar las notas de error, exporta la lista de PDVs con información inconsistente. Luego, puedes cargar un archivo nuevo corregido en el paso anterior o continuar con solo los PDV listos para agregar.',
    EXPORT_ERROR_POCS: 'Exportar PDV inconsistentes',
    CANCEL: 'Cancelar',
    ADD_POCS: 'Agregar PDVs',
    SUCCESS_MESSAGE:
      'Se agregaron {value} PDV con éxito a la campaña del {campaignName}.',
    SUCCESS_MESSAGE_PROMOTION:
      'Se agregaron {value} PDV con éxito a la promoción del {promotionName}.',
  },
  BULK_MANAGE: {
    TITLE: 'Gestión masiva de PDVs - {title}',
    BREADCRUMB: 'Gestión masiva de PDVs',
    SELECT_BULK_ACTION: 'Seleccione una acción en masa',
    MANAGE_POCS_BUTTON: 'Administrar PDVs',
    BLOCK_FRAUD_PREVENTION_ACTION: 'Bloquear - Prevención de fraude',
    UNBLOCK_FRAUD_PREVENTION_ACTION: 'Desbloquear - Prevención de fraude',
    DISABLE_PARTICIPATION_ACTION: 'Desactivar participación',
    ENABLE_PARTICIPATION_ACTION: 'Activar participación',
    BLOCK_FRAUD_PREVENTION_DESCRIPTION:
      'Esta opción debe utilizarse en caso de actividades sospechosas. Los PDVs no podrán canjear cupones en ninguna campaña.',
    UNBLOCK_FRAUD_PREVENTION_DESCRIPTION:
      'Una vez desbloqueados, los PDVs podrán utilizar estos puntos BEES y validar cupones nuevamente.',
    UNBLOCK_FRAUD_PREVENTION_DESCRIPTION_TWO:
      'Esta acción desbloqueará los PDVs que fueron bloqueados por la prevención automática de fraudes, liberando los puntos en todas las campañas.',
    DISABLE_PARTICIPATION_DESCRIPTION:
      'Desactiva los PDVs para participar en la campaña y validar cupones nuevamente.',
    ENABLE_PARTICIPATION_DESCRIPTION:
      'Una vez habilitado, los PDVs participarán en la campaña y podrán validar cupones nuevamente.',
    POC_UPLOAD_LIMIT: 'Límite de {value}K POCs a la vez',
    SUCCESS_MESSAGE_ENABLE_PARTICIPATION:
      '{value} PDVs se habilitaron exitosamente para la campaña {campaignName}.',
    SUCCESS_MESSAGE_DISABLE_PARTICIPATION:
      '{value} PDVs se desactivaron exitosamente para la campaña {campaignName}.',
    SUCCESS_MESSAGE_BLOCK_FRAUD_PREVENTION:
      '{value} PDVs se bloquearon exitosamente por prevención de fraude para la campaña {campaignName}.',
    SUCCESS_MESSAGE_UNBLOCK_FRAUD_PREVENTION:
      '{value} PDVs se desbloquearon exitosamente por prevención de fraude para la campaña {campaignName}.',
    ERROR_MESSAGE_ENABLE_PARTICIPATION:
      '{value} PDVs no se habilitaron para la campaña {campaignName}.',
    ERROR_MESSAGE_DISABLE_PARTICIPATION:
      '{value} PDVs no se desactivaron para la campaña {campaignName}.',
    ERROR_MESSAGE_BLOCK_FRAUD_PREVENTION:
      '{value} PDVs no se bloquearon por prevención de fraude para la campaña {campaignName}.',
    ERROR_MESSAGE_UNBLOCK_FRAUD_PREVENTION:
      '{value} PDVs no se desbloquearon por prevención de fraude para la campaña {campaignName}.',
  },
};

export default campaignEligiblePocs;

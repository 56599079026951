import {EnvConfig, EnvProvider, ToastProvider} from '@Providers';
import {IntlProvider} from './i18n';
import Router from './Router';
import {useGlobalStyles} from '@Hooks';

export default function App(props: EnvConfig) {
  useGlobalStyles();

  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <ToastProvider>
          <Router />
        </ToastProvider>
      </IntlProvider>
    </EnvProvider>
  );
}

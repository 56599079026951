import {Paginated, PocCampaignsSummary} from '@Models';
import {getCampaignsAndPointsService} from '@Services';

async function getCampaignsAndPointsUseCase(
  pocCode: string
): Promise<Paginated<PocCampaignsSummary>> {
  const response = await getCampaignsAndPointsService(pocCode);

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default getCampaignsAndPointsUseCase;

import {useFormikContext} from 'formik';
import {useEffect} from 'react';

interface ValuesWatcherProps {
  onChange: (values: any, isValid: boolean) => void;
}

export default function ValuesWatcher({onChange}: ValuesWatcherProps) {
  const {values, isValid} = useFormikContext();

  useEffect(() => {
    onChange(values, isValid);
  }, [values, isValid]);

  return null;
}

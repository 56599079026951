import {Button, Grid, Heading, TextLink, Tooltip} from '@hexa-ui/components';
import {useIntl} from 'react-intl';
import {ToastData, useBreadcrumb, useToast} from '@Providers';
import {Plus} from '@hexa-ui/icons';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import {LoadDataProps, POC, Paginated, SortData, POCStatusTypes} from '@Models';
import {enableDisablePocUseCase, getPocsByPromotionUseCase} from '@UseCase';
import {
  StatusDot,
  TableCard,
  LoadingBee,
  EnablePocModal,
  DisablePocModal,
} from '@Components';
import {formatDate} from '@Utils';
import useBasePromotionDetailsFilter from './PromotionDetails.filter';
import {useCampaignDetails} from '@Hooks';

export default function PromotionDetails() {
  const navigate = useNavigate();
  const {formatMessage} = useIntl();
  const {campaignId} = useParams();
  const {toast} = useToast();
  const url = window.location.href;
  const segments = url.split('/');
  const promoIdUrl = segments[segments.length - 1];

  const baseFilter = useBasePromotionDetailsFilter();
  const [filter, setFilter] = useState(baseFilter);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortProp, setSortProp] = useState<SortData>({
    prop: 'pocName',
    direction: 'ASC',
  });

  const [loadingData, setLoadingData] = useState(false);
  const [loadingEnableDisable, setLoadingEnableDisable] = useState(false);
  const [pocsDetails, setPocsDetails] = useState<Paginated<POC>>({
    content: [],
    pagination: {
      page: 0,
      pageSize: 0,
      totalElements: 0,
      totalPages: 0,
    },
  });
  const [pocList, setPocList] = useState({id: '', name: '', status: ''});
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);

  const [campaign, isLoading] = useCampaignDetails({
    includeBlob: true,
    path: 'Promotions',
  });

  useEffect(() => {
    loadPocsDetails({});
  }, []);

  const loadPocsDetails = async ({page = 0, props}: LoadDataProps) => {
    setLoadingData(true);
    setPocsDetails({
      content: [],
      pagination: {
        page: 0,
        pageSize: 0,
        totalElements: 0,
        totalPages: 0,
      },
    });

    try {
      const sortOption = props?.sort || sortProp;
      const fetchPocs = await getPocsByPromotionUseCase(
        +campaignId!,
        page,
        promoIdUrl,
        {
          ...props,
          sort: sortOption,
        }
      );
      setSortProp(sortOption);
      setPocsDetails(fetchPocs);
      return fetchPocs;
    } catch {
      return undefined;
    } finally {
      setLoadingData(false);
    }
  };

  const openDisableModal = () => {
    setDisableModalOpen(true);
  };

  const closeDisableModal = () => {
    setDisableModalOpen(false);
  };

  const openEnableModal = () => {
    setEnableModalOpen(true);
  };

  const closeEnableModal = () => {
    setEnableModalOpen(false);
  };
  const showToastMessageDetails = (
    messageId: string,
    type: ToastData['type']
  ) => {
    const promotionName = getPromoName();
    toast({
      message: formatMessage(
        {id: messageId},
        {
          pocName: pocList.name,
          promotionName: promotionName,
        }
      ).toString(),
      type: type,
    });
  };

  const disablePocDetails = () => {
    setLoadingEnableDisable(true);
    const campaignId = campaign?.campaignId;
    enableDisablePocUseCase(
      pocList.id,
      campaignId ?? 0,
      true,
      Number(promoIdUrl)
    )
      .then(() => {
        showToastMessageDetails('DISABLE_POC.SUCCESS_MESSAGE', 'success');
        loadPocsDetails({});
      })
      .catch(() => {
        showToastMessageDetails('COMMON.ERRORS.DEFAULT', 'error');
      })
      .finally(() => {
        setLoadingEnableDisable(false);
        closeDisableModal();
      });
  };

  const enablePocDetails = () => {
    setLoadingEnableDisable(true);
    const campaignId = campaign?.campaignId;

    enableDisablePocUseCase(
      pocList.id,
      campaignId ?? 0,
      false,
      Number(promoIdUrl)
    )
      .then(() => {
        showToastMessageDetails('ENABLE_POC.SUCCESS_MESSAGE', 'success');
        loadPocsDetails({});
      })
      .catch(() => {
        showToastMessageDetails('COMMON.ERRORS.DEFAULT', 'error');
      })
      .finally(() => {
        setLoadingEnableDisable(false);
        closeEnableModal();
      });
  };

  const isBlockedStatusPoc = (poc: any) => {
    if (
      poc.status !== POCStatusTypes.PARTICIPATING &&
      poc.status !== POCStatusTypes.NOT_PARTICIPATING &&
      poc.status !== POCStatusTypes.DISABLED_ON_PROMOTION
    ) {
      return true;
    } else {
      return false;
    }
  };

  const pocDetailsStatusMessageMap: {[key: string]: string} = {
    PARTICIPATING: 'PROMOTION_DETAILS.STATUSES.POC_PARTICIPATING',
    NOT_PARTICIPATING: 'PROMOTION_DETAILS.STATUSES.POC_NOT_PARTICIPATING',
    BLOCKED_MANUAL: 'PROMOTION_DETAILS.STATUSES.POC_BLOCKED_MANUAL',
    BLOCKED_AUTOMATIC: 'PROMOTION_DETAILS.STATUSES.POC_BLOCKED_AUTOMATIC',
    BLOCKED_MANUALLY_FRAUD_PREVENTION:
      'PROMOTION_DETAILS.STATUSES.POC_BLOCKED_MANUAL',
    DISABLED_ON_PROMOTION:
      'PROMOTION_DETAILS.STATUSES.POC_DISABLED_ON_PROMOTION',
  };

  const getPocStatusMessageDetails = (pocStatus: string) => {
    const pocDetailsStatusUppercase = pocStatus
      .replaceAll('-', '_')
      .toUpperCase();

    const pocStatusMessageDetails =
      pocDetailsStatusMessageMap[pocDetailsStatusUppercase] || '';

    return formatMessage({
      id: pocStatusMessageDetails,
    });
  };

  const pocDetailsStatusTooltipInfoMessageMap: {[key: string]: string} = {
    BLOCKED_MANUAL:
      'PROMOTION_DETAILS.STATUSES_TOOLTIP_INFO.POC_BLOCKED_MANUAL',
    BLOCKED_MANUALLY_FRAUD_PREVENTION:
      'PROMOTION_DETAILS.STATUSES_TOOLTIP_INFO.POC_BLOCKED_MANUALLY_FRAUD_PREVENTION',
  };

  const showStatusDetailsTooltipInfo = (pocStatus: string) => {
    const pocStatusUppercase = pocStatus.replaceAll('-', '_').toUpperCase();

    const pocDetailsStatusTooltipInfoMessage =
      pocDetailsStatusTooltipInfoMessageMap[pocStatusUppercase];

    if (!pocDetailsStatusTooltipInfoMessage) return;

    return formatMessage({
      id: pocDetailsStatusTooltipInfoMessage,
    });
  };

  const handleDisablePocDetails = (poc: POC) => {
    setPocList({
      id: poc.pocId,
      name: poc.pocName,
      status: poc.status,
    });

    openDisableModal();
  };

  const handleEnablePoc = async (poc: POC) => {
    setPocList({
      id: poc.pocId,
      name: poc.pocName,
      status: poc.status,
    });
    openEnableModal();
  };

  const getPromoName = () => {
    const promo = campaign?.promotions?.find(
      (promo: any) => promo.promotionId === Number(promoIdUrl)
    );
    const promoName = promo ? promo.promotionName : '';

    return promoName;
  };

  const getStatusDetails = (status: string) => {
    if (status === POCStatusTypes.PARTICIPATING) {
      return 'success';
    }

    if (status === POCStatusTypes.NOT_PARTICIPATING) {
      return 'info';
    }

    return 'error';
  };
  const tableDataPromoDetails = useMemo(() => {
    return {
      table: {
        columns: [
          {
            id: 'pocIdDetails',
            label: formatMessage({
              id: 'PROMOTION_DETAILS.TABLE.DETAILS_POC_ID',
            }),
            dimension: '15%',
            sort: true,
          },
          {
            id: 'pocNameDetails',
            label: formatMessage({
              id: 'PROMOTION_DETAILS.TABLE.DETAILS_POC_NAME',
            }),
            dimension: '20%',
            sort: true,
          },
          {
            id: 'statusDetails',
            label: formatMessage({
              id: 'PROMOTION_DETAILS.TABLE.DETAILS_STATUS',
            }),
            dimension: '22%',
          },
          {
            id: 'uploadDateDetails',
            label: formatMessage({
              id: 'PROMOTION_DETAILS.TABLE.DETAILS_ADDED_ON',
            }),
            dimension: '15%',
          },
          {
            id: 'blockedAtDetails',
            label: formatMessage({
              id: 'PROMOTION_DETAILS.TABLE.DETAILS_BLOCKED_ON',
            }),
            dimension: '15%',
            sort: true,
          },
          {
            id: 'actionsDetails',
            label: formatMessage({
              id: 'PROMOTION_DETAILS.TABLE.DETAILS_ACTION',
            }),
            dimension: '12%',
          },
        ],
        cellHeight: 'tight',
        fontSize: '12px',
        pagination: {
          current: pocsDetails.pagination.page + 1,
          pageSize: 50,
          onChange: (page: number) => {
            loadPocsDetails({page: page - 1, props: {filter, searchTerm}});
          },
          total: pocsDetails.pagination.totalElements,
        },
        data: pocsDetails.content.map((pocDetails, index) => ({
          pocIdDetails: pocDetails.pocId,
          pocNameDetails:
            pocDetails.pocName?.length > 25 ? (
              <Tooltip text={pocDetails.pocName}>
                {`${pocDetails.pocName.slice(0, 25)}...`}
              </Tooltip>
            ) : (
              pocDetails.pocName
            ),
          statusDetails: (
            <StatusDot
              status={getStatusDetails(pocDetails.status)}
              text={getPocStatusMessageDetails(pocDetails.status)}
              infoTooltip={showStatusDetailsTooltipInfo(pocDetails.status)}
            />
          ),
          uploadDateDetails: formatDate(pocDetails.uploadDate),
          blockedAtDetails:
            pocDetails.blocked && pocDetails.blockedAt
              ? formatDate(pocDetails.blockedAt)
              : 'N/A',
          actionsDetails:
            getStatusDetails(pocDetails.status) !== 'error' ? (
              <div data-testid={`disable-poc-action-${index}`}>
                <TextLink
                  size="xsmall"
                  onClick={() => {
                    if (isBlockedStatusPoc(pocDetails)) {
                      return;
                    }

                    handleDisablePocDetails(pocDetails);
                  }}
                  children={formatMessage({
                    id: 'PROMOTION_DETAILS.TABLE.DETAILS_DISABLE',
                  })}
                  style={{
                    color: isBlockedStatusPoc(pocDetails) ? 'gray' : '#c9201d',
                    cursor: isBlockedStatusPoc(pocDetails)
                      ? 'default'
                      : 'pointer',
                  }}
                />
              </div>
            ) : (
              <div data-testid={`enable-poc-action-${index}`}>
                <TextLink
                  size="xsmall"
                  onClick={() => {
                    if (isBlockedStatusPoc(pocDetails)) {
                      return;
                    }

                    handleEnablePoc(pocDetails);
                  }}
                  children={formatMessage({
                    id: 'PROMOTION_DETAILS.TABLE.DETAILS_ENABLE',
                  })}
                  style={{
                    color: isBlockedStatusPoc(pocDetails) ? 'gray' : 'default',
                    cursor: isBlockedStatusPoc(pocDetails)
                      ? 'default'
                      : 'pointer',
                  }}
                />
              </div>
            ),
        })),
      },
      loadingData,
      noDataTitle: formatMessage({
        id: 'PROMOTION_DETAILS.NO_DATA.DETAILS_TITLE',
      }),
      noDataDescription: formatMessage({
        id: 'PROMOTION_DETAILS.NO_DATA.DETAILS_DESCRIPTION',
      }),
    };
  }, [loadingData, pocsDetails, searchTerm]);

  useBreadcrumb(
    [
      {
        name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
        href: '/campaigns',
      },
      {
        name: `${campaign?.campaignName ?? ''}`,
      },
      {
        name: formatMessage({
          id: 'PROMOTION_LIST.BREADCRUMB_PROMOTION_LIST',
        }),
        href: `/campaigns/${campaignId}/promotion-list`,
      },
      {
        name: formatMessage({
          id: 'PROMOTION_DETAILS.DETAILS_BREADCRUMB',
        }),
        active: true,
      },
    ],
    [campaign?.campaignName]
  );

  return isLoading ? (
    <LoadingBee />
  ) : (
    <Grid.Container
      sidebar
      data-testid="promotion-details-container"
      type="fluid"
      style={{margin: 0, width: '100%'}}
    >
      <DisablePocModal
        pocName={pocList.name}
        isOpen={disableModalOpen}
        onClose={closeDisableModal}
        onDisable={disablePocDetails}
        loading={loadingEnableDisable}
      />
      <EnablePocModal
        pocName={pocList.name}
        isOpen={enableModalOpen}
        onClose={closeEnableModal}
        onEnable={enablePocDetails}
        loading={loadingEnableDisable}
      />
      <Grid.Item xs={6}>
        <Heading data-testid="promotion-title" size="H2">
          {formatMessage(
            {id: 'PROMOTION_DETAILS.DETAILS_TITLE'},
            {title: getPromoName()}
          )}
        </Heading>
      </Grid.Item>
      <Grid.Item style={{justifyContent: 'flex-end'}} xs={6}>
        <Button
          data-testid="upload-pocs-button"
          onClick={() => navigate('upload')}
          icon={Plus}
          leading
        >
          {formatMessage({id: 'PROMOTION_DETAILS.DETAILS_ADD_BUTTON'})}
        </Button>
      </Grid.Item>
      <Grid.Item style={{margin: '1.5rem auto'}} xs={12}>
        <TableCard
          tableData={tableDataPromoDetails}
          filter={filter}
          handleFilterChange={value => setFilter(value)}
          searchPlaceholder={formatMessage({
            id: 'PROMOTION_DETAILS.DETAILS_SEARCH',
          })}
          loadData={loadPocsDetails}
          onSearchTerm={term => {
            setSearchTerm(term);
          }}
        />
      </Grid.Item>
    </Grid.Container>
  );
}

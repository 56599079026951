import {useCurrentLocale} from '../../hooks';

const getDateMask = () => {
  const locale = useCurrentLocale();

  if (locale === 'en') {
    return 'MM/DD/YYYY';
  }

  return 'DD/MM/AAAA';
};

export default getDateMask;

import MessageMap from '../../i18n';

const enablePoc: MessageMap = {
  TITLE: `Habilitar {pocName}?`,
  ENABLE_PARTICIPATION_DESCRIPTION:
    'Se habilitado, o PDV poderá participar da promoção e validar cupons novamente.',
  ACTION: 'Sim, habilitar PDV',
  SUCCESS_MESSAGE:
    '{pocName} foi habilitado com sucesso para a promoção {promotionName}.',
};

export default enablePoc;

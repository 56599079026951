import MessageMap from '../../i18n';

const blockPoc: MessageMap = {
  TITLE: 'Bloquear {pocName}?',
  TEXT_WARNING: 'Eles não poderão validar cupons na campanha {campaignName}.',
  ACTION: 'Sim, bloquear PDV',
  SUCCESS_MESSAGE:
    '{pocName} foi bloqueado com sucesso na campanha {campaignName}.',
  MANUALLY_FRAUD_PREVENTION: 'Bloqueio por prevenção a fraude',
  MANUALLY_FRAUD_PREVENTION_DESCRIPTION:
    'Esta opção deve ser usada em caso de atividades suspeitas. O PDV não poderá resgatar cupons em nenhuma campanha. Quaisquer pontos BEES pendentes não serão fornecidos.',
  DISABLED_PARTICIPATION: 'Desativar participação',
  DISABLED_PARTICIPATION_DESCRIPTION:
    'Este PDV não poderá resgatar cupons nesta campanha.',
};

export default blockPoc;

import enUSTranslations from './en-US';
import ptBRTranslations from './pt-BR';
import es419Translations from './es-419';

const resources = {
  'en-US': enUSTranslations,
  'pt-BR': ptBRTranslations,
  'es-419': es419Translations,
};

export default resources;

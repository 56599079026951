import {StatusDot, TableCard, Thumbnail} from '@Components';
import {Campaign, LoadDataProps, Paginated, SortData} from '@Models';
import {useBreadcrumb} from '@Providers';
import {getCampaignsUseCase} from '@UseCase';
import {formatDate, formatNumber} from '@Utils';
import {
  Button,
  Card,
  Grid,
  Heading,
  Select,
  Tooltip,
} from '@hexa-ui/components';
import {MoreHorizontal, Plus} from '@hexa-ui/icons';
import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useFeatureFlag} from '@Hooks';
import useBaseCouponCampaignsFilter from './CouponCampaigns.filter';
import {useNavigate} from 'react-router-dom';

const CouponCampaigns: React.FC = () => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();

  const isRedirectToCreateCampaignEnabled = useFeatureFlag(
    'create_coupon_campaign'
  );

  const [baseFilter, baseSchema] = useBaseCouponCampaignsFilter();
  const [filter, setFilter] = useState(baseFilter);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortProp, setSortProp] = useState<SortData>({
    prop: 'status',
    direction: 'ASC',
  });
  const [loadingData, setLoadingData] = useState(false);
  const [campaigns, setCampaigns] = useState<Paginated<Campaign>>({
    content: [],
    pagination: {
      page: 0,
      pageSize: 0,
      totalElements: 0,
      totalPages: 0,
    },
  });

  const tableData = useMemo(() => {
    const getStatus = (status: string) => {
      if (status === 'ACTIVE') {
        return 'success';
      }

      if (status === 'SCHEDULED') {
        return 'info';
      }

      return 'error';
    };

    const getPocManagementValue = (campaignId: number, path: string) =>
      `/campaigns/${campaignId}/${path}`;
    const getEditValue = (campaignId: number) => `/campaigns/${campaignId}`;
    const getPromotionsValue = (campaignId: number) =>
      `/campaigns/${campaignId}/promotion-list`;

    const PocManagemenOptiontLabel = formatMessage({
      id: 'COUPON_CAMPAIGNS.TABLE.DROPDOWN.POC_MANAGEMENT',
    });
    const EditOptionLabel = formatMessage({
      id: 'COUPON_CAMPAIGNS.TABLE.DROPDOWN.EDIT_CAMPAIGN',
    });
    const promotionsOptionLabel = formatMessage({
      id: 'COUPON_CAMPAIGNS.TABLE.DROPDOWN.PROMOTIONS',
    });

    return {
      table: {
        columns: [
          {id: 'image', label: '', dimension: '5%'},
          {
            id: 'title',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.CAMPAIGN_NAME',
            }),
            dimension: '25%',
            sort: true,
          },
          {
            id: 'status',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.STATUS',
            }),
            dimension: '10%',
            sort: true,
          },
          {
            id: 'totalActivePromotions',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.ACTIVE_PROMOTIONS',
            }),
            dimension: '10%',
            sort: true,
          },
          {
            id: 'totalParticipantPocs',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.ACTIVE_POCS',
            }),
            dimension: '10%',
            sort: true,
          },
          {
            id: 'startDate',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.START_DATE',
            }),
            dimension: '15%',
            sort: true,
          },
          {
            id: 'endDate',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.END_DATE',
            }),
            dimension: '15%',
            sort: true,
          },
          {
            id: 'actions',
            label: formatMessage({
              id: 'COUPON_CAMPAIGNS.TABLE.ACTIONS',
            }),
            dimension: '10%',
          },
        ],
        initialSortBy: [{id: 'status', direction: 'ASC'}],
        pagination: {
          current: campaigns.pagination.page + 1,
          pageSize: 10,
          onChange: (page: number) => {
            loadCampaigns({page: page - 1, props: {filter, searchTerm}});
          },
          total: campaigns.pagination.totalElements,
        },

        data: campaigns.content.map((campaign, index) => ({
          image: <Thumbnail url={campaign.imageUrl} />,
          title: campaign.title,
          status: (
            <StatusDot
              status={getStatus(campaign.status)}
              text={formatMessage({
                id: `COUPON_CAMPAIGNS.TABLE.STATUSES.${campaign.status.toUpperCase()}`,
              })}
            />
          ),
          totalActivePromotions: formatNumber(
            campaign.totalActivePromotions || 0
          ),
          totalParticipantPocs: (
            <Tooltip
              text={formatMessage({
                id: 'COUPON_CAMPAIGNS.TABLE.TOOLTIPS.ELIGIBLE_POCS',
              })}
            >
              {formatNumber(campaign.totalParticipantPocs || 0)}
            </Tooltip>
          ),
          startDate: formatDate(campaign.startDate),
          endDate: formatDate(campaign.endDate),
          actions: (
            <Grid.Item data-testid={`select-root-${index}`}>
              <Select.Root
                shape="pill"
                size="small"
                placeholder=""
                icon={<MoreHorizontal />}
                onChange={eventValue => navigate(eventValue)}
              >
                <Grid.Item data-testid={`select-option-${index}`}>
                  <Select.Option
                    value={getPocManagementValue(
                      campaign.campaignId,
                      'eligible-pocs'
                    )}
                  >
                    {PocManagemenOptiontLabel}
                  </Select.Option>
                </Grid.Item>
                <Select.Option
                  data-testid="select-option-1"
                  value={getEditValue(campaign.campaignId)}
                >
                  {EditOptionLabel}
                </Select.Option>
                <Select.Option
                  data-testid="select-option-2"
                  value={getPromotionsValue(campaign.campaignId)}
                >
                  {promotionsOptionLabel}
                </Select.Option>
              </Select.Root>
            </Grid.Item>
          ),
        })),
      },
      loadingData,
      noDataTitle: formatMessage({
        id: 'COUPON_CAMPAIGNS.NO_DATA.TITLE',
      }),
      noDataDescription: formatMessage({
        id: 'COUPON_CAMPAIGNS.NO_DATA.DESCRIPTION',
      }),
    };
  }, [loadingData, campaigns, searchTerm]);

  useBreadcrumb([
    {
      name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
      active: true,
    },
  ]);

  useEffect(() => {
    loadCampaigns({});
  }, []);

  const handleCreateCouponCampaign = () => {
    if (isRedirectToCreateCampaignEnabled) {
      navigate('/campaigns/create');
    }
  };

  const loadCampaigns = async ({page = 0, props}: LoadDataProps) => {
    setLoadingData(true);
    setCampaigns({
      content: [],
      pagination: {
        page: 0,
        pageSize: 0,
        totalElements: 0,
        totalPages: 0,
      },
    });

    try {
      const sortOption = props?.sort || sortProp;
      const fetchCampaigns = await getCampaignsUseCase(page, {
        ...props,
        sort: sortOption,
      });
      setSortProp(sortOption);
      setCampaigns(fetchCampaigns);

      return fetchCampaigns;
    } catch {
      return undefined;
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <Grid.Container sidebar type="fluid" style={{margin: 0, width: '100%'}}>
      <Grid.Item xs={6}>
        <Heading size="H2">
          {formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'})}
        </Heading>
      </Grid.Item>
      <Grid.Item xs={6} style={{justifyContent: 'flex-end'}}>
        <Button
          data-testid="create-coupon-campaign"
          leading
          icon={Plus}
          onClick={handleCreateCouponCampaign}
        >
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE_COUPON_CAMPAIGN_BUTTON',
          })}
        </Button>
      </Grid.Item>

      <Grid.Item xs={12} style={{margin: '24px 0', overflow: 'visible'}}>
        <Card
          style={{width: '100%', overflow: 'visible'}}
          border="medium"
          elevated="medium"
        >
          <TableCard
            tableData={tableData}
            filter={filter}
            handleFilterChange={value => setFilter(value)}
            searchPlaceholder={formatMessage({
              id: 'COUPON_CAMPAIGNS.SEARCH_PLACEHOLDER',
            })}
            loadData={loadCampaigns}
            schema={baseSchema}
            onSearchTerm={term => {
              setSearchTerm(term);
            }}
          />
        </Card>
      </Grid.Item>
    </Grid.Container>
  );
};

export default CouponCampaigns;

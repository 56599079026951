import React, {memo, useEffect, useMemo, useState} from 'react';
import {Card, Grid, Heading, Paragraph, TextButton} from '@hexa-ui/components';
import {Download} from '@hexa-ui/icons';
import {
  CustomInput,
  CustomStyledUploader,
  CustomTextArea,
  PreviewCard,
} from '@Components';
import {useIntl} from 'react-intl';
import {getStorageURL, processFile} from '@Utils';
import {useFormikContext} from 'formik';

const CustomerSettings: React.FC = () => {
  const {values, setFieldValue} = useFormikContext<any>();
  const {formatMessage} = useIntl();

  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [imgError, setImgError] = useState(false);

  const beesPointsLabel = useMemo(() => {
    const points = values.promotions
      ? values.promotions
          .map((item: any) => item.beesPointsPerCoupon)
          .reduce(
            (accumulator: number, current: number) => accumulator + current,
            0
          )
      : undefined;
    return points
      ? formatMessage(
          {
            id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.UP_TO',
          },
          {points}
        )
      : formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.CAMPAIGN_PREVIEW.POINTS',
        });
  }, [values.promotions]);

  const validateDimensions = async (objectUrl: any) => {
    const imgData = await processFile(objectUrl);
    if (imgData.width !== 1920 && imgData.height !== 1080) {
      setImgError(true);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (values.imageFile) {
      const objectUrl = URL.createObjectURL(values.imageFile);
      setPreview(objectUrl);
    } else if (values.imageUrl) {
      setPreview(values.imageUrl);
    }
  }, [values.imageFile, values.imageUrl]);

  const handleCampaignImageValidation = async (
    file: File,
    callback: (uploadState: boolean) => void
  ) => {
    setImgError(false);
    const objectUrl = URL.createObjectURL(file);
    const validation = await validateDimensions(objectUrl);

    if (validation) {
      setFieldValue('imageFile', file);
      callback(true);
    } else {
      callback(false);
    }
  };

  const handleRemoveCampaignImage = () => {
    setImgError(false);
    setPreview(undefined);
    setFieldValue('imageFile', undefined);
  };

  const handleTemplateDownload = () => {
    const storageURL = getStorageURL();
    const templateUrl = `${storageURL}/templates/campaign/campaign_informative_image_template_1920_1080.png`;
    window.open(templateUrl, '_blank');
  };

  return (
    <Card
      border="medium"
      elevated="small"
      style={{width: '100%', padding: '2rem'}}
    >
      <Grid.Container type="fluid" style={{margin: 0}}>
        <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
          <Heading size="H3">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.TITLE',
            })}
          </Heading>
        </Grid.Item>
        <Grid.Item
          xs={8}
          style={{
            borderRight: '1px solid #DBDADA',
            paddingRight: '39px',
          }}
        >
          <Grid.Container type="fluid" style={{margin: 0, width: '100%'}}>
            <Grid.Item xs={12} style={{padding: 0}}>
              <CustomInput
                data-testid="campaign-name"
                name="campaignName"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.NAME.LABEL',
                })}
                hint={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.NAME.HINT',
                })}
                maxLength={30}
              />
            </Grid.Item>
            <Grid.Item
              xs={12}
              style={{flexDirection: 'column', gap: '8px', padding: 0}}
            >
              <CustomStyledUploader
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.IMAGE.LABEL',
                })}
                acceptedTypes="image/png,image/jpg,image/jpeg"
                type="img"
                maxSizeInMB={2}
                onChange={handleCampaignImageValidation}
                onRemove={handleRemoveCampaignImage}
                value={values.imageFile}
                placeholder={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.IMAGE.PLACEHOLDER',
                })}
                error={
                  imgError
                    ? formatMessage({
                        id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.IMAGE.INVALID_ERROR',
                      })
                    : undefined
                }
              />
              <TextButton
                data-testid="campaign-image-template"
                icon={Download}
                iconPosition="leading"
                size="small"
                onClick={handleTemplateDownload}
              >
                {formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.DOWNLOAD_TEMPLATE',
                })}
              </TextButton>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item
          xs={4}
          style={{paddingLeft: '39px', flexDirection: 'column', gap: '4px'}}
        >
          <Paragraph size="small" weight="semibold">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.CAMPAIGN_PREVIEW.TITLE',
            })}
          </Paragraph>
          <PreviewCard
            imgUrl={preview}
            name={values.campaignName}
            placeholder={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.CAMPAIGN_PREVIEW.NAME',
            })}
            badges={[
              {
                color: 'info',
                label: values.promotions?.length
                  ? `${values.promotions.length} ${formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.CAMPAIGN_PREVIEW.PROMO',
                    })}`
                  : formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.CAMPAIGN_PREVIEW.PROMOTIONS',
                    }),
              },
              {
                color: 'gray',
                label: beesPointsLabel,
              },
            ]}
          />
        </Grid.Item>

        <Grid.Item xs={12} style={{padding: 0, margin: '24px 0'}}>
          <CustomTextArea
            data-testid="campaign-img-alt-text"
            name="campaignImgAlt"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.ALT_TEXT.LABEL',
            })}
            optionalText={formatMessage({
              id: 'COMMON.FORM.OPTIONAL',
            })}
            maxLength={150}
            characterCounter
            required={false}
          />
        </Grid.Item>

        <Grid.Item xs={12} style={{padding: 0}}>
          <CustomTextArea
            data-testid="campaign-description"
            name="campaignDescription"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.DESCRIPTION.LABEL',
            })}
            optionalText={formatMessage({
              id: 'COMMON.FORM.OPTIONAL',
            })}
            maxLength={150}
            characterCounter
            required={false}
          />
        </Grid.Item>
      </Grid.Container>
    </Card>
  );
};

export default memo(CustomerSettings);

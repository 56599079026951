const gcd = (a: number, b: number) => {
  if (b > a) {
    const temp = a;
    a = b;
    b = temp;
  }
  while (b !== 0) {
    const m = a % b;
    a = b;
    b = m;
  }
  return a;
};

const getAspectRatio = (width: number, height: number) => {
  const c = gcd(width, height);
  return width / c + ':' + height / c;
};

export default getAspectRatio;

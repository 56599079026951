import {useCurrentLocale} from '@Hooks';
import moment from 'moment';

function getDateString(locale: string, time: boolean): string {
  switch (locale) {
    case 'en':
      return `MMM DD, YYYY${time ? ' h:mma' : ''}`;
    case 'es':
      return `DD [de] MMM [de] YYYY${time ? ' HH:mm' : ''}`;
    case 'pt':
      return `DD [de] MMM [de] YYYY${time ? ' HH[h]mm' : ''}`;
    default:
      return `MMM DD, YYYY${time ? ' HH:mm' : ''}`;
  }
}

function normalized(text: string) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

function formatDate(value: Date | string | undefined, time = false) {
  if (!value) {
    return '';
  }

  const locale = useCurrentLocale();
  const date = new Date(value);

  const dateString = getDateString(locale, time);
  return normalized(
    moment(date).locale(locale).format(dateString).replaceAll('.', '')
  );
}

export default formatDate;

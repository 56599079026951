import {createCampaignService} from '@Services';
import {CreateCampaignProps} from '@Models';
import {objectToFormData} from '@Utils';

function convertToFormData(obj: Record<string, any>): FormData {
  const {template, adSettings, ...rest} = obj;

  if (
    template?.ageGate?.mobileBackgroundFile &&
    template?.ageGate?.desktopBackgroundFile
  ) {
    template.ageGate.mobileBackgroundFile =
      template.ageGate.mobileBackgroundFile[0].file;
    template.ageGate.desktopBackgroundFile =
      template.ageGate.desktopBackgroundFile[0].file;
  }

  if (template?.registrationBannerFile) {
    template.registrationBannerFile = template.registrationBannerFile[0].file;
  }

  if (adSettings?.dtcBannerFile) {
    adSettings.dtcBannerFile = adSettings.dtcBannerFile[0].file;
  }

  const formData = new FormData();
  objectToFormData({...rest, template, adSettings}, formData);

  return formData;
}

export default async function createCampaignUseCase(
  campaign: CreateCampaignProps
) {
  const formData = convertToFormData(campaign);
  const response = await createCampaignService(formData);

  if (response.hasError) {
    throw new Error(response.response.status);
  }
  return response.data;
}

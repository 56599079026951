import {EligiblePocsSummary} from '@Models';
import {getEligiblePocsSummaryService} from '@Services';

async function getEligiblePocsSummaryUseCase(
  campaignId: string
): Promise<EligiblePocsSummary> {
  const response = await getEligiblePocsSummaryService(campaignId);

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default getEligiblePocsSummaryUseCase;

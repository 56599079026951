import {
  Button,
  Grid,
  Heading,
  ProgressTracker,
  TextLink,
  useProgressTracker,
} from '@hexa-ui/components';
import React, {useMemo, useState} from 'react';
import {useBreadcrumb, useToast} from '@Providers';
import {
  CancelActionModal,
  CreateCampaignStepFive,
  CreateCampaignStepFour,
  CreateCampaignStepOne,
  CreateCampaignStepThree,
  CreateCampaignStepTwo,
  Footer,
} from '@Components';
import {validateLandingPageUrlUseCase} from '@UseCase';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {CreateCampaignProps} from '../../models';
import {createCampaignUseCase} from '../../usecase';

const CreateCampaign: React.FC<{defaultValid?: boolean}> = ({defaultValid}) => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {toast} = useToast();

  const [campaignData, setCampaignData] = useState<CreateCampaignProps>();
  const [hasError, setHasError] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const {currentStep, goToNext, goToPrevious, goTo} = useProgressTracker({
    initialStep: 0,
    stepQuantity: 5,
  });
  const [isCreating, setIsCreating] = useState(false);

  const steps = useMemo(() => {
    const options = [
      'CAMPAIGN',
      'PROMOTIONS',
      'LEGAL_INFO',
      'URL_AND_ADS',
      'SUMMARY',
    ];

    return options.map(item => ({
      title: formatMessage({
        id: `COUPON_CAMPAIGNS.CREATE.STEPS.${item}.TITLE`,
      }),
      description: formatMessage({
        id: `COUPON_CAMPAIGNS.CREATE.STEPS.${item}.DESCRIPTION`,
      }),
    }));
  }, []);

  useBreadcrumb([
    {
      name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
      href: '/campaigns',
    },
    {
      name: formatMessage({id: 'COUPON_CAMPAIGNS.CREATE.TITLE'}),
      active: true,
    },
  ]);

  const handleChange = (values: any, isValid: boolean) => {
    setHasError(!isValid && !defaultValid);

    setCampaignData({...campaignData, ...values});
  };

  const scrollAndGoToNext = () => {
    goToNext();
    setTimeout(
      () => window.scrollTo({top: 0, left: 0, behavior: 'smooth'}),
      200
    );
  };

  const validateAndGoToNext = () => {
    if (currentStep === 3) {
      validateLandingPageUrlUseCase(campaignData?.landingPageUrl ?? '').then(
        response => {
          if (response.content.length > 0) {
            setHasError(true);
          } else {
            scrollAndGoToNext();
          }
        }
      );
    } else {
      scrollAndGoToNext();
    }
  };

  const currentStepComponent = useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <CreateCampaignStepOne
            initialValues={campaignData}
            onChange={handleChange}
          />
        );
      case 1:
        return (
          <CreateCampaignStepTwo
            initialValues={campaignData}
            onChange={handleChange}
            isEditing
          />
        );
      case 2:
        return (
          <CreateCampaignStepThree
            initialValues={campaignData}
            onChange={handleChange}
          />
        );
      case 3:
        return (
          <CreateCampaignStepFour
            initialValues={campaignData}
            onChange={handleChange}
          />
        );
      case 4:
        return (
          <CreateCampaignStepFive
            initialValues={campaignData}
            onChange={handleChange}
            goTo={goTo}
          />
        );
      default:
        return <></>;
    }
  }, [currentStep]);

  const openCancelModal = () => {
    setCancelModalOpen(true);
  };

  const cancelCreation = () => {
    setCancelModalOpen(false);
    navigateToCouponCampaignsList();
  };

  const navigateToCouponCampaignsList = () => {
    navigate('/campaigns');
  };

  const createCampaign = () => {
    setIsCreating(true);
    createCampaignUseCase(campaignData as CreateCampaignProps)
      .then(() => {
        toast({
          message: formatMessage(
            {id: 'COUPON_CAMPAIGNS.CREATE.SUCCESS_MESSAGE'},
            {
              campaignName: campaignData?.campaignName,
            }
          ),
          type: 'success',
        });
        navigateToCouponCampaignsList();
      })
      .catch(() => {
        toast({
          message: formatMessage({
            id: 'COMMON.ERRORS.DEFAULT',
          }),
          type: 'error',
        });
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return (
    <Grid.Container
      sidebar
      type="fluid"
      style={{
        margin: 0,
        paddingBottom: '150px',
      }}
    >
      <CancelActionModal
        title={formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.DISCARD_MODAL.TITLE',
        })}
        description={formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.DISCARD_MODAL.DESCRIPTION',
        })}
        confirmTitle={formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.DISCARD_MODAL.DISCARD',
        })}
        isOpen={cancelModalOpen}
        setIsOpen={setCancelModalOpen}
        onCancel={cancelCreation}
      />
      <Grid.Item xs={6} style={{marginBottom: '40px'}}>
        <Heading size="H2">
          {formatMessage({id: 'COUPON_CAMPAIGNS.CREATE.TITLE'})}
        </Heading>
      </Grid.Item>
      <Grid.Container
        type="fluid"
        style={{
          justifyContent: 'center',
          width: '100%',
          margin: 0,
        }}
      >
        <Grid.Item xs={10}>
          <ProgressTracker.Root currentStep={currentStep}>
            <ProgressTracker.Header>
              {steps.map((item, index) => (
                <ProgressTracker.Step
                  index={index}
                  key={index}
                  completed={index < currentStep}
                >
                  <ProgressTracker.StepLabel
                    label={item.title}
                    detail={item.description}
                  />
                </ProgressTracker.Step>
              ))}
            </ProgressTracker.Header>
          </ProgressTracker.Root>
        </Grid.Item>
      </Grid.Container>
      <Grid.Item xs={12} style={{marginTop: '32px'}}>
        {currentStepComponent}
      </Grid.Item>

      <Footer>
        <Grid.Container
          sidebar
          type="fluid"
          style={{
            border: '1px solid #EEE',
            background: '#FFF',
            borderRadius: '1000px',
            padding: '24px',
            marginLeft: '6rem',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <Grid.Item xs={6}>
            <TextLink onClick={openCancelModal} data-testid="discard">
              {formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.DISCARD_MODAL.DISCARD',
              })}
            </TextLink>
          </Grid.Item>
          <Grid.Item xs={6} style={{justifyContent: 'flex-end', gap: '1rem'}}>
            <Button
              variant="secondary"
              data-testid="back"
              disabled={currentStep === 0}
              onClick={goToPrevious}
            >
              {formatMessage({id: 'COMMON.BUTTONS.GO_BACK'})}
            </Button>
            {currentStep !== 4 && (
              <Button
                data-testid="continue"
                disabled={hasError}
                onClick={validateAndGoToNext}
              >
                {formatMessage({id: 'COMMON.BUTTONS.CONTINUE'})}
              </Button>
            )}
            {currentStep === 4 && (
              <Button
                data-testid="create"
                onClick={createCampaign}
                isLoading={isCreating}
              >
                {formatMessage({id: 'COUPON_CAMPAIGNS.CREATE.BUTTON'})}
              </Button>
            )}
          </Grid.Item>
        </Grid.Container>
      </Footer>
    </Grid.Container>
  );
};

export default CreateCampaign;

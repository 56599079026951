import api from '../../api';

export default async function uploadPocsService(
  campaignId: number,
  form: FormData,
  promotionId: number
): Promise<any> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return await api.post({
    url: `/v1/admin/campaigns/${campaignId}/promotions/${promotionId}/accounts`,
    body: form,
    config,
  });
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input::-webkit-inner-spin-button,
.custom-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
.custom-input {
  -moz-appearance: textfield; /* Firefox */
}`, "",{"version":3,"sources":["webpack://./src/components/Form/CustomInput/CustomInput.styles.css"],"names":[],"mappings":"AAAA;;EAEE,wBAAwB;EACxB,SAAS;EACT,aAAa;AACf;AACA;EACE,0BAA0B,EAAE,YAAY;AAC1C","sourcesContent":[".custom-input::-webkit-inner-spin-button,\n.custom-input::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n  display: none;\n}\n.custom-input {\n  -moz-appearance: textfield; /* Firefox */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {editCampaignService} from '@Services';
import {CreateCampaignProps} from '@Models';
import {objectToFormData} from '@Utils';

function convertToFormData(values: CreateCampaignProps): FormData {
  const formData = new FormData();

  if (values.campaignRulesUrl && values.campaignRules) {
    delete values.campaignRulesUrl;
  }

  objectToFormData(values, formData);

  return formData;
}

export default async function updateCampaignLegalInfoUseCase(
  campaign: CreateCampaignProps
) {
  const formData = convertToFormData(campaign);
  const response = await editCampaignService(+campaign.campaignId!, formData);
  if (response.hasError) {
    throw new Error(response.response.status);
  }
  return response.data;
}

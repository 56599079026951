import {OptionsProps} from '@Models';
import {useMemo} from 'react';

interface TableHeaderProps {
  options: OptionsProps[];
}

export default function useTableHeader({options}: TableHeaderProps) {
  return useMemo(() => {
    return options.map(item => ({
      Header: item.label,
      accessor: item.id,
      disableSortBy: item.sort ? false : true,
      style: {
        width: item.dimension,
      },
    }));
  }, []);
}

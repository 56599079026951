import {Alert, Grid, Heading, InputWrapper, Radio} from '@hexa-ui/components';
import {useFormikContext} from 'formik';
import React, {useEffect} from 'react';
import {CustomNumberInput, CustomInput} from '@Components';
import {CurrencyInput} from '@martech/components';
import {useIntl} from 'react-intl';

interface CouponSettingsProps {
  promotionIndex: number;
  hasLimitPerConsumer?: boolean;
}

const CouponSettings: React.FC<CouponSettingsProps> = ({
  promotionIndex,
  hasLimitPerConsumer,
}) => {
  const {formatMessage} = useIntl();
  const {values, setFieldValue, setFieldTouched, touched, errors} =
    useFormikContext<any>();

  useEffect(() => {
    const {promotions} = values;
    const {couponType, pricePerUnit, totalUnits, discount, freeUnits} =
      promotions[promotionIndex];

    if (
      couponType?.toLowerCase() === 'discount' &&
      pricePerUnit &&
      totalUnits &&
      discount
    ) {
      const couponDiscount = Number(discount) / 100;
      const total = pricePerUnit * totalUnits * couponDiscount;
      setFieldValue(
        `promotions.${promotionIndex}.couponPrice`,
        total.toFixed(2)
      );
    }

    if (couponType === 'freeUnits' && pricePerUnit && freeUnits) {
      const total = pricePerUnit * freeUnits;
      setFieldValue(
        `promotions.${promotionIndex}.couponPrice`,
        total.toFixed(2)
      );
    }
  }, [values.promotions[promotionIndex]]);

  return (
    <Grid.Container
      type="fluid"
      style={{
        border: '1px solid #DBDADA',
        borderRadius: '16px',
        margin: 0,
        padding: '1rem',
        alignItems: 'self-end',
      }}
    >
      <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
        <Heading size="H4">
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.TITLE',
          })}
        </Heading>
      </Grid.Item>

      <Grid.Item xs={12}>
        <InputWrapper
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_TYPE',
          })}
          width="100%"
        >
          <Radio.Root
            onValueChange={value =>
              setFieldValue(`promotions.${promotionIndex}.couponType`, value)
            }
            style={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            <React.Fragment key=".0">
              <Radio.Item
                data-testid="discount-opt"
                id={`promotion-${promotionIndex}-discount`}
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.DISCOUNT',
                })}
                value="discount"
                checked={
                  values.promotions[
                    promotionIndex
                  ].couponType?.toLowerCase() === 'discount'
                }
              />
              <Radio.Item
                data-testid="free-good-opt"
                id={`promotion-${promotionIndex}-freeUnits`}
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.FREE_GOOD',
                })}
                value="freeUnits"
                checked={
                  values.promotions[promotionIndex].couponType === 'freeUnits'
                }
              />
            </React.Fragment>
          </Radio.Root>
        </InputWrapper>
      </Grid.Item>

      {values.promotions[promotionIndex].couponType?.toLowerCase() ===
        'discount' && (
        <Grid.Item xs={4} style={{marginBottom: '1rem'}}>
          <CustomNumberInput
            data-testid="discount"
            name={`promotions.${promotionIndex}.discount`}
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.DISCOUNT_TITLE',
            })}
            decimal
          />
        </Grid.Item>
      )}

      {values.promotions[promotionIndex].couponType === 'freeUnits' && (
        <Grid.Item xs={4} style={{marginBottom: '1rem'}}>
          <CustomNumberInput
            data-testid="free-good"
            name={`promotions.${promotionIndex}.freeUnits`}
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.FREE_GOOD_TITLE',
            })}
          />
        </Grid.Item>
      )}

      <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
        <Heading size="H4">
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_AMOUNTS.TITLE',
          })}
        </Heading>
      </Grid.Item>

      <Grid.Item xs={4}>
        <CustomNumberInput
          data-testid="total-gen"
          name={`promotions.${promotionIndex}.totalAmountCoupons`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_AMOUNTS.TOTAL_GEN',
          })}
        />
      </Grid.Item>
      <Grid.Item xs={4}>
        <CustomNumberInput
          data-testid="consumer-gen"
          name={`promotions.${promotionIndex}.totalCouponsPerUser`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_AMOUNTS.CONSUMER_GEN',
          })}
        />
      </Grid.Item>
      <Grid.Item xs={4}>
        <CustomNumberInput
          data-testid="consumer-active-coupons"
          name={`promotions.${promotionIndex}.maxActiveCouponPerUser`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_AMOUNTS.CONSUMER_ACTIVE_COUPONS',
          })}
        />
      </Grid.Item>

      {hasLimitPerConsumer && (
        <Grid.Item style={{marginTop: '1.5rem'}} xs={12}>
          <Alert
            type="info"
            message={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_AMOUNTS.COUPON_AMOUNT_ALERT',
            })}
            style={{width: '100%'}}
          />
        </Grid.Item>
      )}

      <Grid.Item xs={12} style={{margin: '1rem 0'}}>
        <Heading size="H4">
          {formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_VALUATION.TITLE',
          })}
        </Heading>
      </Grid.Item>

      <Grid.Item xs={4}>
        <CustomNumberInput
          data-testid="points-per-coupon"
          name={`promotions.${promotionIndex}.beesPointsPerCoupon`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_VALUATION.POINTS_PER_COUPON',
          })}
        />
      </Grid.Item>
      <Grid.Item xs={4}>
        <CurrencyInput
          testId="coupon-price"
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_VALUATION.COUPON_PRICE',
          })}
          currency={{
            locale: 'en-US',
            currency: 'USD',
            prefix: {
              actual: '$',
              preferred: '$',
            },
          }}
          value={values.promotions[promotionIndex].couponPrice}
          onChange={value => {
            setFieldTouched(`promotions.${promotionIndex}.couponPrice`, true);
            setFieldValue(`promotions.${promotionIndex}.couponPrice`, value);
          }}
          hasError={
            !!(touched.promotions as any)?.[promotionIndex]?.couponPrice &&
            !!(errors.promotions as any)?.[promotionIndex]?.couponPrice
          }
          errorText={
            (errors.promotions as any)?.[promotionIndex]?.couponPrice as string
          }
        />
      </Grid.Item>

      <Grid.Item xs={12} style={{marginTop: '1rem'}}>
        <CustomInput
          data-testid="success-message"
          name={`promotions.${promotionIndex}.campaignSuccessMessage`}
          label={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_VALUATION.SUCCESS_MESSAGE.TITLE',
          })}
          hint={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_VALUATION.SUCCESS_MESSAGE.HINT',
          })}
          maxLength={150}
        />
      </Grid.Item>
    </Grid.Container>
  );
};

export default CouponSettings;

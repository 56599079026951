import {addPocsService} from '@Services';

export default async function addPocsUseCase(uploadId: string, request: any) {
  const response = await addPocsService(uploadId, request);

  if (response.hasError) {
    throw new Error(response.response.status);
  }
  return response.data;
}

import {editCampaignService} from '@Services';
import {CreateCampaignProps} from '@Models';
import {objectToFormData} from '@Utils';
import _ from 'lodash';

function convertToFormData(values: CreateCampaignProps): FormData {
  const formData = new FormData();

  const deleteIfFileExists = (key: string, fileKey: string) => {
    if (_.get(values, key) && _.get(values, fileKey)) {
      _.unset(values, key);
    }
  };

  deleteIfFileExists('imageUrl', 'imageFile');
  deleteIfFileExists('template.logo', 'template.logoFile');
  deleteIfFileExists(
    'template.registrationBanner',
    'template.registrationBannerFile'
  );
  deleteIfFileExists('template.customPin', 'template.customPinFile');
  deleteIfFileExists(
    'template.ageGate.mobileBackground',
    'template.ageGate.mobileBackgroundFile'
  );
  deleteIfFileExists(
    'template.ageGate.desktopBackground',
    'template.ageGate.desktopBackgroundFile'
  );

  const mobileBackgroundFile =
    values.template?.ageGate?.mobileBackgroundFile?.[0]?.file ?? null;
  const desktopBackgroundFile =
    values.template?.ageGate?.desktopBackgroundFile?.[0]?.file ?? null;
  const registrationBannerFile =
    values.template?.registrationBannerFile?.[0]?.file ?? null;

  objectToFormData(
    {
      ...values,
      template: {
        ...values.template,
        registrationBannerFile,
        ageGate: {
          ...values.template?.ageGate,
          mobileBackgroundFile,
          desktopBackgroundFile,
        },
      },
    },
    formData
  );

  return formData;
}

export default async function updateCampaignInCampaignUseCase(
  campaign: CreateCampaignProps
) {
  const formData = convertToFormData(campaign);
  const response = await editCampaignService(+campaign.campaignId!, formData);

  if (response.hasError) {
    throw new Error(response.response.status);
  }
  return response.data;
}

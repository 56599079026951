import MessageMap from '../../i18n';

const promotionList: MessageMap = {
  TITLE: 'Promotions for {title}',
  SEARCH_PLACEHOLDER: 'Search by promotion name',
  TABLE: {
    PROMOTION_NAME: 'Promotion Name',
    PARTICIPATING_POCS: 'Participating POCs',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    ACTIONS: 'Actions',
  },
  NO_DATA: {
    TITLE: 'You have no promotions',
    DESCRIPTION: 'Add a new one by clicking on "Add promotion”.',
  },
  FILTERS: {
    DATES: {
      TITLE: 'Promotion dates',
      START: 'Start date',
      END: 'End date',
    },
    INVALID_DATE: 'Choose a date after {startDate}.',
  },
  BREADCRUMB_PROMOTION_LIST: 'Promotions',
};

export default promotionList;

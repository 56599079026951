// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .dragging {
    opacity: 0.4; 
    transition: all 0.1s ease;
  }`, "",{"version":3,"sources":["webpack://./src/components/CustomDragAndDrop/CustomDragAndDrop.css"],"names":[],"mappings":"CAAC;IACG,YAAY;IACZ,yBAAyB;EAC3B","sourcesContent":[" .dragging {\n    opacity: 0.4; \n    transition: all 0.1s ease;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {styled} from '@stitches/react';

export const StatusWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Dot = styled('div', {
  height: '8px',
  width: '8px',
  borderRadius: '50%',
  marginRight: '8px',
  variants: {
    status: {
      error: {
        backgroundColor: '#E5423F',
      },
      success: {
        backgroundColor: '#1F8E4A',
      },
      warning: {
        backgroundColor: '#FFB042',
      },
      info: {
        backgroundColor: '#047AF1',
      },
    },
  },
});

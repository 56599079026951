import MessageMap from '../../i18n';

const common: MessageMap = {
  SIDEBAR: {
    COUPON_CAMPAIGN: 'Coupon campaign',
  },
  FILTER: {
    DROPDOWN: 'Filters',
    APPLY: 'Apply',
    CANCEL: 'Cancel',
    CLEAR_ALL: 'Clear all',
  },
  TABLE: {
    OF: 'of',
    LOADING: 'Loading',
    LOADING_SEARCH: 'Loading search results',
    EMPTY_RESULTS: 'No results found',
    EMPTY_SEARCH: 'Try a new search using different keywords.',
    EMPTY_FILTER: 'Try using different filters',
  },
  BUTTONS: {
    GO_BACK: 'Go back',
    CANCEL: 'Cancel',
    YES_CANCEL: 'Yes, cancel',
    CONFIRM: 'Confirm',
    CONTINUE: 'Continue',
    SAVE: 'Save changes',
    SELECT_FILE: 'Select file',
    DOWNLOAD_TEMPLATE: 'Download template',
    NO_GO_BACK: 'No, go back',
    SELECT: 'Select',
    DELETE: 'Discard',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    DISABLE: 'Disable',
    OK: 'Ok',
  },
  FORM: {
    OPTIONAL: '(Optional)',
    REQUIRED: 'Required field.',
  },
  ERRORS: {
    DEFAULT: 'Something went wrong. Try again later.',
    UPLOAD: 'Upload failed.',
    MAX_SIZE: 'The file exceeded {size}MB.',
    TYPE_ERROR: 'File type not supported.',
    INVALID_URL: 'Enter a valid URL.',
    FIELD_MIN: 'Field must be at least {min} characters.',
    SPECIAL_INVALID: 'Spaces and special characters are not supported.',
    NUMBER_MIN: 'Value must be greater than {min}.',
    NUMBER_MAX: 'Value must be less or equal to {max}.',
    INVALID_FIELD: 'Invalid field.',
    MIN_DATE: 'Choose a date from {date}.',
    MAX_DATE: 'Choose a date until {date}.',
    INVALID_DATE: 'Choose a valid date.',
  },
  COMPLEMENTS: {
    POINTS: 'points',
  },
};

export default common;

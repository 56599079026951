import {validateLandingPageUrlService} from '@Services';

async function validateLandingPageUrlUseCase(
  landingPageUrl: string,
  campaignId?: string
): Promise<any> {
  const response = await validateLandingPageUrlService(
    landingPageUrl.toLowerCase(),
    campaignId
  );

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default validateLandingPageUrlUseCase;

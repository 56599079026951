import {POC, ListRequestProps, Paginated} from '@Models';
import {parseFilterStructure} from '@martech/utils';
import api from '../../api';

export default async function getEligiblePocsService(
  campaignId: number,
  pageNumber: number,
  props: ListRequestProps
): Promise<Paginated<POC> | any> {
  const {searchTerm, filter, sort} = props;
  const searchString = searchTerm ? `&queryString=${searchTerm}` : '';
  const filterString = parseFilterStructure(filter);
  const sortString = sort?.prop
    ? `&sortField=${sort.prop}&sortDirection=${sort.direction}`
    : '';

  return await api.get({
    url: `/v1/admin/campaigns/${campaignId}/eligible-pocs?page=${pageNumber}${sortString}${searchString}${filterString}`,
  });
}

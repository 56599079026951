import {useMemo, useState} from 'react';
import Axios from 'axios';
import {Card, Grid, Heading, Paragraph, Radio} from '@hexa-ui/components';
import {useIntl} from 'react-intl';
import {useBreadcrumb, useToast} from '@Providers';
import {bulkManagePocsUseCase} from '@UseCase';
import {BulkManageActionTypes} from '@Models';
import {StyledFileUploader} from '@martech/components';
import {openBlob} from '@martech/utils';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {countCsvLines, getStorageURL} from '@Utils';
import {useAuthenticationService} from 'admin-portal-shared-services';
import {ButtonsBackSubmit, CustomCardFileUploader} from '@Components';

type ProcessStatus = 'waiting' | 'loading' | 'processing' | 'done' | 'error';

export const MAX_POCS_UPLOAD = 3000;
export const MAX_POCS_ENABLE_DISABLE_DESCRIPTION = 3;

const radioOptions = [
  {
    actionType: BulkManageActionTypes.BLOCK_FRAUD_PREVENTION,
    labelId: 'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.BLOCK_FRAUD_PREVENTION_ACTION',
    descriptionId:
      'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.BLOCK_FRAUD_PREVENTION_DESCRIPTION',
  },
  {
    actionType: BulkManageActionTypes.UNBLOCK_FRAUD_PREVENTION,
    labelId:
      'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.UNBLOCK_FRAUD_PREVENTION_ACTION',
    descriptionId:
      'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.UNBLOCK_FRAUD_PREVENTION_DESCRIPTION',
    descriptionIdTwo:
      'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.UNBLOCK_FRAUD_PREVENTION_DESCRIPTION_TWO',
  },
  {
    actionType: BulkManageActionTypes.DISABLE_PARTICIPATION,
    labelId: 'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.DISABLE_PARTICIPATION_ACTION',
    descriptionId:
      'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.DISABLE_PARTICIPATION_DESCRIPTION',
  },
  {
    actionType: BulkManageActionTypes.ENABLE_PARTICIPATION,
    labelId: 'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.ENABLE_PARTICIPATION_ACTION',
    descriptionId:
      'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.ENABLE_PARTICIPATION_DESCRIPTION',
  },
];

export default function BulkManagePocs() {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {toast} = useToast();
  const location = useLocation();
  const authService = useAuthenticationService();

  const {campaignId} = useParams();
  const [file, setFile] = useState<File>();
  const [status, setStatus] = useState<ProcessStatus>('done');
  const [csvLines, setCsvLines] = useState(0);
  const [validatingFile, setValidatingFile] = useState(false);
  const [addingFile, setAddingFile] = useState(false);
  const [onCancel, setOnCancel] = useState<any>();
  const [fileError, setFileError] = useState('');
  const [bulkManageActionType, setBulkManageActionType] = useState('');
  const [selectedCard, setSelectedCard] = useState('');

  const campaignData = useMemo(() => {
    return location.state;
  }, [location.state]);

  useBreadcrumb([
    {
      name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
      href: '/campaigns',
    },
    {
      name: campaignData.campaignName,
      href: `/campaigns/${campaignId}/eligible-pocs`,
    },
    {
      name: formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.ELIGIBLE_POCS'}),
      href: `/campaigns/${campaignId}/eligible-pocs`,
    },
    {
      name: formatMessage({
        id: 'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.BREADCRUMB',
      }),
      active: true,
    },
  ]);

  const handlePocBulkManageValidation = (file: File) => {
    const handleFailure = () => {
      setStatus('error');
      setValidatingFile(false);
    };

    setFileError('');
    setFile(file);
    setStatus('loading');
    setValidatingFile(true);

    countCsvLines(file)
      .then(lines => {
        const actualLines = lines - 4;
        setCsvLines(actualLines);

        if (actualLines > MAX_POCS_UPLOAD || actualLines < 1) {
          setFileError('INVALID');
          handleFailure();
        } else {
          setStatus('done');
        }
      })
      .catch(() => {
        handleFailure();
        setFileError('TYPE');
      });
  };

  const showToast = (
    type: 'warning' | 'success',
    value: number,
    keyBulkManageActionType: string
  ) => {
    toast({
      message: formatMessage(
        {
          id: `CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.${type === 'warning' ? 'ERROR' : 'SUCCESS'}_MESSAGE_${keyBulkManageActionType}`,
        },
        {
          value: value,
          campaignName: campaignData.campaignName ?? '',
        }
      ),
      type: type,
    });
  };

  const managePocs = () => {
    const request = Axios.CancelToken.source();

    setOnCancel(request);
    setAddingFile(true);

    bulkManagePocsUseCase(file!, Number(campaignId), bulkManageActionType)
      .then(resPocsList => {
        const numberInconsistentPocs = resPocsList.split('\n').length - 2;
        const numberSuccessPocs =
          csvLines - (numberInconsistentPocs > 0 ? numberInconsistentPocs : 0);
        const keyBulkManageActionType =
          getKeyByValue(BulkManageActionTypes, bulkManageActionType) ?? '';

        if (numberInconsistentPocs > 0) {
          showToast('warning', numberInconsistentPocs, keyBulkManageActionType);
          openFileInBrowser(resPocsList);
        }

        if (numberSuccessPocs > 0) {
          const delay = numberInconsistentPocs > 0 ? 1000 : 0;
          setTimeout(
            () =>
              showToast('success', numberSuccessPocs, keyBulkManageActionType),
            delay
          );
        }

        navigateToEligiblePocsList();
      })
      .catch(() => {
        toast({
          message: formatMessage({
            id: 'COMMON.ERRORS.DEFAULT',
          }),
          type: 'error',
        });
      })
      .finally(() => {
        setAddingFile(false);
      });
  };

  const openFileInBrowser = (response: any) => {
    const datetime = new Date().toLocaleString().split(', ');
    const date = datetime[0].replaceAll('/', '-');
    const time = datetime[1].replaceAll(':', '-').replace(' ', '');

    openBlob(response, `pocs_upload_inconsistencies_${date}_${time}.csv`);
  };

  const handleCancel = () => {
    onCancel?.cancel();
    navigateToEligiblePocsList();
  };

  const handleTemplatePocsDownload = () => {
    const storageURL = getStorageURL();
    const country = authService.getCountryB2C().toLowerCase();

    const templateUrl = `${storageURL}/templates/${country}/POC_Upload_template.csv`;
    window.open(templateUrl, '_blank');
  };

  const navigateToEligiblePocsList = () => {
    navigate(`/campaigns/${campaignId}/eligible-pocs`);
  };

  function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find(key => object[key] === value);
  }

  return (
    <Grid.Container
      sidebar
      data-testid="bulk-manage-pocs-page"
      type="fluid"
      style={{margin: 0, width: '100%', justifyContent: 'center'}}
    >
      <Grid.Item xs={12}>
        <Heading data-testid="bulk-manage-pocs-title" size="H2">
          {formatMessage(
            {id: 'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.TITLE'},
            {title: campaignData.campaignName}
          )}
        </Heading>
      </Grid.Item>

      <Grid.Item
        xs={9}
        style={{margin: '2rem auto 0', flexDirection: 'column'}}
      >
        <Grid.Item xs={12}>
          <Heading
            data-testid="select-bulk-action-title"
            size="H4"
            style={{marginBottom: '24px'}}
          >
            {formatMessage(
              {id: 'CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.SELECT_BULK_ACTION'},
              {title: campaignData.campaignName}
            )}
          </Heading>
        </Grid.Item>

        <Radio.Root onValueChange={value => setBulkManageActionType(value)}>
          <Grid.Item
            xs={9}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            {radioOptions.map(
              ({actionType, labelId, descriptionId, descriptionIdTwo}) => (
                <Card
                  key={actionType}
                  style={{
                    padding: '20px 20px 20px 15px',
                    width: '410px',
                    height: '190px',
                    border:
                      selectedCard === actionType ? '2px solid black' : 'none',
                    marginBottom: '16px',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  border="small"
                  elevated="small"
                  onClick={() => {
                    setSelectedCard(actionType);
                    setBulkManageActionType(actionType);
                  }}
                >
                  <Grid.Container
                    type="fluid"
                    style={{
                      margin: 0,
                    }}
                  >
                    <Grid.Item xs={12}>
                      <Radio.Item
                        id={`${actionType}-action`}
                        data-testid={`${actionType}-action`}
                        label={''}
                        value={actionType}
                        checked={selectedCard === actionType}
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedCard(actionType);
                        }}
                      />
                      <Grid.Container type="fluid" style={{margin: 0}}>
                        <Paragraph
                          data-testid={`${actionType}-title`}
                          size="basis"
                          colortype="primary"
                          style={{
                            fontWeight:
                              selectedCard === actionType ? '500' : 'normal',
                          }}
                        >
                          {formatMessage({id: labelId})}
                        </Paragraph>

                        <Paragraph
                          data-testid={`${actionType}-description`}
                          size="xsmall"
                          colortype="secondary"
                        >
                          {formatMessage({id: descriptionId})}
                        </Paragraph>

                        {descriptionIdTwo && (
                          <Paragraph
                            data-testid={`${actionType}-description-two`}
                            size="xsmall"
                            colortype="error"
                            style={{marginTop: '0.5rem'}}
                          >
                            {formatMessage({id: descriptionIdTwo})}
                          </Paragraph>
                        )}
                      </Grid.Container>
                    </Grid.Item>
                  </Grid.Container>
                </Card>
              )
            )}
          </Grid.Item>
        </Radio.Root>
      </Grid.Item>

      {bulkManageActionType && (
        <>
          <CustomCardFileUploader
            handleTemplateDownload={handleTemplatePocsDownload}
            isDisabledDownloadTemplate={addingFile}
            textIdDescriptionOne="CAMPAIGN_ELIGIBLE_POCS.ADD.FILE_DESCRIPTION_THREE"
            textIdDescriptionTwo="CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.POC_UPLOAD_LIMIT"
            valueUploadLimit={MAX_POCS_ENABLE_DISABLE_DESCRIPTION}
          >
            <StyledFileUploader
              onChange={handlePocBulkManageValidation}
              isLoading={status === 'loading'}
              handleRemove={() => {
                setFile(undefined);
                setFileError('');
              }}
              acceptedType=".csv"
              placeholder={formatMessage({
                id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.DROP_PLACEHOLDER',
              })}
              buttonText={formatMessage({
                id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.BROWSE_FILE',
              })}
              isDisabled={addingFile || validatingFile}
              errorMessage={
                fileError
                  ? formatMessage({
                      id: `CAMPAIGN_ELIGIBLE_POCS.ADD.${fileError}_ERROR`,
                    })
                  : undefined
              }
              iconType="doc"
              value={file}
            />
          </CustomCardFileUploader>

          <Grid.Item
            xs={9}
            style={{
              justifyContent: 'flex-end',
              margin: '2rem auto',
            }}
          >
            <ButtonsBackSubmit
              dataTestIdBtnOne="cancel"
              dataTestIdBtnTwo="manage-pocs-button"
              variantBtnOne="secondary"
              styleBtnOne={{marginRight: '1rem'}}
              onClickBtnOne={handleCancel}
              onClickBtnTwo={managePocs}
              textIdBtnOne="CAMPAIGN_ELIGIBLE_POCS.ADD.CANCEL"
              textIdBtnTwo="CAMPAIGN_ELIGIBLE_POCS.BULK_MANAGE.MANAGE_POCS_BUTTON"
              disabledBtnTwo={status !== 'done' || !file}
              isLoadingBtnTwo={addingFile}
            />
          </Grid.Item>
        </>
      )}
    </Grid.Container>
  );
}

import {uploadPocsService} from '@Services';

export default async function uploadPocsUseCase(
  campaignId: number,
  file: File
): Promise<{
  numberOfPocs: number;
  pocUploadId: string;
}> {
  const data = new FormData();
  data.append('file', file);

  const response = await uploadPocsService(campaignId, data);
  if (response.hasError) {
    const errorMessage = response.response?.data?.errors[0]?.message || '';
    const isInvalid =
      errorMessage.includes('invalid lines') ||
      errorMessage.includes('without Poc Code');

    const errorPayload = {
      id: isInvalid ? 'invalid' : 'error',
    };

    throw errorPayload;
  }
  return response.data;
}

import React from 'react';
import {useField} from 'formik';
import {DatePicker, DatePickerProps} from '@hexa-ui/components';

const defaultLocale = 'en'; // or 'pt', 'es'

const CustomDatePicker: React.FC<
  Omit<DatePickerProps, 'onChange' | 'customLocale'>
> = props => {
  const [field, meta, helper] = useField(props as any);

  const {width = '100%'} = props;

  return (
    <DatePicker
      {...field}
      {...props}
      width={width}
      customLocale={defaultLocale}
      hasError={Boolean(meta.touched && meta.error)}
      errorText={meta.error}
      placeholder={props.placeholder ?? ''}
      value={field.value ? [field.value] : undefined}
      onChange={date => {
        helper.setTouched(true);
        date && helper.setValue(date[0]);
      }}
    />
  );
};

export default CustomDatePicker;

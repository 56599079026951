export default interface POC {
  pocId: string;
  accountId: string;
  pocName: string;
  status: string;
  uploadDate: string;
  blocked: boolean;
  blockedAt?: string;
}

export enum POCStatusTypes {
  PARTICIPATING = 'participating',
  NOT_PARTICIPATING = 'not-participating',
  BLOCKED_MANUAL_DISABLED = 'blocked-manual',
  BLOCKED_MANUALLY_FRAUD_PREVENTION = 'blocked-manually-fraud-prevention',
  BLOCKED_AUTOMATIC = 'blocked-automatic',
  DISABLED_ON_PROMOTION = 'disabled-on-promotion',
}

import {
  Alert,
  Card,
  Divider,
  Grid,
  Heading,
  InputWrapper,
  Radio,
  Select,
  Toggle,
  Tooltip,
} from '@hexa-ui/components';
import {useIntl} from 'react-intl';
import {
  CustomCheckbox,
  CustomDatePicker,
  CustomInput,
  CustomNumberInput,
  CustomSelectInput,
} from '@Components';
import {memo, useEffect, useMemo, useState} from 'react';
import {useCurrentLocale} from '@Hooks';
import {useFormikContext} from 'formik';
import {CurrencyInput, TextItem} from '@martech/components';
import {Info} from '@hexa-ui/icons';
import {useLocation} from 'react-router-dom';

const PlatformSettings: React.FC = () => {
  const {values, setFieldValue, setFieldTouched, touched, errors} =
    useFormikContext<any>();
  const {formatMessage} = useIntl();
  const locale = useCurrentLocale();
  const location = useLocation();
  const isEdit = location.state?.edit;

  const yearValues = useMemo(() => {
    const today = new Date().getFullYear();

    return [today - 1, today, today + 1].map(y => y.toString());
  }, []);

  const monthValues = useMemo(() => {
    return Array.from({length: 12}, (_, month) => {
      const date = new Date(2000, month, 1);
      return date.toLocaleString(locale, {month: 'long'});
    }).map(m => m.charAt(0).toUpperCase() + m.slice(1));
  }, []);

  const [showDailyRedemptionLimit, setShowDailyRedemptionLimit] =
    useState(false);

  const getFieldsFromB2OTag = () => {
    const regex = /^(\w+)-(\d+)-(\w+)-(\w+)-(.+)$/;
    const match = values.globalB2OTag.match(regex);
    if (match) {
      const [, initiative, year, launch, globalB2OCountry, uniqueName] = match;
      const index = monthValues.findIndex((month: any) =>
        month.startsWith(launch)
      );
      setFieldValue('initiative', initiative);
      setFieldValue('year', year);
      setFieldValue('launch', monthValues[index]);
      setFieldValue('globalB2OCountry', globalB2OCountry);
      setFieldValue('uniqueName', uniqueName);
    }
  };

  useEffect(() => {
    const {
      initiative,
      year,
      launch = '',
      globalB2OCountry,
      uniqueName,
    } = values;

    setFieldValue(
      'globalB2OTag',
      `${initiative}-${year}-${launch.substring(
        0,
        3
      )}-${globalB2OCountry}-${uniqueName}`
    );
  }, [
    values.initiative,
    values.year,
    values.launch,
    values.country,
    values.uniqueName,
  ]);

  useEffect(() => {
    if (values.globalB2OTag) {
      getFieldsFromB2OTag();
    }
  }, [values.globalB2OTag]);

  useEffect(() => {
    if (values.maximumCouponsValidationAttemptsByPeriod) {
      setFieldValue(
        'maximumCouponsValidationAttemptsByPeriod',
        values.maximumCouponsValidationAttemptsByPeriod
      );
    }
  }, [values.maximumCouponsValidationAttemptsByPeriod]);

  useEffect(() => {
    if (values.consumerRedemptionCouponLimit) {
      setFieldValue(
        'consumerRedemptionCouponLimit',
        values.consumerRedemptionCouponLimit
      );
    }
  }, [values.consumerRedemptionCouponLimit]);

  useEffect(() => {
    if (!values.hasLimitPerConsumer) {
      setFieldValue('consumerRedemptionCouponLimit', '');
    } else {
      setShowDailyRedemptionLimit(true);
    }
  }, [values.hasLimitPerConsumer]);

  const periodInSecondsOptions = useMemo(() => {
    return [
      {
        value: '300',
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.PERIODS.FIVE_MIN',
        }),
      },
      {
        value: '900',
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.PERIODS.FIFTEEN_MIN',
        }),
      },
      {
        value: '1800',
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.PERIODS.THIRTY_MIN',
        }),
      },
      {
        value: '3600',
        label: formatMessage({
          id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.PERIODS.ONE_HOUR',
        }),
      },
    ];
  }, []);

  return (
    <Card
      data-testid="platform-settings-card"
      border="medium"
      elevated="small"
      style={{width: '100%', padding: '2rem'}}
    >
      <Grid.Container type="fluid" style={{margin: 0}}>
        <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
          <Heading size="H3">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.TITLE',
            })}
          </Heading>
        </Grid.Item>

        <Grid.Item xs={12} style={{padding: 0}}>
          <InputWrapper
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.CAMPAIGN_HOST',
            })}
          >
            <Radio.Root
              value={values.campaignHost}
              onValueChange={value => setFieldValue('campaignHost', value)}
              style={{display: 'flex', gap: '2rem'}}
            >
              <Radio.Item
                id="beesDigitalCoupons"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.BEES_COUPONS',
                })}
                value="beesdc"
              />
              <Radio.Item
                id="thirdPartyPlatform"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.THIRD_PARTY_PLATFORM',
                })}
                value="third-party"
              />
            </Radio.Root>
          </InputWrapper>
        </Grid.Item>

        <Grid.Item xs={12} style={{padding: 0}}>
          <InputWrapper
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.CAMPAIGN_PERIOD',
            })}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                gap: '2rem',
                marginTop: '1rem',
              }}
            >
              <CustomDatePicker
                name="startDateCampaign"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.START_DATE',
                })}
              />
              <CustomDatePicker
                name="endDateCampaign"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.END_DATE',
                })}
              />
            </div>
          </InputWrapper>
        </Grid.Item>

        <Grid.Item style={{margin: '2rem 0'}} xs={12}>
          <Divider />
        </Grid.Item>

        <Grid.Item xs={12}>
          <InputWrapper
            width="100%"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.CAMPAIGN_TAG',
            })}
          >
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                marginTop: '1rem',
                alignItems: 'flex-end',
                width: '100%',
              }}
            >
              <CustomInput
                disabled
                width="120px"
                name="initiative"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.INITIATIVE',
                })}
              />
              <CustomSelectInput
                width="120px"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.YEAR',
                })}
                name="year"
              >
                {yearValues.map(year => (
                  <Select.Option key={year} value={year}>
                    {year}
                  </Select.Option>
                ))}
              </CustomSelectInput>
              <CustomSelectInput
                width="120px"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.LAUNCH',
                })}
                name="launch"
              >
                {monthValues.map(month => (
                  <Select.Option key={month} value={month}>
                    {month}
                  </Select.Option>
                ))}
              </CustomSelectInput>
              <CustomInput
                disabled
                width="120px"
                name="globalB2OCountry"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.COUNTRY',
                })}
                maxLength={3}
              />
              <CustomInput
                name="uniqueName"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.UNIQUE_NAME',
                })}
                maxLength={50}
              />
            </div>
          </InputWrapper>
        </Grid.Item>

        <Grid.Item xs={12} style={{marginTop: '1.5rem'}}>
          <CustomInput
            width="50%"
            disabled
            name="globalB2OTag"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.B2O_TAG',
            })}
          />
        </Grid.Item>

        <Grid.Item style={{margin: '2rem 0'}} xs={12}>
          <Divider />
        </Grid.Item>

        <Grid.Item xs={12}>
          <CurrencyInput
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.DOLLAR_QUOTE',
            })}
            currency={{
              locale: 'en-US',
              currency: 'USD',
              prefix: {
                actual: '$',
                preferred: '$',
              },
            }}
            value={values.dollarQuote}
            onChange={value => {
              setFieldTouched('dollarQuote', true);
              setFieldValue('dollarQuote', value);
            }}
            hasError={!!touched.dollarQuote && !!errors.dollarQuote}
            errorText={errors.dollarQuote as string}
          />
        </Grid.Item>

        <Grid.Item style={{marginTop: '1.5rem'}} xs={12}>
          <CustomCheckbox
            id="couponOnSms"
            name="couponOnSms"
            checked={values.couponOnSms}
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.SEND_SMS',
            })}
          />
        </Grid.Item>

        <Grid.Item style={{margin: '2rem 0'}} xs={12}>
          <Divider />
        </Grid.Item>

        <Grid.Item>
          <Toggle
            name="hasLimitPerConsumer"
            checked={values.hasLimitPerConsumer}
            onCheckedChange={checked => {
              setShowDailyRedemptionLimit(checked);
              setFieldValue('hasLimitPerConsumer', checked);
            }}
            disabled={isEdit}
          />
          <TextItem
            title=""
            value={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.COUPON_REDEMPTION_LIMIT',
            })}
            xs={12}
          />
          <Tooltip
            placement="right"
            text={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.TOOLTIP_INFO',
            })}
          >
            <Info size="large" />
          </Tooltip>
        </Grid.Item>

        {showDailyRedemptionLimit && (
          <Grid.Item xs={12} style={{marginTop: '1rem'}}>
            <CustomNumberInput
              name="consumerRedemptionCouponLimit"
              label={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.DAILY_REDEMPTION_LIMIT',
              })}
            />
          </Grid.Item>
        )}

        <Grid.Item style={{margin: '2rem 0'}} xs={12}>
          <Divider />
        </Grid.Item>

        <Grid.Item xs={8}>
          <InputWrapper
            width="100%"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.CUSTOMER_LIMITS',
            })}
          >
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                marginTop: '1rem',
                alignItems: 'flex-end',
                width: '100%',
              }}
            >
              <CustomNumberInput
                data-testid="redemption-limit"
                name="maximumCouponsValidationAttemptsByPeriod"
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.LIMIT',
                })}
                width="200px"
              />
              <CustomSelectInput
                label={formatMessage({
                  id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.PERIOD',
                })}
                placeholder={formatMessage({
                  id: 'COMMON.BUTTONS.SELECT',
                })}
                name="periodForCouponValidation"
                width="200px"
              >
                {periodInSecondsOptions.map(period => (
                  <Select.Option key={period.value} value={period.value}>
                    {period.label}
                  </Select.Option>
                ))}
              </CustomSelectInput>
            </div>
          </InputWrapper>
        </Grid.Item>

        <Grid.Item style={{marginTop: '1.5rem'}} xs={12}>
          <Alert
            type="info"
            message={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.ALERT',
            })}
            style={{width: '100%'}}
          />
        </Grid.Item>
      </Grid.Container>
    </Card>
  );
};

export default memo(PlatformSettings);

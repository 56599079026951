import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {
  Card,
  Divider,
  Grid,
  Heading,
  IconButton,
  TextButton,
} from '@hexa-ui/components';
import {FieldArray, useFormikContext} from 'formik';
import {CustomEditor, CustomInput, CustomStyledUploader} from '@Components';
import {Plus, Trash2} from '@hexa-ui/icons';

interface DocumentData {
  document: string;
  title: string;
  position: number;
  hasTitle?: boolean;
  hasDocument?: boolean;
}

interface LegalInfoProps {
  isEditing?: boolean;
}

const LegalInfo: React.FC<LegalInfoProps> = ({isEditing}) => {
  const {formatMessage} = useIntl();
  const {values, setFieldValue} = useFormikContext<any>();

  const [legalInfoMaximumIndex] = React.useState<number>(
    values.legalInfoForParticipantPocs.length - 1
  );
  useEffect(() => {
    values?.legalInfoForParticipantPocs.forEach(
      (legalInfo: DocumentData, index: number) => {
        if (legalInfo.title) {
          setFieldValue(`legalInfoForParticipantPocs[${index}].hasTitle`, true);
        }
        if (legalInfo.document) {
          setFieldValue(
            `legalInfoForParticipantPocs[${index}].hasDocument`,
            true
          );
        }
      }
    );
  }, []);

  return (
    <Card
      data-testid="legal-info-card"
      border="medium"
      elevated="small"
      style={{width: '100%', padding: '2rem'}}
    >
      <Grid.Container type="fluid" style={{margin: 0, gap: '1rem'}}>
        <Grid.Item xs={12}>
          <Heading size="H3">
            {formatMessage({id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.TITLE'})}
          </Heading>
        </Grid.Item>

        <Grid.Item xs={12}>
          <Heading size="H4">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CUSTOMER_SECTION.TITLE',
            })}
          </Heading>
        </Grid.Item>

        <FieldArray name="legalInfoForParticipantPocs">
          {({push, remove}) => (
            <>
              {values.legalInfoForParticipantPocs?.map(
                (_: DocumentData, index: number) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      width: '100%',
                      marginTop: index !== 0 ? '1rem' : 0,
                    }}
                  >
                    <Grid.Item xs={5}>
                      <CustomInput
                        data-testid={`legalInfoForParticipantPocs-${index}`}
                        name={`legalInfoForParticipantPocs[${index}].title`}
                        disabled={
                          isEditing &&
                          legalInfoMaximumIndex >= index &&
                          values.legalInfoForParticipantPocs[index].hasTitle
                        }
                        label={formatMessage(
                          {
                            id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CUSTOMER_SECTION.DOCUMENT.TITLE',
                          },
                          {index: index + 1}
                        )}
                        hint={formatMessage({
                          id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CUSTOMER_SECTION.DOCUMENT.TITLE_HINT',
                        })}
                        maxLength={30}
                      />
                    </Grid.Item>
                    <Grid.Item xs={6}>
                      <CustomEditor
                        label={formatMessage({
                          id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CUSTOMER_SECTION.DOCUMENT.LEGAL_TEXT',
                        })}
                        name={`legalInfoForParticipantPocs[${index}].document`}
                        disabled={
                          isEditing &&
                          legalInfoMaximumIndex >= index &&
                          values.legalInfoForParticipantPocs[index].hasDocument
                        }
                      />
                    </Grid.Item>

                    {legalInfoMaximumIndex < index && (
                      <Grid.Item
                        xs={1}
                        style={{alignSelf: 'center', marginTop: '1rem'}}
                      >
                        <IconButton
                          data-testid={`remove-document-${index}`}
                          icon={Trash2}
                          variant="inherit"
                          type="button"
                          onClick={() => remove(index)}
                        />
                      </Grid.Item>
                    )}
                  </div>
                )
              )}
              <Grid.Item xs={12} style={{marginTop: '1.5rem'}}>
                <TextButton
                  data-testid="add-document"
                  icon={Plus}
                  iconPosition="leading"
                  size="small"
                  onClick={() =>
                    push({
                      document: undefined,
                      title: '',
                      position: values.legalInfoForParticipantPocs.length,
                    })
                  }
                  type="button"
                >
                  {formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CUSTOMER_SECTION.DOCUMENT.ADD',
                  })}
                </TextButton>
              </Grid.Item>
            </>
          )}
        </FieldArray>

        <Divider css={{margin: '1rem 0'}} />

        <Grid.Item xs={12}>
          <Heading size="H4">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CONSUMER_SECTION.TITLE',
            })}
          </Heading>
        </Grid.Item>
        <Grid.Item xs={8}>
          <CustomInput
            data-testid="terms-of-use-url"
            name="termsUrl"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CONSUMER_SECTION.TERMS_OF_USE',
            })}
          />
        </Grid.Item>
        <Grid.Item xs={8}>
          <CustomInput
            data-testid="privacy-policy-url"
            name="privacyUrl"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CONSUMER_SECTION.PRIVACY_POLICY',
            })}
          />
        </Grid.Item>

        <Grid.Item xs={12}>
          <CustomStyledUploader
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CONSUMER_SECTION.CAMPAIGN_RULES',
            })}
            acceptedTypes="application/pdf"
            type="doc"
            maxSizeInMB={2}
            onChange={(file, callback) => {
              setFieldValue('campaignRules', file);
              callback(true);
            }}
            onRemove={() => {
              setFieldValue('campaignRules', undefined);
            }}
            placeholder={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.LEGAL_INFO.CONSUMER_SECTION.CAMPAIGN_RULES_PLACEHOLDER',
            })}
            value={values.campaignRules}
          />
        </Grid.Item>
      </Grid.Container>
    </Card>
  );
};

export default LegalInfo;

import MessageMap from '../../i18n';

const disablePoc: MessageMap = {
  TITLE: 'Disable {pocName}?',
  ACTION: 'Yes, disable POC',
  DISABLED_PARTICIPATION_DESCRIPTION:
    'If disabled, the POC will not be able to participate in the promotion and validate coupons again.',
    SUCCESS_MESSAGE:
      '{pocName} was successfully disabled from the {promotionName} promotion.',
};


export default disablePoc;

interface Column {
  id: string;
  label: string;
  dimension: string;
  sort?: boolean;
}

const ColumnsTableDataOne = (
  ids: string[],
  formatMessages: string[],
  sortAddOn: boolean
): Column[] => [
  {
    id: ids[0],
    label: formatMessages[0],
    dimension: '15%',
    sort: true,
  },
  {
    id: ids[1],
    label: formatMessages[1],
    dimension: '20%',
    sort: true,
  },
  {
    id: ids[2],
    label: formatMessages[2],
    dimension: '22%',
  },
  {
    id: ids[3],
    label: formatMessages[3],
    dimension: '15%',
    sort: sortAddOn,
  },
  {
    id: ids[4],
    label: formatMessages[4],
    dimension: '15%',
    sort: true,
  },
  {
    id: ids[5],
    label: formatMessages[5],
    dimension: '12%',
  },
];

const ColumnsTableDataTwo = (
  ids: string[],
  formatMessages: string[]
): Column[] => [
  {
    id: ids[0],
    label: formatMessages[0],
    dimension: '15%',
    sort: true,
  },
  {
    id: ids[1],
    label: formatMessages[1],
    dimension: '20%',
  },
  {
    id: ids[2],
    label: formatMessages[2],
    dimension: '15%',
    sort: true,
  },
  {
    id: ids[3],
    label: formatMessages[3],
    dimension: '15%',
    sort: true,
  },
  {
    id: ids[4],
    label: formatMessages[4],
    dimension: '12%',
  },
];

export {ColumnsTableDataOne, ColumnsTableDataTwo};

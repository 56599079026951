import React from 'react';
import {Formik, Form} from 'formik';
import DTCbanner from '../../Common/DTCbanner/DTCbanner';
import CreateStepFourSchema from './StepFour.schema';
import {CreateCampaignStepProps} from '@Models';
import DomainAndURL from '../../Common/DomainAndURL/DomainAndURL';
import MediaCampaigns from '../../Common/MediaCampaigns/MediaCampaigns';
import {ValuesWatcher} from '@Components';

const StepFour: React.FC<CreateCampaignStepProps> = ({
  onChange,
  initialValues,
}) => {
  const [schema] = CreateStepFourSchema();

  return (
    <div data-testid="step-four" style={{width: '100%'}}>
      <Formik
        initialValues={{
          landingPageUrl: initialValues?.landingPageUrl,
          adSettings: {
            dtcRedirectLink: initialValues?.adSettings?.dtcRedirectLink,
            dtcBannerFile: initialValues?.adSettings?.dtcBannerFile,
            domainType: initialValues?.adSettings?.domainType ?? 'beesdc',
            customDomain:
              initialValues?.adSettings?.customDomain ?? 'beescoupons.com/',
            gtmContainerId: initialValues?.adSettings?.gtmContainerId,
            customCookiesUrl: initialValues?.adSettings?.customCookiesUrl,
            googleAnalytics360UA:
              initialValues?.adSettings?.googleAnalytics360UA,
            googleAnalytics360PropertyViewId:
              initialValues?.adSettings?.googleAnalytics360PropertyViewId,
            mediaAccountIds: initialValues?.adSettings?.mediaAccountIds ?? [
              {channel: 'default', idValue: '', position: 0},
            ],
            socialMediaRedirectLinks: initialValues?.adSettings
              ?.socialMediaRedirectLinks ?? [
              {service: 'default', redirectLink: '', position: 0},
            ],
          },
        }}
        validationSchema={schema}
        validateOnChange
        validateOnMount
        onSubmit={() => {}}
      >
        <Form>
          <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
            <DomainAndURL />
            <DTCbanner />
            <MediaCampaigns />
          </div>
          <ValuesWatcher onChange={onChange} />
        </Form>
      </Formik>
    </div>
  );
};

export default StepFour;

import {bulkManagePocsService} from '@Services';
import {BulkManageActionTypes} from '@Models';

export default async function bulkManagePocsUseCase(
  file: File,
  campaignId: number,
  bulkManageActionType: string
): Promise<any> {
  const data = new FormData();
  data.append('file', file);

  const isBlockOrDisableAction = () => {
    if (
      bulkManageActionType === BulkManageActionTypes.BLOCK_FRAUD_PREVENTION ||
      bulkManageActionType === BulkManageActionTypes.DISABLE_PARTICIPATION
    ) {
      return true;
    }

    return false;
  };

  const isFraud = () => {
    if (
      bulkManageActionType === BulkManageActionTypes.BLOCK_FRAUD_PREVENTION ||
      bulkManageActionType === BulkManageActionTypes.UNBLOCK_FRAUD_PREVENTION
    ) {
      return true;
    }

    return false;
  };

  const response = await bulkManagePocsService(
    data,
    campaignId,
    isFraud(),
    isBlockOrDisableAction()
  );

  if (response.hasError) {
    const errorMessage = response.response?.data?.errors[0]?.message || '';
    const isInvalid =
      errorMessage.includes('invalid lines') ||
      errorMessage.includes('without Poc Code');

    const errorPayload = {
      id: isInvalid ? 'invalid' : 'error',
    };

    throw errorPayload;
  }
  return response.data;
}

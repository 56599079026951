import MessageMap from '../../i18n';

const CouponCampaigns: MessageMap = {
  TITLE: 'Campanhas de cupom',
  CREATE_COUPON_CAMPAIGN_BUTTON: 'Criar campanha de cupom',
  SEARCH_PLACEHOLDER: 'Pesquisar por nome da campanha',
  NO_DATA: {
    TITLE: 'Você não tem campanhas de cupom',
    DESCRIPTION: 'Adicione uma nova clicando em "Criar campanha de cupom"',
  },
  FILTERS: {
    STATUS: 'Status da campanha',
    DATES: {
      TITLE: 'Datas da campanha',
      START: 'Data de início',
      END: 'Data final',
    },
    INVALID_DATE: 'Escolha uma data posterior {startDate}.',
  },
  TABLE: {
    CAMPAIGN_NAME: 'Nome da campanha',
    STATUS: 'Status',
    ACTIVE_PROMOTIONS: 'Promoções ativas',
    ACTIVE_POCS: 'PDVs participantes',
    START_DATE: 'Data de início',
    END_DATE: 'Data final',
    ACTIONS: 'Ações',
    TOOLTIPS: {
      ELIGIBLE_POCS: 'PDVs elegíveis que se inscreveram na campanha de cupons.',
      MANAGE_ELIGIBLE_POCS: 'Gerenciar PDVs elegíveis',
      EDIT: 'Editar',
    },
    STATUSES: {
      ACTIVE: 'Ativa',
      SCHEDULED: 'Agendada',
      FINISHED: 'Concluída',
    },
    DROPDOWN: {
      POC_MANAGEMENT: 'Gerenciamento de PDV',
      EDIT_CAMPAIGN: 'Editar Campanha',
      PROMOTIONS: 'Promoções',
    },
  },

  CREATE: {
    TITLE: 'Criar campanha de cupom',
    DOWNLOAD_TEMPLATE: 'Baixar modelo',
    PREVIEW: 'Mostrar prévia',
    BUTTON: 'Criar campanha',
    SUCCESS_MESSAGE: 'Campanha {campaignName} criada com sucesso.',
    UPDATED_MESSAGE: 'Campanha {campaignName} atualizada com sucesso.',
    STEPS: {
      CAMPAIGN: {
        TITLE: 'Campanha',
        DESCRIPTION: 'Defina os detalhes da campanha.',
      },
      PROMOTIONS: {
        TITLE: 'Promoções',
        DESCRIPTION: 'Defina as configurações do cupom.',
      },
      LEGAL_INFO: {
        TITLE: 'Informações legais',
        DESCRIPTION: 'Forneça informações sobre os Termos e a Política.',
      },
      URL_AND_ADS: {
        TITLE: 'URL e anúncios',
        DESCRIPTION: 'Defina as configurações de domínio e anúncio.',
      },
      SUMMARY: {
        TITLE: 'Resumo',
        DESCRIPTION: 'Revise e publique.',
      },
    },
    DISCARD_MODAL: {
      TITLE: 'Descartar campanha?',
      DESCRIPTION: 'Esta campanha será descartada permanentemente.',
      DISCARD: 'Descartar',
    },
    CUSTOMER_SETTINGS: {
      TITLE: 'Configurações do cliente',
      NAME: {
        LABEL: 'Nome da campanha',
        HINT: 'Esse nome também é mostrado ao consumidor.',
      },
      IMAGE: {
        LABEL: 'Imagem da campanha',
        PLACEHOLDER:
          'Formato do arquivo: JPG ou PNG. Resolução: 1920x1080. Tamanho: Até 2MB.',
        BUTTON: 'Selecionar arquivo',
        TYPE_ERROR: 'Tipo de arquivo não suportado.',
        INVALID_ERROR: 'O carregamento falhou.',
      },
      DOWNLOAD_TEMPLATE: 'Baixar modelo',
      CAMPAIGN_PREVIEW: {
        TITLE: 'Visualização do cartão de campanha',
        NAME: 'Nome da campanha',
        PROMOTIONS: 'Promoções',
        PROMO: 'promoções',
        POINTS: 'Pontos BEES',
      },
      ALT_TEXT: {LABEL: 'Texto ALT da imagem'},
      DESCRIPTION: {
        LABEL: 'Descrição da campanha',
      },
    },
    CONSUMER_SETTINGS: {
      TITLE: 'Configurações do consumidor',
      AGE_GATE: {
        TITLE: 'Dispositivo de acesso seletivo por idade',
        CHECKBOX: 'Mostrar portão de idade',
        TYPE: {
          TITLE: 'Tipo',
          FIRST: 'Sim ou Não',
          SECOND: 'DD/MM/AAAA',
        },
        MOBILE_BG: {
          TITLE: 'Plano de fundo móvel',
          DESCRIPTION:
            'Formato do arquivo: JPG ou PNG. Resolução: 1180x1920. Tamanho: Até 2MB.',
        },
        DESKTOP_BG: {
          TITLE: 'Plano de fundo da área de trabalho',
          DESCRIPTION:
            'Formato do arquivo: JPG ou PNG. Resolução: 1920x930. Tamanho: Até 2MB.',
        },
      },
      HEADER: {
        TITLE: 'Cabeçalho',
        LOGO: {
          TITLE: 'Logotipo',
          DESCRIPTION: 'Formato do arquivo: JPG ou PNG. Tamanho: Até 1MB.',
        },
        COLOR: {
          TITLE: 'Cor do cabeçalho',
          HINT: 'Exemplo: #ED1941.',
        },
      },
      FOOTER: {
        TITLE: 'Rodapé',
        CONTACT: {
          TYPE: 'Tipo de contato {index}',
          CONTACT: 'Contato {index}',
        },
        TYPES: {
          EMAIL: 'E-mail',
          PHONE: 'Telefone',
          WEBSITE: 'Website',
          WHATSAPP: 'WhatsApp',
        },
        ADD: 'Adicionar contato',
      },
      REGISTER_FORM: {
        TITLE: 'Formulário de registro',
        BANNER: {
          TITLE: 'Banner de registro',
          DESCRIPTION:
            'Formato do arquivo: JPG ou PNG. Resolução: 1920x1280. Tamanho: Até 2MB.',
        },
        UNIQUE_ID:
          'Mostrar campo de entrada de identificador governamental exclusivo.',
      },
      MAP: {
        TITLE: 'Mapa',
        PIN_TYPE: {
          TITLE: 'Tipo de pin POC',
          FIRST: 'Padrão',
          SECOND: 'Personalizado (ícone carregado)',
        },
        CUSTOM: {
          TITLE: 'Pin personalizado',
          DESCRIPTION: 'Formato do arquivo: JPG ou PNG. Tamanho: Até 1MB.',
        },
      },
      BUTTONS: {
        TITLE: 'Cor do botão e do texto',
        FIRST: {
          TITLE: 'Botões e cor do texto do portão de idade',
          HINT: 'Exemplo: #ED1941.',
        },
        SECOND: {
          TITLE: 'Cor dos botões e links',
          HINT: 'Exemplo: #ED1941.',
        },
        BUTTON: 'Sim',
      },
      VALIDATIONS: {
        UPLOAD_FAILED: 'O carregamento falhou.',
        WRONG_FORMAT: 'Formato inválido.',
        INVALID_EMAIL: 'Insira um e-mail válido. Exemplo: name@domain.com.',
        INVALID_PHONE: 'Insira um número de telefone válido.',
        INVALID_WHATSAPP: 'Insira um número de WhatsApp válido.',
        INVALID_URL: 'Insira uma URL válida.',
        INVALID_COLOR: 'Insira uma cor válida.',
        MAX_SIZE: 'O arquivo excedeu {size}.',
      },
    },
    PLATFORM_SETTINGS: {
      TITLE: 'Configurações da plataforma',
      CAMPAIGN_HOST: 'Anfitrião da campanha',
      BEES_COUPONS: 'BEES Digital Coupons',
      THIRD_PARTY_PLATFORM: 'Plataforma de terceiros',
      CAMPAIGN_PERIOD: 'Período da campanha',
      START_DATE: 'Data de início',
      END_DATE: 'Data de término',
      CAMPAIGN_TAG: 'Etiqueta da campanha',
      INITIATIVE: 'Iniciativa',
      YEAR: 'Ano',
      LAUNCH: 'Lançamento',
      COUNTRY: 'País',
      UNIQUE_NAME: 'Nome exclusivo',
      B2O_TAG: 'Etiqueta B2O Global',
      DOLLAR_QUOTE: 'Cotação em dólares na campanha',
      SEND_SMS: 'Envie o código do cupom via SMS para o consumidor.',
      INVALID_DATE: 'A data de término não pode ser anterior à data de início.',
      INVALID_DOLLAR_QUOTE: 'Valor deve ser maior ou igual a 0.01.',
      COUPON_REDEMPTION_LIMIT: 'Limite de resgate do cupom',
      COUPON_REDEMPTION_FREQUENCY_MINUTES: 'a cada {time} minutos',
      COUPON_REDEMPTION_FREQUENCY_HOUR: 'a cada {time} hora',
      DAILY_REDEMPTION_LIMIT: 'Limite de resgate diário por consumidor',
      LIMIT: 'Limite de resgate (por período)',
      PERIOD: 'Período',
      PERIODS: {
        FIVE_MIN: 'Cada 5 minutos',
        FIFTEEN_MIN: 'Cada 15 minutos',
        THIRTY_MIN: 'Cada 30 minutos',
        ONE_HOUR: 'Cada 1 hora',
      },
      CUSTOMER_LIMITS: 'Limites de PDV',
      TOOLTIP_INFO:
        'Defina um limite de resgate diário por consumidor, de modo a evitar fraudes de cupons e melhorar os resultados da campanha.',
    },
    LEGAL_INFO: {
      TITLE: 'Informações legais',
      CUSTOMER_SECTION: {
        TITLE: 'Cliente',
        DOCUMENT: {
          FILE: 'Arquivo de documento {index}',
          FILE_PLACEHOLDER: 'Formato: PDF. Tamanho: Até 2MB.',
          TITLE: 'Título do documento {index}',
          TITLE_HINT: 'Exemplo: Termos e condições.',
          ADD: 'Adicionar documento',
          LEGAL_TEXT: 'Informação legal',
        },
      },
      CONSUMER_SECTION: {
        TITLE: 'Consumidor',
        TERMS_OF_USE: 'Termos de uso (URL)',
        PRIVACY_POLICY: 'Política de privacidade (URL)',
        CAMPAIGN_RULES: 'Regras da campanha',
        CAMPAIGN_RULES_PLACEHOLDER: 'Formato: PDF. Tamanho: Até 2MB.',
      },
    },
    PROMOTIONS: {
      ALERT:
        'Depois que a campanha for criada, as promoções não podem ser excluídas, apenas desativadas. Adicionar novas promoções ainda é possível. Pelo menos 1 promoção por campanha precisa ser ativada.',

      PROMOTION: 'Promoção',
      NAME: {
        LABEL: 'Nome da promoção',
        HINT: 'Exemplo: 10% de desconto na garrafa de Stella Artois. (Máximo de 60 caracteres)',
      },
      CARD: {
        PREVIEW: 'Visualização do cartão de campanha',
        POINTS: 'Pontos BEES',
        TYPE: 'Tipo de cupom',
      },
      IMAGE: {
        NAME: 'Imagem informativa',
        SELECT: 'Selecionar arquivo',
        FORMAT:
          'Formato do arquivo: JPG ou PNG. Resolução: 1920x721. Tamanho: Até 1MB.',
        ALT_TEXT: 'Texto ALT da imagem promocional',
      },
      TOGGLE: {
        POC_BASE: 'Usar a base de PDV do nível da campanha',
      },
      STATUS: {
        IN_PROGRESS: 'Em andamento',
        DISABLED: 'Desabilitado',
      },
      DELETE: {
        TITLE: 'Excluir promoção {index}?',
        DESCRIPTION: 'Esta promoção sera deletada permanentemente.',
        CONFIRM: 'Deletar',
      },
      DOWNLOAD_TEMPLATE: 'Baixar modelo',
      ADD_BUTTON: 'Adicionar promoção',
      PROMOTION_GOAL: {
        TITLE: 'Meta da promoção',
        INTRODUCE: {
          TITLE: 'Apresentar',
          DESCRIPTION: 'Aumentar a cobertura da marca.',
        },
        SUSTAIN: {
          TITLE: 'Sustentar',
          DESCRIPTION: 'Aumentar a frequência de compras.',
        },
        GENERATE: {
          TITLE: 'Gerar demanda',
          DESCRIPTION: 'Aumentar o valor por compra.',
        },
      },
      PRODUCT_SETTINGS: {
        TITLE: 'Configurações do produto',
        PRODUCT_TYPE: {
          TITLE: 'Tipo de produto',
          HINT: 'Exemplo: Cerveja.',
        },
        PRODUCT_BRAND: {
          TITLE: 'Marca do produto',
          HINT: 'Exemplo: Stella Artois.',
        },
        PRODUCT_NAME: {
          TITLE: 'Nome do produto',
          HINT: 'Exemplo: Garrafa Stella Artois.',
        },
        TOTAL_UNITS: {
          TITLE: 'Total de unidades',
          HINT: 'Unidades em um único cupom.',
        },
        PRICE_PER_UNIT: {
          TITLE: 'Preço por unidade',
          HINT: 'Valor individual.',
        },
        VOLUME_PER_UNIT: {
          TITLE: 'Volume por unidade',
          HINT: 'Em mililitro (ml).',
        },
      },
      COUPON_SETTINGS: {
        TITLE: 'Configurações do cupom',
        COUPON_TYPE: 'Tipo de cupom',
        DISCOUNT: 'Desconto',
        DISCOUNT_TITLE: 'Desconto (%)',
        FREE_GOOD: 'Mercadoria gratuita',
        FREE_GOOD_TITLE: 'Mercadoria gratuita (un.)',
        COUPON_AMOUNTS: {
          TITLE: 'Quantidades de cupom',
          TOTAL_GEN: 'Máximo de geração total',
          CONSUMER_GEN: 'Máximo de geração de consumidores',
          CONSUMER_ACTIVE_COUPONS: 'Máximo de cupons ativos de consumidores',
          COUPON_AMOUNT_ALERT:
            'O valor da geração do cupom deve ser maior que o limite diário de resgate.',
        },
        COUPON_VALUATION: {
          TITLE: 'Validação do cupom',
          POINTS_PER_COUPON: 'Pontos BEES por cupom',
          COUPON_PRICE: 'Preço do cupom',
          SUCCESS_MESSAGE: {
            TITLE: 'Mensagem de sucesso',
            HINT: 'Exemplo: Parabéns! Você acabou de ganhar um cupom de uma bebida grátis. Resgate seu benefício na loja mais próxima.',
          },
        },
      },
      DATES: {
        TITLE: 'Datas',
        DESCRIPTION:
          'As datas devem estar dentro do período de início e término da campanha.',
        ALERT:
          'Exceder o limite suspende o resgate por 30 minutos. Três suspensões em 24h bloqueiam o POC em todas as campanhas por atividades suspeitas. Você pode desbloqueá-los em POCs elegíveis.',
        COUPON_GENERATION: {
          TITLE: 'Geração de cupons',
          START_DATE: 'Data de início',
          END_DATE: 'Data de término',
          EXP_TIME: 'Tempo de expiração',
          HOURS: 'horas',
          EXP_TIME_ALERT:
            'Os cupons podem expirar caso o tempo de expiração definido seja muito curto.',
        },
        COUPON_REDEMPTION: {
          TITLE: 'Resgate de cupons',
          START_DATE: 'Data de início',
          END_DATE: 'Data de término',
        },
      },
      DIALOG_CONTENT:
        ' Observe que se esta promoção for desativada você não poderá mais ativá-la ou vê-la nesta página. Se você precisar habilitá-la no futuro, abra um ticket para nossa equipe técnica.',
    },
    DOMAIN_AND_URL: {
      TITLE: 'Domínio e URL',
      DOMAIN_TYPE: {
        TITLE: 'Tipo de domínio',
        BEESDC: 'BEES Digital Coupons',
        CUSTOM: 'Personalizado(a)',
      },
      DEFAULT_DOMAIN: {
        TITLE: 'Domínio padrão',
        HINT_BEESDC: 'O domínio Digital Coupons será usado.',
        HINT_CUSTOM: 'Exemplo: www.brandname.com/',
      },
      PATH_NAME: {
        TITLE: 'Nome do caminho',
        HINT: 'Use apenas letras minúsculas, números e hífens.',
        ERROR: 'Esta URL de campanha já existe. Por favor, escolha uma nova.',
      },
      GTM_CONTAINER_ID: 'ID do recipiente GTM',
      CUSTOM_URL_COOKIES: 'Consentimento de cookie de URL personalizado',
      GOOGLE_ANALYTICS_UA: 'Número UA do Google Analytics 360',
      GOOGLE_ANALYTICS_VIEW_ID:
        'ID de exibição de propriedade do Google Analytics 360',
    },
    DTC: {
      TITLE: 'Banner DTC',
      LINK: {
        LABEL: 'Link de redirecionamento do DTC',
      },
      IMAGE: {
        LABEL: 'Banner DTC',
        PLACEHOLDER:
          'Formato do arquivo: JPG ou PNG. Resolução: 1366x513. Tamanho: Até 2MB.',
        BUTTON: 'Selecionar arquivo',
      },
    },
    MEDIA_CAMPAIGNS: {
      TITLE: 'Campanhas de mídia',
      MEDIA_ACCOUNT: {
        LABEL: 'Lista de ID de conta de mídia',
        CHANNEL: 'Canal {index}',
        ID: 'ID {index}',
        ADD: 'Adicionar canal',
        TYPES: {
          DEFAULT: 'Selecionar',
          ADSMOVIL: 'Adsmovil',
          EMAIL_MARKETING: 'Email marketing',
          FACEBOOK: 'Facebook',
          GOOGLE_ADS: 'Google Ads',
          PUSH_NOTIFICATION: 'Push notification',
          SMS: 'SMS',
          TWITTER: 'Twitter',
          VERIZON: 'Verizon',
          YOUTUBE: 'YouTube',
        },
      },
      SOCIAL_MEDIA: {
        LABEL: 'Link de redirecionamento de mídia social',
        SERVICE: 'Serviço {index}',
        LINK: 'Link de redirecionamento {index}',
        ADD: 'Adicionar serviço',
        TYPES: {
          DEFAULT: 'Selecionar',
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          TIKTOK: 'TikTok',
          TWITTER: 'Twitter',
          YOUTUBE: 'YouTube',
        },
      },
    },
    SUMMARY: {
      UP_TO: 'Até {points} pontos',
      CAMPAIGN: 'Campanha',
      CONSUMER: {
        UNIQUE_ID: 'Campo de identidade governamental',
        CONTACT_TYPE: 'Tipo de contato',
      },
      PLATFORM: {
        SEND_SMS: 'Enviar código de cupom via SMS',
        START_DATE: 'Data de início da campanha',
        END_DATE: 'Data de término da campanha',
      },
      PROMOTIONS: {
        INDEX: 'Promoção {index}',
        POINTS: '{points} pontos',
        GOAL: 'Meta',
        COUPON_GENERATION_PERIOD: 'Período de geração do cupom',
        COUPON_REDEMPTION_PERIOD: 'Período de resgate do cupom',
      },
      URL_AND_MEDIA: {
        TITLE: 'URL & mídia',
        DOMAIN: 'Domínio',
      },
      DTC: {
        BANNER: 'Banner DTC',
      },
    },
  },
  EDIT: {
    EDIT_BUTTON: 'Editar',
    GO_BACK_BUTTON: 'Voltar para campanha de cupom',
    DISCARD_MODAL: {
      TITLE: 'Descartar alterações?',
      DESCRIPTION: 'Alterações não salvas serão perdidas.',
      DISCARD: 'Descartar',
    },
  },
};

export default CouponCampaigns;

import {createPromotionService, editPromotionService} from '@Services';
import {CreatePromotionProps, Promotion} from '@Models';
import {objectToFormData} from '@Utils';

function convertToFormData(promotionsList: Promotion[]): FormData {
  const formData = new FormData();

  const promotions = promotionsList?.map((promo: Promotion) => {
    if (promo.promotionImage && promo.promotionImageFile) {
      delete promo.promotionImage;
    }
    return promo;
  });

  objectToFormData({promotions}, formData);

  return formData;
}

export default async function updateCampaignPromotionsUseCase(
  promotion: CreatePromotionProps
) {
  const newPromotionsList =
    promotion?.promotions?.filter(promo => promo.newPromotion) || [];
  const editedPromotionsList =
    promotion?.promotions?.filter(
      promo => promo.editedPromotion && !promo.newPromotion
    ) || [];
  const responses = [];

  if (editedPromotionsList.length > 0) {
    const formData = convertToFormData(editedPromotionsList);
    const response = await editPromotionService(formData);
    if (response.hasError) {
      throw new Error(response.response.status);
    }
    responses.push(response.data);
  }

  if (newPromotionsList.length > 0) {
    const formData = convertToFormData(newPromotionsList);
    const response = await createPromotionService(formData);
    if (response.hasError) {
      throw new Error(response.response.status);
    }
    responses.push(response.data);
  }

  return responses;
}

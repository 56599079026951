import MessageMap from '../../i18n';

const blockPoc: MessageMap = {
  TITLE: '¿Bloquear {pocName}?',
  ACTION: 'Sí, bloquear PDV',
  SUCCESS_MESSAGE:
    '{pocName} fue bloqueado con éxito de la campaña del {campaignName}.',
  MANUALLY_FRAUD_PREVENTION: 'Bloqueo por prevención de fraude',
  MANUALLY_FRAUD_PREVENTION_DESCRIPTION:
    'Esta opción debe utilizarse en caso de actividades sospechosas. El PDV no podrá canjear cupones en ninguna campaña. No se proporcionarán puntos BEES pendientes.',
  DISABLED_PARTICIPATION: 'Desactivar participación',
  DISABLED_PARTICIPATION_DESCRIPTION:
    'Este PDV no podrá canjear cupones en esta campaña.',
};

export default blockPoc;

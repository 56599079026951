import React from 'react';
import {Button, Dialog, Heading, Paragraph} from '@hexa-ui/components';
import {useIntl} from 'react-intl';

interface CancelActionModalProps {
  title: string;
  description: string;
  confirmTitle?: string;
  cancelTitle?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onCancel: () => void;
}

const CancelActionModal: React.FC<CancelActionModalProps> = ({
  title,
  description,
  confirmTitle,
  cancelTitle,
  isOpen,
  setIsOpen,
  onCancel,
}) => {
  const {formatMessage} = useIntl();

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog.Root
      open={isOpen}
      onClose={onClose}
      actions={
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            data-testid="confirm-cancel-button"
            size="medium"
            variant="primary"
            onClick={onCancel}
            style={{background: '#C9201D', marginRight: '0.9375rem'}}
          >
            {confirmTitle || formatMessage({id: 'COMMON.BUTTONS.YES_CANCEL'})}
          </Button>
          <Button
            data-testid="close-cancel-modal"
            size="medium"
            variant="secondary"
            onClick={onClose}
          >
            {cancelTitle || formatMessage({id: 'COMMON.BUTTONS.GO_BACK'})}
          </Button>
        </div>
      }
      title={<Heading size="H2">{title}</Heading>}
    >
      <Paragraph>{description}</Paragraph>
    </Dialog.Root>
  );
};

export default CancelActionModal;

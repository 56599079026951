import {editCampaignService} from '@Services';
import {CreateCampaignProps} from '@Models';
import {objectToFormData} from '@Utils';

function convertToFormData(values: CreateCampaignProps): FormData {
  const formData = new FormData();

  if (values.adSettings.dtcBanner && values.adSettings.dtcBannerFile) {
    delete values.adSettings.dtcBanner;
  }

  const dtcBannerFile = values.adSettings?.dtcBannerFile?.[0]?.file ?? null;

  objectToFormData(
    {...values, adSettings: {...values.adSettings, dtcBannerFile}},
    formData
  );

  return formData;
}

export default async function updateCampaignUrlMediaUseCase(
  campaign: CreateCampaignProps
) {
  const formData = convertToFormData(campaign);
  const response = await editCampaignService(+campaign.campaignId!, formData);

  if (response.hasError) {
    throw new Error(response.response.status);
  }
  return response.data;
}

import {getEnvironmentFromToken} from '@martech/utils';
import {useAuthenticationService} from 'admin-portal-shared-services';

const getStorageURL = () => {
  const storageURLs: any = {
    QA: 'https://beesdevglobaldigicpn.z20.web.core.windows.net',
    SIT: 'https://beessitglobaldigicpn.z20.web.core.windows.net',
    UAT: 'https://beesuatglobaldigicpn.z20.web.core.windows.net',
    PROD: 'https://beesglobaldigicpn.z20.web.core.windows.net',
  };
  const authService = useAuthenticationService();
  const userEnv = getEnvironmentFromToken(authService.getIssuer());

  return storageURLs[userEnv];
};

export default getStorageURL;

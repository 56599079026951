export default function objectToFormData(
  obj: Record<string, any>,
  formData: FormData,
  parentKey?: string
): void {
  Object.entries(obj).forEach(([property, value]) => {
    const key = parentKey ? `${parentKey}.${property}` : property;
    if (typeof value !== 'undefined' && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item: any, index: number) => {
          objectToFormData(item, formData, `${key}[${index}]`);
        });
      } else if (
        typeof value === 'object' &&
        !(value instanceof File) &&
        !(value instanceof Date)
      ) {
        objectToFormData(value, formData, key);
      } else {
        formData.append(
          key,
          value instanceof Date ? value.toISOString() : value
        );
      }
    }
  });
}

import MessageMap from '../../i18n';

const promotionList: MessageMap = {
  TITLE: 'Promociones de {title}',
  SEARCH_PLACEHOLDER: 'Buscar por nombre de promoción',
  TABLE: {
    PROMOTION_NAME: 'Nombre de la promoción',
    PARTICIPATING_POCS: 'PDVs participantes',
    START_DATE: 'Fecha de inicio',
    END_DATE: 'Fecha de finalización',
    ACTIONS: 'Acciones',
  },
  NO_DATA: {
    TITLE: 'You have No tienes promociones',
    DESCRIPTION: 'Agrega una nueva haciendo clic en "Agregar promoción".',
  },
  FILTERS: {
    DATES: {
      TITLE: 'Fecha de la promoción',
      START: 'Fecha de inicio',
      END: 'Fecha de finalización',
    },
    INVALID_DATE: 'Elija una fecha posterior al {startDate}.',
  },
  BREADCRUMB_PROMOTION_LIST: 'Promociones',
};

export default promotionList;

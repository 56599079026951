import {Grid, Heading} from '@hexa-ui/components';
import {useIntl} from 'react-intl';
import {useBreadcrumb} from '@Providers';
import {Edit2} from '@hexa-ui/icons';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import {
  ColumnsTableDataTwo,
  CreatePromotionProps,
  LoadDataProps,
  Paginated,
  Promotion,
  SortData,
} from '@Models';
import useBasePromotionListFilter from './PromotionList.filter';
import {
  getPromotionListUseCase,
  getQuantityPocsByPromotionUseCase,
} from '@UseCase';
import {LoadingBee, TableCard} from '@Components';
import {formatDate} from '@Utils';
import {useCampaignDetails} from '../../../hooks';

export default function PromotionList() {
  const navigate = useNavigate();
  const {formatMessage} = useIntl();
  const {campaignId} = useParams();

  const [baseFilter, baseSchema] = useBasePromotionListFilter();
  const [filter, setFilter] = useState(baseFilter);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortProp, setSortProp] = useState<SortData>({
    prop: 'promotionName',
    direction: 'ASC',
  });
  const [loadingData, setLoadingData] = useState(false);

  const [campaign, isLoading] = useCampaignDetails({
    includeBlob: true,
    path: 'Promotions',
  });

  const [promos, setPromos] = useState<Paginated<Promotion>>({
    content: [],
    pagination: {
      page: 0,
      pageSize: 0,
      totalElements: 0,
      totalPages: 0,
    },
  });

  const loadPromos = async ({page = 0, props}: LoadDataProps) => {
    const pageSize = 10;
    setLoadingData(true);
    setPromos({
      content: [],
      pagination: {
        page: 0,
        pageSize: 0,
        totalElements: 0,
        totalPages: 0,
      },
    });
    try {
      const sortOption = props?.sort || sortProp;
      const fetchPromos = await getPromotionListUseCase(
        +campaignId!,
        page,
        pageSize,
        {
          ...props,
          sort: sortOption,
        }
      );
      setSortProp(sortOption);
      setPromos(fetchPromos);
      return fetchPromos;
    } catch {
      return undefined;
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    loadPromos({});
  }, []);

  const handlePromotionDetails = (campaignId: any, promo: any) => {
    navigate(`/campaigns/${campaignId}/promotion-list/${promo.promotionId}`);
  };

  const tableDataPromotionList = useMemo(() => {
    const idsColumnPromotionList = [
      'promotionName',
      'participatingPocsByPromotion',
      'couponGenStart',
      'couponGenEnd',
      'promotionActions',
    ];
    const formateMessagesPromotionList = [
      formatMessage({
        id: 'PROMOTION_LIST.TABLE.PROMOTION_NAME',
      }),
      formatMessage({
        id: 'PROMOTION_LIST.TABLE.PARTICIPATING_POCS',
      }),
      formatMessage({
        id: 'PROMOTION_LIST.TABLE.START_DATE',
      }),
      formatMessage({
        id: 'PROMOTION_LIST.TABLE.END_DATE',
      }),
      formatMessage({
        id: 'PROMOTION_LIST.TABLE.ACTIONS',
      }),
    ];

    return {
      table: {
        columns: ColumnsTableDataTwo(
          idsColumnPromotionList,
          formateMessagesPromotionList
        ),
        cellHeight: 'tight',
        fontSize: '12px',
        pagination: {
          current: promos.pagination.page + 1,
          pageSize: 10,
          onChange: (page: number) => {
            loadPromos({page: page - 1, props: {filter, searchTerm}});
          },
          total: promos.pagination.totalElements,
        },
        data:
          promos?.content?.map((promo, index) => ({
            promotionName: promo.promotionName,
            participatingPocsByPromotion: promo.participatingPocs,
            couponGenStart: formatDate(promo.couponGenStart),
            couponGenEnd: formatDate(promo.couponGenEnd),
            promotionActions: (
              <Edit2
                onClick={() => {
                  handlePromotionDetails(campaignId, promo);
                }}
                size="medium"
                cursor={'pointer'}
              />
            ),
          })) ?? [],
      },
      loadingData,
      noDataTitle: formatMessage({
        id: 'PROMOTION_LIST.NO_DATA.TITLE',
      }),
      noDataDescription: formatMessage({
        id: 'PROMOTION_LIST.NO_DATA.DESCRIPTION',
      }),
    };
  }, [promos, searchTerm, loadingData]);

  useBreadcrumb(
    [
      {
        name: formatMessage({id: 'COUPON_CAMPAIGNS.TITLE'}),
        href: '/campaigns',
      },
      {
        name: `${campaign?.campaignName ?? ''}`,
      },
      {
        name: formatMessage({
          id: 'PROMOTION_LIST.BREADCRUMB_PROMOTION_LIST',
        }),
        active: true,
      },
    ],
    [campaign?.campaignName]
  );

  return !promos || isLoading ? (
    <LoadingBee />
  ) : (
    <Grid.Container sidebar type="fluid" style={{margin: 0, width: '100%'}}>
      <Grid.Item xs={12}>
        <Heading data-testid="promotion-title" size="H2">
          {formatMessage(
            {id: 'PROMOTION_LIST.TITLE'},
            {title: campaign?.campaignName}
          )}
        </Heading>
      </Grid.Item>
      <Grid.Item style={{margin: '1.5rem auto'}} xs={12}>
        <TableCard
          tableData={tableDataPromotionList}
          filter={filter}
          handleFilterChange={value => setFilter(value)}
          searchPlaceholder={formatMessage({
            id: 'PROMOTION_LIST.SEARCH_PLACEHOLDER',
          })}
          loadData={loadPromos}
          schema={baseSchema}
          onSearchTerm={term => {
            setSearchTerm(term);
          }}
        />
      </Grid.Item>
    </Grid.Container>
  );
}

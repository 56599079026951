import {Grid} from '@hexa-ui/components';
import PreviewCard from '../../../PreviewCard/PreviewCard';
import {CreateCampaignProps} from '@Models';
import {BorderContainer, TextItem} from '@martech/components';
import {useIntl} from 'react-intl';
import {formatDate, getFileFromValueArray, getFileName} from '@Utils';
import {useMemo} from 'react';
import CardForEdit from '../CardForEdit/CardForEdit';

export default function CampaignSummary({
  values,
  onEdit,
}: {
  values: CreateCampaignProps | undefined;
  onEdit?: () => void;
}) {
  const {formatMessage} = useIntl();

  const sumOfPoints = useMemo(
    () =>
      values?.promotions?.reduce((accumulator, currentValue) => {
        const value = !currentValue.promotionStatus
          ? 0
          : currentValue.beesPointsPerCoupon ?? 0;
        return accumulator + value;
      }, 0),
    [values?.promotions]
  );

  const SumOfPromos = useMemo(() => {
    return (
      values?.promotions?.filter(promo => promo.promotionStatus).length || 0
    );
  }, [values?.promotion]);

  const ageGateMobileBg = useMemo(
    () =>
      getFileFromValueArray(values?.template?.ageGate?.mobileBackgroundFile),
    [values?.template?.ageGate?.mobileBackgroundFile]
  );

  const ageGateDesktopBg = useMemo(
    () =>
      getFileFromValueArray(values?.template?.ageGate?.desktopBackgroundFile),
    [values?.template?.ageGate?.desktopBackgroundFile]
  );

  const registrationBanner = useMemo(
    () => getFileFromValueArray(values?.template?.registrationBannerFile),
    [values?.template?.registrationBannerFile]
  );

  const getPeriodSecondsToMinutes = (
    periodForCouponValidation: string
  ): string => {
    const minutes = Math.floor(parseInt(periodForCouponValidation, 10) / 60);

    return isNaN(minutes) ? '0' : minutes === 60 ? '1' : minutes.toString();
  };

  const getformatMessage = (periodForCouponValidation: string): string => {
    const periodInMinutesOrHour = getPeriodSecondsToMinutes(
      periodForCouponValidation
    );
    const frequencyUnit = periodInMinutesOrHour === '1' ? 'HOUR' : 'MINUTES';

    return `COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.COUPON_REDEMPTION_FREQUENCY_${frequencyUnit}`;
  };

  const getCouponValidationMessage = (): string => {
    return `
      ${values?.maximumCouponsValidationAttemptsByPeriod} ${formatMessage(
        {
          id: getformatMessage(values?.periodForCouponValidation ?? '0'),
        },
        {
          time: getPeriodSecondsToMinutes(
            values?.periodForCouponValidation ?? '0'
          ),
        }
      )}`;
  };

  return (
    <CardForEdit
      id="campaign"
      title={formatMessage({id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.CAMPAIGN'})}
      onEdit={onEdit}
    >
      <Grid.Item xs={4}>
        <BorderContainer
          heading={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.TITLE',
          })}
        >
          <Grid.Item xs={12} style={{height: '271px'}}>
            <PreviewCard
              name={values?.campaignName}
              badges={[
                {
                  label: `${SumOfPromos} ${formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.CUSTOMER_SETTINGS.CAMPAIGN_PREVIEW.PROMOTIONS',
                  }).toLowerCase()}`,
                  color: 'blue',
                },
                {
                  label: formatMessage(
                    {
                      id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.UP_TO',
                    },
                    {points: sumOfPoints}
                  ),
                  color: 'gray',
                },
              ]}
              imgUrl={
                values?.imageFile
                  ? URL.createObjectURL(values.imageFile)
                  : values?.imageUrl
              }
            />
          </Grid.Item>
        </BorderContainer>
      </Grid.Item>
      <Grid.Item xs={8}>
        <BorderContainer
          heading={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.TITLE',
          })}
        >
          <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.TITLE',
              })}
              value={
                values?.template?.ageGate?.type
                  ? formatMessage({
                      id: 'COMMON.BUTTONS.ENABLED',
                    })
                  : formatMessage({
                      id: 'COMMON.BUTTONS.DISABLED',
                    })
              }
              xs={4}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.HEADER.TITLE',
              })}
              value={
                values?.template?.logoFile
                  ? values?.template?.logoFile.name
                  : getFileName(values?.template?.logo)
              }
              xs={4}
              isLink
              linkTo={
                values?.template?.logoFile
                  ? URL.createObjectURL(values.template.logoFile)
                  : values?.template?.logo
              }
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.PIN_TYPE.TITLE',
              })}
              value={
                values?.template?.pocPinType === 'CUSTOM'
                  ? formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.CUSTOM.TITLE',
                    })
                  : formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.PIN_TYPE.FIRST',
                    })
              }
              xs={4}
            />
          </Grid.Item>

          <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.TYPE.TITLE',
              })}
              value={
                values?.template?.ageGate?.type === 'yes-or-no'
                  ? formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.TYPE.FIRST',
                    })
                  : values?.template?.ageGate?.type === 'birth-date'
                  ? formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.TYPE.SECOND',
                    })
                  : undefined
              }
              xs={4}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.HEADER.COLOR.TITLE',
              })}
              value={values?.template?.headerColor}
              xs={4}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.MAP.CUSTOM.TITLE',
              })}
              value={
                values?.template?.customPinFile
                  ? values.template.customPinFile.name
                  : getFileName(values?.template?.customPin)
              }
              xs={4}
              isLink
              linkTo={
                values?.template?.customPinFile
                  ? URL.createObjectURL(values.template.customPinFile)
                  : values?.template?.customPin
              }
            />
          </Grid.Item>

          <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.MOBILE_BG.TITLE',
              })}
              value={
                ageGateMobileBg
                  ? ageGateMobileBg.name
                  : getFileName(values?.template?.ageGate?.mobileBackground)
              }
              xs={4}
              isLink
              linkTo={
                ageGateMobileBg
                  ? URL.createObjectURL(ageGateMobileBg)
                  : values?.template?.ageGate?.mobileBackground
              }
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.REGISTER_FORM.BANNER.TITLE',
              })}
              value={
                registrationBanner
                  ? registrationBanner.name
                  : getFileName(values?.template?.registrationBanner)
              }
              xs={4}
              isLink
              linkTo={
                registrationBanner
                  ? URL.createObjectURL(registrationBanner)
                  : values?.template?.registrationBanner
              }
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.BUTTONS.FIRST.TITLE',
              })}
              value={values?.template?.ageGateColor}
              xs={4}
            />
          </Grid.Item>

          <Grid.Item xs={12}>
            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.AGE_GATE.DESKTOP_BG.TITLE',
              })}
              value={
                ageGateDesktopBg
                  ? ageGateDesktopBg.name
                  : getFileName(values?.template?.ageGate?.desktopBackground)
              }
              xs={4}
              isLink
              linkTo={
                ageGateDesktopBg
                  ? URL.createObjectURL(ageGateDesktopBg)
                  : values?.template?.ageGate?.desktopBackground
              }
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.CONSUMER.UNIQUE_ID',
              })}
              value={
                values?.template?.govIdentifier
                  ? formatMessage({
                      id: 'COMMON.BUTTONS.ENABLED',
                    })
                  : formatMessage({
                      id: 'COMMON.BUTTONS.DISABLED',
                    })
              }
              xs={4}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.BUTTONS.SECOND.TITLE',
              })}
              value={values?.template?.linksColor}
              xs={4}
            />
          </Grid.Item>
          <Grid.Item xs={12} style={{marginTop: '1rem'}}>
            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.CONSUMER.CONTACT_TYPE',
              })}
              value={
                values?.template?.contacts
                  ? values?.template?.contacts
                      .map(contact =>
                        formatMessage({
                          id: `COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.FOOTER.TYPES.${contact.type.toUpperCase()}`,
                        })
                      )
                      .join(', ')
                  : undefined
              }
              xs={4}
            />
            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.COUPON_REDEMPTION_LIMIT',
              })}
              value={getCouponValidationMessage()}
              xs={4}
            />
          </Grid.Item>
        </BorderContainer>
      </Grid.Item>

      <Grid.Item xs={12} style={{marginTop: '1.5rem'}}>
        <BorderContainer
          heading={formatMessage({
            id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.TITLE',
          })}
        >
          <Grid.Item xs={12}>
            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.CAMPAIGN_HOST',
              })}
              value={
                values?.campaignHost === 'third-party'
                  ? formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.THIRD_PARTY_PLATFORM',
                    })
                  : formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.BEES_COUPONS',
                    })
              }
              xs={2}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.PLATFORM.START_DATE',
              })}
              value={formatDate(values?.startDateCampaign)}
              xs={2}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.PLATFORM.END_DATE',
              })}
              value={formatDate(values?.endDateCampaign)}
              xs={2}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.B2O_TAG',
              })}
              value={values?.globalB2OTag}
              xs={2}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.DOLLAR_QUOTE',
              })}
              value={`$ ${values?.dollarQuote}`}
              xs={2}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.PLATFORM.SEND_SMS',
              })}
              value={
                values?.couponOnSms
                  ? formatMessage({
                      id: 'COMMON.BUTTONS.ENABLED',
                    })
                  : formatMessage({
                      id: 'COMMON.BUTTONS.DISABLED',
                    })
              }
              xs={2}
            />

            <TextItem
              title={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.DAILY_REDEMPTION_LIMIT',
              })}
              value={values?.consumerRedemptionCouponLimit}
              xs={2}
            />
          </Grid.Item>
        </BorderContainer>
      </Grid.Item>
    </CardForEdit>
  );
}

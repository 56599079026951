import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import * as yup from 'yup';
import {formatDate} from '@Utils';

export default function CreatePromotionSchema(
  startDateCampaign: Date,
  endDateCampaign: Date
) {
  const {formatMessage} = useIntl();

  function formatToEndOfDay(date: Date) {
    return date?.setHours(23, 59, 59, 999);
  }

  return useMemo(() => {
    const requiredMessage = formatMessage({
      id: 'COMMON.FORM.REQUIRED',
    });
    const NUMBER_MIN = formatMessage(
      {id: 'COMMON.ERRORS.NUMBER_MIN'},
      {min: 1}
    );

    return yup.object().shape({
      promotions: yup.array().of(
        yup.object().shape({
          promotionName: yup.string().required(requiredMessage).min(1).max(60),
          promotionImageAltText: yup.string().min(1),
          promotionImageFile: yup.mixed().required(),
          promotionGoal: yup.string().required(requiredMessage),
          productType: yup.string().required(requiredMessage).min(1).max(30),
          cdpBrandName: yup.string().required(requiredMessage).min(1).max(30),
          productName: yup.string().required(requiredMessage).min(1).max(30),
          totalUnits: yup.number().required(requiredMessage).min(1, NUMBER_MIN),
          pricePerUnit: yup
            .number()
            .required(requiredMessage)
            .min(
              0.01,
              formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.INVALID_DOLLAR_QUOTE',
              })
            ),
          mlVolumePerUnit: yup
            .number()
            .required(requiredMessage)
            .min(1, NUMBER_MIN),
          couponType: yup.string().required(requiredMessage),
          discount: yup.number().when('couponType', {
            is: (value: string) => value?.toLowerCase() === 'discount',
            then: yup.number().required(requiredMessage).min(1, NUMBER_MIN),
            otherwise: yup.number().nullable(),
          }),
          freeUnits: yup.number().when('couponType', {
            is: (value: string) => value === 'freeUnits',
            then: yup.number().required(requiredMessage).min(1, NUMBER_MIN),
            otherwise: yup.number().nullable(),
          }),
          totalAmountCoupons: yup
            .number()
            .required(requiredMessage)
            .min(1, NUMBER_MIN),
          totalCouponsPerUser: yup
            .number()
            .required(requiredMessage)
            .min(1, NUMBER_MIN),
          maxActiveCouponPerUser: yup
            .number()
            .required(requiredMessage)
            .min(1, NUMBER_MIN),
          beesPointsPerCoupon: yup
            .number()
            .required(requiredMessage)
            .min(1, NUMBER_MIN),
          couponPrice: yup
            .number()
            .required(requiredMessage)
            .min(
              0.01,
              formatMessage({id: 'COMMON.ERRORS.NUMBER_MIN'}, {min: 0.01})
            ),
          campaignSuccessMessage: yup
            .string()
            .required(requiredMessage)
            .min(1)
            .max(150),
          couponGenStart: yup
            .date()
            .required(requiredMessage)
            .transform((v, o) => (o === '' ? null : v))
            .min(
              startDateCampaign,
              formatMessage(
                {id: 'COMMON.ERRORS.MIN_DATE'},
                {date: formatDate(startDateCampaign)}
              )
            ),
          couponGenEnd: yup
            .date()
            .required(requiredMessage)
            .transform((v, o) => (o === '' ? null : v))
            .when(['couponGenStart'], {
              is: (end: Date | undefined) => Boolean(end),
              then: yup
                .date()
                .required()
                .transform((v, o) => (o === '' ? null : v))
                .min(yup.ref('couponGenStart'), () =>
                  formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.INVALID_DATE',
                  })
                ),
            })
            .max(
              endDateCampaign,
              formatMessage(
                {id: 'COMMON.ERRORS.MAX_DATE'},
                {date: formatDate(endDateCampaign)}
              )
            ),
          expirationTime: yup
            .number()
            .required(requiredMessage)
            .min(1, NUMBER_MIN),
          couponRedeemStart: yup
            .date()
            .required(requiredMessage)
            .transform((v, o) => (o === '' ? null : v))
            .test('date is within limits', '', function (value) {
              const {path, parent, createError} = this;
              if (
                value &&
                (value < parent.couponGenStart || value > parent.couponGenEnd)
              ) {
                return createError({
                  path,
                  message: formatMessage({id: 'COMMON.ERRORS.INVALID_DATE'}),
                });
              }
              return true;
            }),
          couponRedeemEnd: yup
            .date()
            .required(requiredMessage)
            .transform((v, o) => (o === '' ? null : v))
            .when(['couponRedeemStart'], {
              is: (end: Date | undefined) => Boolean(end),
              then: yup
                .date()
                .required()
                .transform((v, o) => (o === '' ? null : v))
                .min(yup.ref('couponRedeemStart'), () =>
                  formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PLATFORM_SETTINGS.INVALID_DATE',
                  })
                ),
            })
            .test('date is within limits', '', function (value) {
              const {path, parent, createError} = this;

              const couponRedeemEndValueEndOfDay = formatToEndOfDay(
                value as Date
              );

              const endDateCampaignEndOfDay = formatToEndOfDay(
                new Date(endDateCampaign)
              );

              if (
                value &&
                (couponRedeemEndValueEndOfDay < parent.couponGenStart ||
                  couponRedeemEndValueEndOfDay < parent.couponGenEnd ||
                  couponRedeemEndValueEndOfDay > endDateCampaignEndOfDay)
              ) {
                return createError({
                  path,
                  message: formatMessage({id: 'COMMON.ERRORS.INVALID_DATE'}),
                });
              }
              return true;
            }),
            uploadNewPromotionInEligiblePocs: yup.boolean().optional(),
        })
      ),
    });
  }, []);
}

import * as yup from 'yup';
import {useIntl} from 'react-intl';

export default function CreateStepThreeSchema(): any {
  const {formatMessage} = useIntl();
  const requiredMessage = formatMessage({
    id: 'COMMON.FORM.REQUIRED',
  });
  const websiteRegex =
    /^(https?:\/\/)([a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)$/i;

  const documentSchema = yup.object().shape({
    document: yup
      .string()
      .required(requiredMessage)
      .min(
        100,
        formatMessage(
          {
            id: 'COMMON.ERRORS.FIELD_MIN',
          },
          {
            min: 100,
          }
        )
      ),
    title: yup
      .string()
      .required(requiredMessage)
      .min(
        5,
        formatMessage(
          {
            id: 'COMMON.ERRORS.FIELD_MIN',
          },
          {
            min: 5,
          }
        )
      )
      .max(30),
  });

  return yup.object().shape({
    legalInfoForParticipantPocs: yup.array().of(documentSchema),
    termsUrl: yup
      .string()
      .required(requiredMessage)
      .matches(
        websiteRegex,
        formatMessage({
          id: 'COMMON.ERRORS.INVALID_URL',
        })
      ),
    privacyUrl: yup
      .string()
      .required(requiredMessage)
      .matches(
        websiteRegex,
        formatMessage({
          id: 'COMMON.ERRORS.INVALID_URL',
        })
      ),
    campaignRules: yup.mixed().required(requiredMessage),
  });
}

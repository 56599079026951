import {blockPocManuallyFraudPreventionService} from '@Services';

async function blockPocManuallyFraudPreventionUseCase(
  pocCode: string,
  campaignId: number
): Promise<any> {
  const response = await blockPocManuallyFraudPreventionService(
    pocCode,
    campaignId
  );

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  return response.data;
}

export default blockPocManuallyFraudPreventionUseCase;

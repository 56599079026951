import {Badge, Grid, Heading} from '@hexa-ui/components';
import PreviewCard from '../../../PreviewCard/PreviewCard';
import {BorderContainer, TextItem} from '@martech/components';
import {useIntl} from 'react-intl';
import {CreateCampaignProps} from '../../../../models';
import {formatDate} from '../../../../utils';
import CardForEdit from '../CardForEdit/CardForEdit';

export default function PromotionsSummary({
  values,
  onEdit,
}: {
  values: CreateCampaignProps | undefined;
  onEdit?: () => void;
}) {
  const {formatMessage} = useIntl();

  const goal = (promotionGoal: string) => {
    if (promotionGoal === 'introduce')
      return formatMessage({
        id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.INTRODUCE.TITLE',
      });
    else if (promotionGoal === 'sustain')
      return formatMessage({
        id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.SUSTAIN.TITLE',
      });
    else
      return formatMessage({
        id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PROMOTION_GOAL.GENERATE.TITLE',
      });
  };

  return (
    <CardForEdit
      id="promotions"
      title={formatMessage({
        id: 'COUPON_CAMPAIGNS.CREATE.STEPS.PROMOTIONS.TITLE',
      })}
      onEdit={onEdit}
    >
      {values?.promotions?.map((promotion, index) => (
        <Grid.Item xs={12} style={{marginBottom: '1.5rem'}} key={index}>
          <BorderContainer>
            <Grid.Item xs={4}>
              <PreviewCard
                name={promotion.promotionName}
                badges={[
                  {
                    label: `${formatMessage(
                      {
                        id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.PROMOTIONS.POINTS',
                      },
                      {points: `${promotion.beesPointsPerCoupon}`}
                    )}`,
                    color: 'gray',
                  },
                  promotion.couponType?.toLowerCase() === 'discount'
                    ? {
                        label: formatMessage({
                          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.DISCOUNT',
                        }),
                        color: 'orange',
                      }
                    : {
                        label: formatMessage({
                          id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.FREE_GOOD',
                        }),
                        color: 'green',
                      },
                ]}
                imgUrl={
                  promotion.promotionImageFile
                    ? URL.createObjectURL(promotion.promotionImageFile)
                    : promotion?.promotionImage
                }
              />
            </Grid.Item>
            <Grid.Item xs={8} style={{flexDirection: 'column'}}>
              <Grid.Item
                xs={12}
                style={{marginBottom: '1.5rem', marginLeft: '0.5rem'}}
              >
                <Heading size="H4">
                  {formatMessage(
                    {id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.PROMOTIONS.INDEX'},
                    {index: index + 1}
                  )}
                </Heading>
                {!promotion.promotionStatus && (
                  <Badge.Status
                    color="gray"
                    label={formatMessage({
                      id: 'COMMON.BUTTONS.DISABLED',
                    })}
                    type="default"
                    style={{marginLeft: '1rem'}}
                  />
                )}
              </Grid.Item>
              <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.PROMOTIONS.GOAL',
                  })}
                  value={goal(promotion.promotionGoal as string)}
                  xs={3}
                />

                {promotion.couponType?.toLowerCase() === 'discount' ? (
                  <TextItem
                    title={formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.DISCOUNT_TITLE',
                    })}
                    value={promotion.discount}
                    xs={4}
                  />
                ) : (
                  <TextItem
                    title={formatMessage({
                      id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.FREE_GOOD_TITLE',
                    })}
                    value={promotion.freeUnits}
                    xs={4}
                  />
                )}

                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.PROMOTIONS.COUPON_GENERATION_PERIOD',
                  })}
                  value={`${formatDate(
                    promotion.couponGenStart
                  )} - ${formatDate(promotion.couponGenEnd)}`}
                  xs={5}
                  noLineBreakAnywhere
                />
              </Grid.Item>

              <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRODUCT_NAME.TITLE',
                  })}
                  value={promotion.productName}
                  xs={3}
                />

                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_AMOUNTS.TOTAL_GEN',
                  })}
                  value={promotion.totalAmountCoupons}
                  xs={4}
                />

                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_GENERATION.EXP_TIME',
                  })}
                  value={`${promotion.expirationTime} ${formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.DATES.COUPON_GENERATION.HOURS',
                  })}`}
                  xs={5}
                />
              </Grid.Item>

              <Grid.Item xs={12} style={{marginBottom: '1rem'}}>
                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.TOTAL_UNITS.TITLE',
                  })}
                  value={promotion.totalUnits}
                  xs={3}
                />

                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_AMOUNTS.CONSUMER_GEN',
                  })}
                  value={promotion.totalCouponsPerUser}
                  xs={4}
                />

                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.SUMMARY.PROMOTIONS.COUPON_REDEMPTION_PERIOD',
                  })}
                  value={`${formatDate(
                    promotion.couponRedeemStart
                  )} - ${formatDate(promotion.couponRedeemEnd)}`}
                  xs={5}
                  noLineBreakAnywhere
                />
              </Grid.Item>

              <Grid.Item xs={12}>
                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.PRODUCT_SETTINGS.PRICE_PER_UNIT.TITLE',
                  })}
                  value={`$ ${promotion.pricePerUnit}`}
                  xs={3}
                />

                <TextItem
                  title={formatMessage({
                    id: 'COUPON_CAMPAIGNS.CREATE.PROMOTIONS.COUPON_SETTINGS.COUPON_AMOUNTS.CONSUMER_ACTIVE_COUPONS',
                  })}
                  value={promotion.maxActiveCouponPerUser}
                  xs={4}
                />
              </Grid.Item>
            </Grid.Item>
          </BorderContainer>
        </Grid.Item>
      ))}
    </CardForEdit>
  );
}

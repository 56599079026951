import {CancelActionModal, Footer} from '@Components';
import {Button, Grid, TextLink} from '@hexa-ui/components';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';

interface EditCampaignFooterProps {
  onSave?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export default function EditCampaignFooter({
  onSave,
  disabled,
  loading,
}: EditCampaignFooterProps) {
  const navigate = useNavigate();
  const {formatMessage} = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Footer>
      <Grid.Container
        sidebar
        type="fluid"
        style={{
          border: '1px solid #EEE',
          background: '#FFF',
          borderRadius: '1000px',
          padding: '24px',
          marginLeft: '6rem',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <Grid.Item xs={6}>
          <TextLink onClick={() => setIsModalOpen(true)} data-testid="discard">
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DISCARD_MODAL.DISCARD',
            })}
          </TextLink>
        </Grid.Item>
        <Grid.Item xs={6} style={{justifyContent: 'flex-end', gap: '1rem'}}>
          <Button
            variant="secondary"
            data-testid="back"
            onClick={() => setIsModalOpen(true)}
          >
            {formatMessage({id: 'COMMON.BUTTONS.GO_BACK'})}
          </Button>
          <Button
            isLoading={loading}
            data-testid="save"
            disabled={disabled}
            onClick={onSave}
          >
            {formatMessage({id: 'COMMON.BUTTONS.SAVE'})}
          </Button>
        </Grid.Item>
      </Grid.Container>

      <CancelActionModal
        title={formatMessage({
          id: 'COUPON_CAMPAIGNS.EDIT.DISCARD_MODAL.TITLE',
        })}
        description={formatMessage({
          id: 'COUPON_CAMPAIGNS.EDIT.DISCARD_MODAL.DESCRIPTION',
        })}
        confirmTitle={formatMessage({
          id: 'COUPON_CAMPAIGNS.EDIT.DISCARD_MODAL.DISCARD',
        })}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onCancel={() => navigate(-1)}
      />
    </Footer>
  );
}

import {CreateCampaignStepProps} from '@Models';
import CampaignSummary from '../../Common/CampaignSummary/CampaignSummary';
import PromotionsSummary from '../../Common/PromotionsSummary/PromotionsSummary';
import LegalInfoSummary from '../../Common/LegalInfoSummary/LegalInfoSummary';
import UrlAndMediaSummary from '../../Common/UrlAndMediaSummary/UrlAndMediaSummary';

interface StepFiveProps extends CreateCampaignStepProps {
  goTo: (step: number) => void;
}

const StepFive: React.FC<StepFiveProps> = ({initialValues, goTo}) => {
  return (
    <div data-testid="step-five">
      <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
        <CampaignSummary values={initialValues} onEdit={() => goTo(0)} />
        <PromotionsSummary values={initialValues} onEdit={() => goTo(1)} />
        <LegalInfoSummary values={initialValues} onEdit={() => goTo(2)} />
        <UrlAndMediaSummary values={initialValues} onEdit={() => goTo(3)} />
      </div>
    </div>
  );
};

export default StepFive;

import {Grid, Heading, LoadingBuzz, Paragraph} from '@hexa-ui/components';
import React, {useMemo} from 'react';

const BeeState: React.FC<{
  title: string;
  description?: string;
  image?: any;
  loadingBuzz?: boolean;
  containerStyle?: 'large' | 'small';
  style?: React.CSSProperties;
}> = ({
  title,
  description,
  image,
  loadingBuzz,
  containerStyle = 'large',
  style,
}) => {
  const ContainerStyle = useMemo(() => {
    if (containerStyle === 'small') {
      return {padding: '100px'};
    }

    return {padding: '265px', paddingBottom: '200px'};
  }, [containerStyle]);

  return (
    <Grid.Container
      type="fluid"
      data-testid="bee-state-container"
      style={{...ContainerStyle, ...style}}
    >
      <Grid.Item xs={12} style={{justifyContent: 'center'}}>
        {loadingBuzz ? (
          <div style={{height: '80px', display: 'flex', alignItems: 'center'}}>
            <LoadingBuzz size="xlarge" />
          </div>
        ) : (
          <img src={image} alt="bee-state-image" style={{width: '80px'}} />
        )}
      </Grid.Item>
      <Grid.Item xs={12} style={{justifyContent: 'center', margin: '16px 0'}}>
        <Heading size="H5" alignment="center">
          {title}
        </Heading>
      </Grid.Item>
      <Grid.Item xs={12} style={{justifyContent: 'center'}}>
        <Paragraph size="small" style={{textAlign: 'center'}}>
          {description}
        </Paragraph>
      </Grid.Item>
    </Grid.Container>
  );
};

export default BeeState;

import {Barcode} from '@hexa-ui/icons';
import {useFeatureFlag} from '@Hooks';
import {useSidebar} from 'admin-portal-shared-services';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';

interface SidebarItemData {
  id: string;
  icon: () => JSX.Element;
  path: string;
  title: string;
  toggle?: string;
}

export default function Sidebar() {
  const {formatMessage} = useIntl();

  function CheckToggle(item: SidebarItemData) {
    const isEnabled = item.toggle ? useFeatureFlag(item.toggle) : true;
    return isEnabled;
  }

  const sidebarItems = useMemo(() => {
    const items: SidebarItemData[] = [
      {
        id: 'coupon-campaign',
        icon: () => <Barcode />,
        path: '/digital-coupons/campaigns',
        title: formatMessage({id: 'COMMON.SIDEBAR.COUPON_CAMPAIGN'}),
      },
    ];

    const utils = [] as SidebarItemData[];

    return {
      items: items.filter(item => {
        return CheckToggle(item);
      }),
      utils: utils.filter(util => {
        return CheckToggle(util);
      }),
    };
  }, [formatMessage]);

  useSidebar(sidebarItems);

  return <></>;
}

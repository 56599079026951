import React from 'react';
import {Button, Dialog, Heading, Paragraph} from '@hexa-ui/components';
import {useIntl} from 'react-intl';
import {PocCampaignsSummary} from '../../models';

interface UnblockPocModalProps {
  pocName: string;
  pocStatus: string;
  campaigns: PocCampaignsSummary[];
  isOpen: boolean;
  onClose: () => void;
  onUnblock: () => void;
  loading?: boolean;
}

enum PocStatus {
  BLOCKED_AUTOMATIC = 'blocked-automatic',
  BLOCKED_MANUAL = 'blocked-manual',
  BLOCKED_MANUALLY_FRAUD_PREVENTION = 'blocked-manually-fraud-prevention',
}

const UnblockPocModal: React.FC<UnblockPocModalProps> = ({
  pocName,
  pocStatus,
  campaigns,
  isOpen,
  onClose,
  onUnblock,
  loading,
}) => {
  const {formatMessage} = useIntl();

  const unblockWarningMessageMap: {[key: string]: string} = {
    'blocked-automatic': 'UNBLOCK_POC.TEXT_WARNING_AUTOMATIC',
    'blocked-manual': 'UNBLOCK_POC.TEXT_WARNING_DISABLED_PARTICIPATION',
    'blocked-manually-fraud-prevention':
      'UNBLOCK_POC.TEXT_WARNING_MANUALLY_FRAUD_PREVENTION',
  };

  const getUnblockWarningMessage = (pocStatus: string) => {
    const messageId =
      unblockWarningMessageMap[pocStatus] ||
      unblockConfirmationMessageMap[PocStatus.BLOCKED_MANUAL];

    if (pocStatus === PocStatus.BLOCKED_MANUALLY_FRAUD_PREVENTION) {
      const pointsUniqueCampaignBlockedByManuallyFraudPrevention =
        campaigns[0]?.points ?? 0;

      return formatMessage(
        {id: messageId},
        {amountPoints: pointsUniqueCampaignBlockedByManuallyFraudPrevention}
      );
    }

    return formatMessage({id: messageId});
  };

  const unblockConfirmationMessageMap: {[key: string]: string} = {
    'blocked-automatic': 'UNBLOCK_POC.TEXT_CONFIRMATION_AUTOMATIC',
    'blocked-manual': 'UNBLOCK_POC.TEXT_CONFIRMATION_DISABLED_PARTICIPATION',
    'blocked-manually-fraud-prevention':
      'UNBLOCK_POC.TEXT_CONFIRMATION_MANUALLY_FRAUD_PREVENTION',
  };

  const getConfirmationWarningMessage = (pocStatus: string) => {
    const messageId =
      unblockConfirmationMessageMap[pocStatus] ||
      unblockConfirmationMessageMap[PocStatus.BLOCKED_MANUAL];

    return formatMessage({id: messageId});
  };

  return (
    <Dialog.Root
      open={isOpen}
      onClose={onClose}
      actions={
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            data-testid="action-unblock-poc-button"
            size="large"
            variant="destructive"
            style={{marginRight: '0.9375rem'}}
            onClick={onUnblock}
            children={formatMessage({id: 'UNBLOCK_POC.ACTION'})}
            isLoading={loading}
          />
          <Dialog.Close>
            <Button
              data-testid="cancel-unblock-poc-button"
              size="large"
              variant="secondary"
              onClick={onClose}
              children={formatMessage({id: 'COMMON.BUTTONS.NO_GO_BACK'})}
            />
          </Dialog.Close>
        </div>
      }
      title={
        <Heading size="H2">
          {formatMessage(
            {id: 'UNBLOCK_POC.TITLE'},
            {
              pocName: pocName,
            }
          )}
        </Heading>
      }
    >
      <Paragraph
        style={{
          margin: 0,
          paddingTop: '15px',
          paddingBottom: '15px',
          textAlign: 'justify',
          maxWidth: '507px',
        }}
      >
        {getUnblockWarningMessage(pocStatus)}
      </Paragraph>

      {pocStatus === PocStatus.BLOCKED_AUTOMATIC &&
        campaigns.map(campaign => (
          <Paragraph key={campaign.campaignId}>
            {formatMessage(
              {id: 'UNBLOCK_POC.CAMPAIGNS_POINTS'},
              {
                campaignName: campaign.campaignTitle,
                amountPoints: campaign.points,
              }
            )}
          </Paragraph>
        ))}

      <Paragraph
        style={{
          margin: 0,
          paddingTop: '15px',
          maxWidth: '507px',
        }}
      >
        {getConfirmationWarningMessage(pocStatus)}
      </Paragraph>
    </Dialog.Root>
  );
};

export default UnblockPocModal;

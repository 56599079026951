import {Card, Grid, Heading, TextButton} from '@hexa-ui/components';
import {Edit2} from '@hexa-ui/icons';
import React from 'react';
import {useIntl} from 'react-intl';

interface CardForEditProps {
  id: string;
  title: string;
  onEdit?: () => void;
}

const CardForEdit: React.FC<CardForEditProps> = ({
  id,
  title,
  onEdit,
  children,
}) => {
  const {formatMessage} = useIntl();

  return (
    <Card
      border="medium"
      elevated="small"
      style={{width: '100%', padding: '2rem'}}
      data-testid={`${id}-summary`}
    >
      <Grid.Container type="fluid" style={{margin: 0}}>
        <Grid.Item xs={10} style={{marginBottom: '1.5rem'}}>
          <Heading size="H3">{title}</Heading>
        </Grid.Item>
        {onEdit && (
          <Grid.Item xs={2} style={{justifyContent: 'end'}}>
            <TextButton
              data-testid={`edit-${id}-button`}
              icon={Edit2}
              iconPosition="leading"
              size="small"
              onClick={onEdit}
              type="button"
            >
              {formatMessage({id: 'COUPON_CAMPAIGNS.EDIT.EDIT_BUTTON'})}
            </TextButton>
          </Grid.Item>
        )}

        {children}
      </Grid.Container>
    </Card>
  );
};

export default CardForEdit;

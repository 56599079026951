import React, {memo, useMemo} from 'react';
import {
  Card,
  Grid,
  TextButton,
  InputWrapper,
  FileUploader,
  Heading,
} from '@hexa-ui/components';
import {Download} from '@hexa-ui/icons';
import {CustomInput} from '@Components';
import {useIntl} from 'react-intl';
import {getStorageURL} from '@Utils';
import {useFormikContext} from 'formik';
import CreateStepFourSchema from '../../Create/StepFour/StepFour.schema';

const DTCbanner: React.FC = () => {
  const {values, setFieldValue, setFieldTouched} = useFormikContext<any>();
  const {formatMessage} = useIntl();
  const [, droppedFilesValidation] = CreateStepFourSchema();

  const dtcBannerError = useMemo(() => {
    if (
      values.adSettings?.dtcRedirectLink &&
      !values.adSettings?.dtcBannerFile
    ) {
      return {
        message: formatMessage({
          id: 'COMMON.FORM.REQUIRED',
        }),
      };
    }

    return values.adSettings?.dtcBannerFile &&
      !values.adSettings?.dtcBannerFile[0].valid
      ? {
          message: values.adSettings?.dtcBannerFile[0].errors
            ? values.adSettings?.dtcBannerFile[0].errors[0]
            : '',
        }
      : undefined;
  }, [values.adSettings?.dtcBannerFile, values.adSettings?.dtcRedirectLink]);

  const handleTemplateDownload = () => {
    const storageURL = getStorageURL();
    const templateUrl = `${storageURL}/templates/campaign/dtc_banner_template_1366_513.png`;
    window.open(templateUrl, '_blank');
  };

  const handleFilesDropped = (files: any, field: string) => {
    const fields = field.split('.');
    const fieldName = fields[fields.length - 1];
    droppedFilesValidation(files, fieldName).then(
      (response: {valid: boolean; files: any}) => {
        setFieldValue(field, response.files);
      }
    );
  };

  return (
    <Card
      border="medium"
      elevated="small"
      style={{width: '100%', padding: '2rem'}}
      data-testid="dtc-card"
    >
      <Grid.Container type="fluid" style={{margin: 0, gap: '1rem'}}>
        <Grid.Item xs={12}>
          <Heading size="H3">
            {formatMessage({id: 'COUPON_CAMPAIGNS.CREATE.DTC.TITLE'})}
          </Heading>
        </Grid.Item>

        <Grid.Item xs={12}>
          <CustomInput
            data-testid="dtc-link"
            name="adSettings.dtcRedirectLink"
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DTC.LINK.LABEL',
            })}
            required={false}
            optionalText={formatMessage({
              id: 'COMMON.FORM.OPTIONAL',
            })}
          />
        </Grid.Item>

        <Grid.Item
          xs={4}
          style={{
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <InputWrapper
            label={formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DTC.IMAGE.LABEL',
            })}
            width="100%"
            optionalText={formatMessage({
              id: 'COMMON.FORM.OPTIONAL',
            })}
            required={false}
          >
            <FileUploader
              accept=".png,.jpg"
              maxFiles={1}
              imageFit="contain"
              message={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.DTC.IMAGE.PLACEHOLDER',
              })}
              onDrop={fileDropped => {
                handleFilesDropped(fileDropped, 'adSettings.dtcBannerFile');
                setFieldTouched('adSettings.dtcBannerFile', true);
              }}
              onClean={() => {
                setFieldValue('adSettings.dtcBannerFile', '');
              }}
              buttonIntl={formatMessage({
                id: 'COUPON_CAMPAIGNS.CREATE.DTC.IMAGE.BUTTON',
              })}
              value={values.adSettings?.dtcBannerFile}
              error={dtcBannerError}
            />
          </InputWrapper>

          <TextButton
            data-testid="dtc-banner-template"
            icon={Download}
            iconPosition="leading"
            size="small"
            onClick={handleTemplateDownload}
          >
            {formatMessage({
              id: 'COUPON_CAMPAIGNS.CREATE.DOWNLOAD_TEMPLATE',
            })}
          </TextButton>
        </Grid.Item>
      </Grid.Container>
    </Card>
  );
};

export default memo(DTCbanner);

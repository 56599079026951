import {Grid, LoadingBuzz} from '@hexa-ui/components';
import React from 'react';

const LoadingBee: React.FC = () => {
  return (
    <Grid.Container
      style={{
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        msTransform: 'translateY(-50%) translateX(-50%)',
      }}
    >
      <LoadingBuzz dataTestId="buzz" size="xlarge" />
    </Grid.Container>
  );
};

export default LoadingBee;

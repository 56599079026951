import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import * as yup from 'yup';
import {processFile} from '@Utils';

export default function CreateStepFourSchema(): [any, any] {
  const {formatMessage} = useIntl();
  const websiteRegex =
    /^(https?:\/\/)?([a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)$/i;

  const requiredMessage = formatMessage({
    id: 'COMMON.FORM.REQUIRED',
  });

  const getErrorMessage = (type: string, size?: string) => {
    return formatMessage(
      {
        id: `COUPON_CAMPAIGNS.CREATE.CONSUMER_SETTINGS.VALIDATIONS.${type}`,
      },
      {
        size,
      }
    );
  };

  const droppedFilesValidation = async (files: any, field: string) => {
    const dimensions: Record<string, {height: number; width: number}> = {
      dtcBannerFile: {
        height: 513,
        width: 1366,
      },
    };

    const file = files[0].file;
    const objectUrl = URL.createObjectURL(file);

    return processFile(objectUrl)
      .then(imgData => {
        if (file.size > 2097152) {
          files[0] = {
            ...files[0],
            valid: false,
            errors: [getErrorMessage('MAX_SIZE', '2MB')],
          };
          return {valid: false, files};
        }
        if (
          imgData.width !== dimensions[field]?.width ||
          imgData.height !== dimensions[field]?.height
        ) {
          files[0] = {
            ...files[0],
            errors: [getErrorMessage('UPLOAD_FAILED')],
            valid: false,
          };
          return {valid: false, files};
        }

        return {valid: true, files};
      })
      .catch(() => {
        files[0] = {
          ...files[0],
          errors: [formatMessage({id: 'COMMON.ERRORS.TYPE_ERROR'})],
          valid: false,
        };
        return {valid: false, files};
      });
  };

  const schema = useMemo(() => {
    const mediaAccountsSchema = yup.object().shape({
      channel: yup
        .string()
        .test('channel required when id is filled', '', function (value) {
          const {path, parent, createError} = this;
          if (parent.idValue && value === 'default') {
            return createError({
              path,
              message: requiredMessage,
            });
          }
          return true;
        }),
      idValue: yup
        .string()
        .test('id required when channel is filled', '', function (value) {
          const {path, parent, createError} = this;
          if (parent.channel && parent.channel !== 'default' && !value) {
            return createError({
              path,
              message: requiredMessage,
            });
          }
          return true;
        }),
    });

    const socialMediaLinksSchema = yup.object().shape({
      service: yup
        .string()
        .test('service required when link is filled', '', function (value) {
          const {path, parent, createError} = this;
          if (parent.redirectLink && value === 'default') {
            return createError({
              path,
              message: requiredMessage,
            });
          }
          return true;
        }),
      redirectLink: yup
        .string()
        .matches(
          websiteRegex,
          formatMessage({
            id: 'COMMON.ERRORS.INVALID_URL',
          })
        )
        .test('link required when service is filled', '', function (value) {
          const {path, parent, createError} = this;
          if (parent.service && parent.service !== 'default' && !value) {
            return createError({
              path,
              message: requiredMessage,
            });
          }
          return true;
        }),
    });

    const adSettingsSchema = yup.object().shape({
      dtcRedirectLink: yup
        .string()
        .test('link required when banner is filled', '', function (value) {
          const {path, parent, createError} = this;
          if (parent.dtcBannerFile && !value) {
            return createError({
              path,
              message: requiredMessage,
            });
          }
          return true;
        })
        .matches(
          websiteRegex,
          formatMessage({
            id: 'COMMON.ERRORS.INVALID_URL',
          })
        )
        .nullable(),
      dtcBannerFile: yup
        .mixed()
        .test('banner required when link is filled', '', function (value) {
          const {path, parent, createError} = this;
          if (parent.dtcRedirectLink && !value) {
            return createError({
              path,
              message: requiredMessage,
            });
          }
          return true;
        }),
      domainType: yup.string().required(requiredMessage),
      customDomain: yup
        .string()
        .required(requiredMessage)
        .matches(
          /^[a-z0-9-/.]*$/,
          formatMessage({
            id: 'COMMON.ERRORS.INVALID_FIELD',
          })
        ),
      gtmContainerId: yup.string().when('domainType', {
        is: (value: string) => value === 'custom',
        then: yup.string().required(requiredMessage),
        otherwise: yup.string().nullable(),
      }),
      customCookiesUrl: yup.string().when('domainType', {
        is: (value: string) => value === 'custom',
        then: yup.string().required(requiredMessage),
        otherwise: yup.string().nullable(),
      }),
      googleAnalytics360UA: yup.string().when('domainType', {
        is: (value: string) => value === 'custom',
        then: yup
          .string()
          .required(requiredMessage)
          .matches(
            /^[^\s]*$/,
            formatMessage({
              id: 'COMMON.ERRORS.INVALID_FIELD',
            })
          ),
        otherwise: yup.string().nullable(),
      }),
      googleAnalytics360PropertyViewId: yup.string().when('domainType', {
        is: (value: string) => value === 'custom',
        then: yup
          .string()
          .required(requiredMessage)
          .matches(
            /^[^\s]*$/,
            formatMessage({
              id: 'COMMON.ERRORS.INVALID_FIELD',
            })
          ),
        otherwise: yup.string().nullable(),
      }),
      mediaAccountIds: yup.array().of(mediaAccountsSchema),
      socialMediaRedirectLinks: yup.array().of(socialMediaLinksSchema),
    });

    return yup.object().shape({
      landingPageUrl: yup
        .string()
        .required(requiredMessage)
        .matches(
          /^[a-z0-9-]*$/,
          formatMessage({
            id: 'COMMON.ERRORS.INVALID_FIELD',
          })
        ),
      adSettings: adSettingsSchema,
    });
  }, []);

  return [schema, droppedFilesValidation];
}

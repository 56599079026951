import MessageMap from '../../i18n';

const disablePoc: MessageMap = {
  TITLE: 'Desactivar {pocName}?',
  ACTION: 'Sí, desactivar PDV',
  DISABLED_PARTICIPATION_DESCRIPTION:
    'Si está deshabilitado, el PDV no podrá participar en la promoción y validar cupones de nuevo.',
    SUCCESS_MESSAGE:
      '{pocName} fue deshabilitado con éxito de la promoción {promotionName}.',
};

export default disablePoc;

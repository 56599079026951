import {useParams} from 'react-router-dom';
import {CreateCampaignProps} from '@Models';
import {useEffect, useState} from 'react';
import {getCampaignDetailsUseCase} from '@UseCase';

interface UseCampaignDetailsProps {
  includeBlob?: boolean;
  path?: 'Promotions' | 'LegalInfo' | 'Campaign' | 'UrlAndMedia';
}

export default function useCampaignDetails({
  includeBlob,
  path,
}: UseCampaignDetailsProps = {}): [CreateCampaignProps | undefined, boolean] {
  const [campaign, setCampaign] = useState<CreateCampaignProps>();
  const [isLoading, setIsLoading] = useState(true);
  const {campaignId} = useParams();

  useEffect(() => {
    getCampaignDetailsUseCase(+campaignId!, includeBlob, path)
      .then(response => setCampaign({...campaign, ...response}))
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
  }, [campaignId]);

  return [campaign, isLoading];
}

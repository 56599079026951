import {
  Badge,
  BadgeStatusTypes,
  Card,
  Grid,
  Heading,
} from '@hexa-ui/components';
import React from 'react';
import {BeeAlert} from '@Assets';

interface CampaignCardPreviewProps {
  name?: string;
  imgUrl?: string;
  placeholder?: string;
  badges?: BadgeStatusTypes[];
}

const PreviewCard: React.FC<CampaignCardPreviewProps> = ({
  name,
  imgUrl,
  placeholder,
  badges,
}) => {
  return (
    <Card
      border="medium"
      elevated="medium"
      style={{width: '100%', overflow: 'hidden', height: 'fit-content'}}
    >
      <Grid.Container type="fluid" style={{margin: 0}}>
        <Grid.Item
          xs={12}
          style={{
            justifyContent: 'center',
            background: '#D9D9D9',
            padding: imgUrl ? '0' : '30px 0',
          }}
        >
          {imgUrl ? (
            <img src={imgUrl} alt="campaign image" width="100%" />
          ) : (
            <img src={BeeAlert} alt="empty" width={115} />
          )}
        </Grid.Item>

        <Grid.Item xs={12} style={{margin: '16px 8px 8px 8px'}}>
          <Heading size="H4">{name || placeholder}</Heading>
        </Grid.Item>
        {badges && (
          <Grid.Item xs={12} style={{margin: '0 8px 16px 8px', gap: '8px'}}>
            {badges.map(badge => (
              <Badge.Status key={badge.label} {...badge} />
            ))}
          </Grid.Item>
        )}
      </Grid.Container>
    </Card>
  );
};

export default PreviewCard;

import {getCampaignDetailsService} from '@Services';
import {CreateCampaignProps} from '@Models';
import {urlToFile} from '@Utils';

async function includePromotionsBlob(data: CreateCampaignProps) {
  const {promotions} = data;

  if (promotions) {
    await Promise.all(
      promotions.map(async promotion => {
        if (promotion.promotionImage) {
          promotion.promotionImageFile = await urlToFile(
            promotion.promotionImage
          );
        }
        return promotion;
      })
    );
  }
}

async function includeLegalInfoBlob(data: CreateCampaignProps) {
  if (data.campaignRulesUrl) {
    data.campaignRules = await urlToFile(data.campaignRulesUrl);
  }
}

async function includeCampaignBlob(data: CreateCampaignProps) {
  if (data.imageUrl) {
    data.imageFile = await urlToFile(data.imageUrl);
  }

  if (data.template?.ageGate?.mobileBackground) {
    data.template.ageGate.mobileBackgroundFile = [
      {
        errors: [],
        file: await urlToFile(data.template.ageGate.mobileBackground),
        id: 1,
        valid: true,
      },
    ];
  }

  if (data.template?.ageGate?.desktopBackground) {
    data.template.ageGate.desktopBackgroundFile = [
      {
        errors: [],
        file: await urlToFile(data.template.ageGate.desktopBackground),
        id: 1,
        valid: true,
      },
    ];
  }

  if (data.template.logo) {
    data.template.logoFile = await urlToFile(data.template.logo);
  }

  if (data.template.registrationBanner) {
    data.template.registrationBannerFile = [
      {
        errors: [],
        file: await urlToFile(data.template.registrationBanner),
        id: 1,
        valid: true,
      },
    ];
  }

  if (data.template.customPin) {
    data.template.customPinFile = await urlToFile(data.template.customPin);
  }
}

async function includeUrlAndMediaBlob(data: CreateCampaignProps) {
  if (data.adSettings.dtcBanner) {
    data.adSettings.dtcBannerFile = [
      {
        errors: [],
        file: await urlToFile(data.adSettings.dtcBanner),
        id: 1,
        valid: true,
      },
    ];
  }
}

async function getCampaignDetailsUseCase(
  id: number,
  includeBlob?: boolean,
  path?: 'Promotions' | 'LegalInfo' | 'Campaign' | 'UrlAndMedia'
): Promise<CreateCampaignProps> {
  const response = await getCampaignDetailsService(id);

  if (response.hasError) {
    throw new Error(response.response.status);
  }

  const {data} = response;

  if (includeBlob) {
    switch (path) {
      case 'Promotions':
        await includePromotionsBlob(data);
        break;
      case 'LegalInfo':
        await includeLegalInfoBlob(data);
        break;
      case 'Campaign':
        await includeCampaignBlob(data);
        break;
      case 'UrlAndMedia':
        await includeUrlAndMediaBlob(data);
        break;
      default:
        throw new Error(`Invalid path: ${path}`);
    }
  }

  return data;
}

export default getCampaignDetailsUseCase;
